import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import white_logo from "./assets/img/logo_white.png";
import Navbar from "../../components/navbar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";


class Login extends Component {
  login(e) {
    e.preventDefault();

    let pin = e.target.pin.value;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/authenticate", {
        email: e.target.email.value,
        password: e.target.password.value,
        pin: pin,
      })

      .then((response) => {
        if (response.data.success === "true") {
          //save token
          localStorage.setItem("supportToken", response.data.data);
          localStorage.setItem("supportClearance", response.data.clearance);
          localStorage.setItem("supportPin", pin);
          localStorage.setItem("ticket_categories", response.data.ticket_categories);
        

          if (response.data.clearance  != "admin"){
            window.location.href = "/account";
          }else{
            window.location.href = "/overview";
          }
        }
      });
  }

  render() {
    return (
      <div style={{ overflowY: "scroll" }}>
        <div className="main-content">
          <div className="header bg-gradient-primary py-7 py-lg-8">
            <div
              className="separator separator-bottom separator-skew zindex-100"
              style={{ display: "none" }}
            >
              <svg
                x="0"
                y="0"
                viewBox="0 0 2560 100"
                preserveAspectRatio="none"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
            <div className="container">
              <div className="header-body text-center mb-4">
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-6">
                    <img style={{ width: "50%" }} src={white_logo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt--8 pb-5">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-7">
                <div className="card bg-secondary shadow border-0">
                  <div className="card-body px-lg-5 py-lg-5">
                    <form role="form" onSubmit={this.login} autoComplete="off">
                      <div className="form-group mb-3">
                        <div className="input-group input-group-alternative">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="ni ni-email-83" />
                            </span>
                          </div>
                          <input
                            autoComplete="off"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-alternative">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="ni ni-lock-circle-open" />
                            </span>
                          </div>
                          <input
                            autoComplete="new-password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-alternative">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="ni ni-lock-circle-open" />
                            </span>
                          </div>
                          <input
                            name="pin"
                            className="form-control"
                            placeholder="Support Pin"
                            type="password"
                            required
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary my-2 w-100"
                        >
                          Sign in 
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
