/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import Blockings from "./blockings.js";
import StopCreditBureau from "./stop_credit_bureau.js";
import SkipNINPhoneConfirmation from "./skip_nin_phone_cfm";
import BlockLoanOffer from "./block_loan_offers";
import BankLinkingException from "./bank_linking_exception";
import BankStatement from "./bank-statement.js";
import NewFeature from "./newFeature.js";
import TransferUpgrade from "./transfer_upgrade.js";
import SendBankStatement from "./send_bank_statement";
import BvnNinLookUp from "./bvn_nin_lookup.js";
import PrivateSms from "./private_sms.js";
import IncreaseLoan from "./increase_loan.js";
import SearchAccount from "./search_account.js";
import ID_Verification from "./id-verification.js";
import AgentSearch from "./agentTicket";
import RequeueTransactions from "./requeue_transactions.js";
import ReInitiateWithdrawals from "./reinitiate_p2p_bank_withdrawals.js";
import DisbursementProvider from "./provider";
import DeleteAccount from "./delete_account.js";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "bvn-nin-lookup",
      // tab: "agent_ticket_search",
    };
  }

  render() {
    let clearance = localStorage.getItem("supportClearance");

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Account</h2>
                  </div>

                  <hr />

                  <ul className="nav nav-pills  p-3">
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "bvn-nin-lookup" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "bvn-nin-lookup" })}
                      >
                        {" "}
                        BVN/NIN Lookup
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "blockings" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "blockings" })}
                      >
                        Blockings
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "stop-credit-bureau" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "stop-credit-bureau" })}
                      >
                        Stop Credit Bureau
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "block-loan-offer" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "block-loan-offer" })}
                      >
                        Block Loan Offer
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "bank-statement" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "bank-statement" })}
                      >
                        Bank Statement
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "send_bank_statement" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "send_bank_statement" })}
                      >
                        Send Bank Statement
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "transfer_upgrade" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "transfer_upgrade" })}
                      >
                        Transfer Upgrade
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "resets-overrides" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "resets-overrides" })}
                      >
                        Resets & Overrides / Account Info.
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "id_verification" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "id_verification" })}
                      >
                        ID Verification
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "sms-user" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "sms-user" })}
                      >
                        {" "}
                        SMS User
                      </a>
                    </li>
                    {(clearance === "admin" ||
                      clearance === "loan-officer" ||
                      clearance === "account") && (
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "increase_loan" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "increase_loan" })}
                        >
                          {" "}
                          Increase Loan
                        </a>
                      </li>
                    )}

                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "account_search" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "account_search" })}
                      >
                        {" "}
                        Account Search
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "agent_ticket_search" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "agent_ticket_search" })}
                      >
                        {" "}
                        Agent Ticket Search
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "re_queue_transactions" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "re_queue_transactions" })}
                      >
                        {" "}
                        Re-Queue Transactions
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "reinitiate-withdrawals" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "reinitiate-withdrawals" })}
                      >
                        {" "}
                        Non-Reinitiated Failed P2p Bank Withdrawals
                      </a>
                    </li>

                    {(clearance === "admin" || clearance === "account") && (
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "provider" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "provider" })}
                        >
                          Disbursement Provider
                        </a>
                      </li>
                    )}
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "account_delete" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "account_delete" })}
                        >
                        Delete Account Requests                       
                        </a>
                      </li>
                  </ul>

                  <div className="p-3">
                    {this.state.tab === "bvn-nin-lookup" ? (
                      <BvnNinLookUp />
                    ) : this.state.tab === "agent_ticket_search" ? (
                      <AgentSearch />
                    ) : this.state.tab === "blockings" ? (
                      <Blockings />
                    ) : this.state.tab === "stop-credit-bureau" ? (
                      <StopCreditBureau />
                    ) : this.state.tab === "block-loan-offer" ? (
                      <BlockLoanOffer />
                    ) : this.state.tab === "skip-user-otp" ? (
                      <SkipNINPhoneConfirmation />
                    ) : this.state.tab === "bank-linking" ? (
                      <BankLinkingException />
                    ) : this.state.tab === "bank-statement" ? (
                      <BankStatement />
                    ) : this.state.tab === "resets-overrides" ? (
                      <NewFeature />
                    ) : this.state.tab === "transfer_upgrade" ? (
                      <TransferUpgrade />
                    ) : this.state.tab === "send_bank_statement" ? (
                      <SendBankStatement />
                    ) : this.state.tab === "sms-user" ? (
                      <PrivateSms />
                    ) : this.state.tab === "increase_loan" ? (
                      <IncreaseLoan />
                    ) : this.state.tab === "account_delete" ? (
                      <DeleteAccount />
                    ) : this.state.tab === "id_verification" ? (
                      <ID_Verification />
                    ) : this.state.tab === "account_search" ? (
                      <SearchAccount />
                    ) : this.state.tab === "re_queue_transactions" ? (
                      <RequeueTransactions />
                    ) : this.state.tab === "reinitiate-withdrawals" ? (
                      <ReInitiateWithdrawals />
                    ) : this.state.tab === "provider" ? (
                      <DisbursementProvider />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
