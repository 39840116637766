/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import Collection from "./collection.js";
import Transaction from "./transfers";
import * as ConstantVar from "../../constants_var.js";
import axios from "axios";
import NumberFormat from "react-number-format";


class Raven extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "transaction",
      wallet :0,
      // transaction_reference:"202303141507EHFBFGJ"
    };
  }



  componentDidMount() {
    this.getWalletBalance();
  }

  getWalletBalance = () => {
    this.setState({isLoading :true})
    let token = localStorage.getItem("supportToken")

    axios.post(ConstantVar.API_BASE_URL + `/support/get_raven/wallet`,{
      token
    })
    .then((res) => {
      if (res.data.data.status === "success") {
        this.setState({
          isLoading:false,
          wallet: res.data.data.data[0].available_bal,
          currency :res.data.data.data[0].currency,
        });
      }
    });
  };

  render() {

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <div className="row d-flex justify-content-between align-item-center">
                      <h2 className="mb-0 d-inline">Raven Dashboard</h2>
                      <div className="mx-4" >
                        <div className="row">
                          <h2 onClick={this.getWalletBalance} style={{cursor:"pointer"}}  className=" wallet_balance pe-auto" >
                            Wallet :
                          </h2>
                          {this.state.isLoading ?   
                            <div className="fa-x "  >
                              <i className="fas align-item-center fa-circle-notch fa-spin"></i>
                            </div>                       :
                            <h2 className="px-2 bold text-success" >₦
                              <NumberFormat
                              
                              style={{ fontSize: 20 }}
                              value={this.state.wallet.toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={","}
                              />
                              
                            </h2>
                        }
                        </div>
                      </div>
                    </div>


                  </div>

                  <hr />

                  <ul className="nav nav-pills  p-3">
                   
                   
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "transaction" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "transaction" })}
                      >
                        Transactions
                      </a>
                    </li>

                  </ul>

                  <div className="p-3">
                    {  this.state.tab === "transaction" ? (
                      <Transaction reference={this.props.match.params.transactionID} />
                    ): (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Raven;
