import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";

class FundAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      pin: "",
      account_name: "",
      available_bal: 0,
      amount: 0,
      funding_accounts:[]
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount(){
    this.getFundingAccount()
  };

  setAmount = (e) => {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({
      amount: amount,
    });
  };

  fundAccount = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/fund_account", {
        token: localStorage.getItem("supportToken"),
        phone: this.state.phone,
        amount: this.state.amount,
        purpose: this.state.purpose,
        pin: this.state.pin,
        otp: this.props.otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            phone: "",
            amount: 0,
            account_name: "",
            available_bal: 0,
            pin: "",
            purpose: "",
          });
        } else {
          self.setState({
            isLoading: false,
          });
        }
        alert(response.data.message);
      });
  };

  getFundingAccount = () => {
    
    let self = this;
    axios.get(
        ConstantVar.API_BASE_URL + `/support/funding_accounts?token=${localStorage.getItem("supportToken")}`
        )
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            funding_accounts: response.data.data,
          });
        }
      });
  };
  
  getAccountDetails = (phone) => {
    this.setState({
      account_name: "",
      available_bal: "",
    });

    this.setState({
      account_name: "",
    });
    let self = this;
    axios
      .post(
        ConstantVar.API_BASE_URL + "/support/get_hometown_account_details",
        {
          token: localStorage.getItem("supportToken"),
          phone: phone,
        }
      )
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            account_name: response.data.account_name,
            available_bal: response.data.available_bal,
          });
        } else {
          self.setState({
            account_name: "Not Found",
            available_bal: 0,
          });
        }
      });
  };

  handleSelectAccount = (phone) => {
    this.setState({phone:phone},() => {
      this.getAccountDetails(this.state.phone)
    })
    
  }

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Fund Account</h2>
          </div>

          <form className=" p-3 col-md-7" onSubmit={this.fundAccount}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Phone Number</label>
                  <input
                    required
                    name="phone"
                    className="form-control"
                    placeholder="Phone Number"
                    value={this.state.phone}
                    onChange={(evt) => {
                      let phone = evt.target.value;
                      if (phone.length === 11) {
                        // get user full name
                        this.getAccountDetails(phone);
                      }
                      this.handleChange(evt);
                    }}
                    type="phone"
                  />
                  <small className="text-info">{this.state.account_name}</small>
                  <label className="float-right">
                    <small>
                      Available Bal : <b>{this.state.available_bal}</b>
                    </small>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Amount</label>
                  <NumberFormat
                    allowNegative={false}
                    placeholder="Amount to fund "
                    value={this.state.amount}
                    onChange={this.setAmount}
                    thousandSeparator={true}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Purpose</label>
                  <textarea
                    required
                    name="purpose"
                    className="form-control"
                    placeholder="Purpose"
                    value={this.state.purpose}
                    onChange={this.handleChange}
                    rows="2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Your PIN</label>
                  <input
                    required
                    name="pin"
                    className="form-control"
                    placeholder="PIN"
                    value={this.state.pin }
                    onChange={this.handleChange}
                    type="password"
                  />
                </div>
              </div>
            </div>

            <button className="btn btn-success" disabled={this.state.isLoading}>
              Continue
            </button>
          </form>

          <div className=" p-3 col-md-5" >
            <p className="d-flex justify-content-between text-bold">
              <strong>Account_Name</strong>{" "}
              <strong>Phone_number</strong>
            </p>
            {this.state.funding_accounts.length > 0 && 
            this.state.funding_accounts.map((item,index) => 
            
            <div key={index} >
                
                <a href="#" className="d-flex justify-content-between pe-auto" onClick={() =>this.handleSelectAccount(item.phone_number)}>
                  <span>{item.account_name}</span>{" "}
                  <span>{item.phone_number}</span>
                </a>
              </div>
            )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default FundAccount;
