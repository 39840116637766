import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";

class DebitAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      pin: null,
      account_name: "",
      available_bal: 0,
      amount: 0,
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  setAmount = (e) => {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({
      amount: amount,
    });
  };

  debitAccount = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/debit_account", {
        token: localStorage.getItem("supportToken"),
        phone: this.state.phone,
        amount: this.state.amount,
        purpose: this.state.purpose,
        wallet: this.state.wallet,
        pin: this.state.pin,
        otp: this.props.otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            phone: "",
            amount: 0,
            account_name: "",
            available_bal: 0,
            pin: "",
            purpose: "",
          });
        } else {
          self.setState({
            isLoading: false,
          });
        }
        alert(response.data.message);
      });
  };

  getAccountDetails = (phone) => {
    this.setState({
      account_name: "",
      available_bal: "",
    });

    this.setState({
      account_name: "",
    });
    let self = this;
    axios
      .post(
        ConstantVar.API_BASE_URL + "/support/get_hometown_account_details",
        {
          token: localStorage.getItem("supportToken"),
          phone: phone,
        }
      )
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            account_name: response.data.account_name,
            available_bal: response.data.available_bal,
          });
        } else {
          self.setState({
            account_name: "Not Found",
            available_bal: 0,
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Debit Account</h2>
          </div>

          <form className=" p-3 col-md-7" onSubmit={this.debitAccount}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Phone Number</label>
                  <input
                    required
                    name="phone"
                    className="form-control"
                    placeholder="Phone Number"
                    value={this.state.phone}
                    onChange={(evt) => {
                      let phone = evt.target.value;
                      if (phone.length === 11) {
                        // get user full name
                        this.getAccountDetails(phone);
                      }
                      this.handleChange(evt);
                    }}
                    type="phone"
                  />
                  <small className="text-info">{this.state.account_name}</small>
                  <label className="float-right">
                    <small>
                      Available Bal : <b>{this.state.available_bal}</b>
                    </small>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Amount</label>
                  <NumberFormat
                    allowNegative={false}
                    placeholder="Amount to fund "
                    value={this.state.amount}
                    onChange={this.setAmount}
                    thousandSeparator={true}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Purpose</label>
                  <textarea
                    required
                    name="purpose"
                    className="form-control"
                    placeholder="Purpose"
                    value={this.state.purpose}
                    onChange={this.handleChange}
                    rows="2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Wallet</label>
                  <select
                    required
                    name="wallet"
                    className="form-control"
                    onChange={this.handleChange}
                  >
                    <option value="">Select Wallet</option>
                    <option value="main">Main Wallet</option>{" "}
                    <option value="loan">Loan Wallet</option>
                  </select>
                </div>
              </div>
            </div>
            
            <div className="form-group ">
              <label>Your PIN</label>
              <input
                required
                name="pin"
                className="form-control"
                placeholder="PIN"
                value={this.state.pin || ""}
                onChange={this.handleChange}
                type="password"
              />
            </div>

            <button className="btn btn-success" disabled={this.state.isLoading}>
              Continue
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default DebitAccount;
