import React, { Component, useReducer } from "react";
import "./assets/index.css";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class LoanOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSubmitting: false,
      week: {},
      month: {},
      year: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //bind this to self
    this.loadOverviewData();
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  loadOverviewData = () => {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/loan_overview", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          let data = response.data.data;
          let week = data.week;
          let month = data.month;
          let year = data.year;

          week.due =
            week.expectedWeeklyRepayment.total +
            week.expectedWeeklyRepayment.p_total;
          week.collection = week.weeklyTotalRepayment.total || 0;
          week.percentage = Math.round((week.collection * 100) / week.due);

          month.due =
            month.expectedMonthlyRepayment.total +
            month.expectedMonthlyRepayment.p_total;
          month.collection = month.monthlyTotalRepayment.total || 0;
          month.percentage = Math.round((month.collection * 100) / month.due);

          year.due =
            year.expectedYearlyRepayment.total +
            year.expectedYearlyRepayment.p_total;
          year.collection = year.yearlyTotalRepayment.total || 0;
          year.percentage = Math.round((year.collection * 100) / year.due);

          self.setState({
            week: week,
            month: month,
            year: year,
            isLoading: false,
          });
        }
      });
  };

  render() {
    let report = this.state.overview_data;

    // alert(JSON.stringify(report.week));
    // let week = this.state.overview_data.week;

    return (
      <div>
        <hr className="dash-hr" />

        <div className="p-3">
          <h2 className="text-primary">Open/Fully Paid loans</h2>
          <div className="p-3">
            <div className="row">
              <div className="col-md-4 card p-3">
                <div className="card-shadow">
                  <h4>
                    Weekly Goal Completion ({this.state.week.percentage})%
                  </h4>
                  <b>Collections/Due</b>{" "}
                  <label className="float-right">
                    <b>
                      <NumberFormat
                        value={this.state.week.collection}
                        displayType={"text"}
                        decimalScale={0}
                        thousandSeparator={true}
                      />
                    </b>
                    /
                    <NumberFormat
                      value={this.state.week.due}
                      displayType={"text"}
                      decimalScale={0}
                      thousandSeparator={true}
                    />
                  </label>
                  <br />
                  <progress
                    color="text-danger"
                    style={{
                      width: "100%",
                    }}
                    value={this.state.week.collection}
                    max={this.state.week.due}
                  ></progress>
                </div>
              </div>

              <div className="col-md-4 card p-3">
                <div className="card-shadow">
                  <h4>
                    Monthly Goal Completion ({this.state.month.percentage})%
                  </h4>
                  <b>Collections/Due</b>{" "}
                  <label className="float-right">
                    <b>
                      <NumberFormat
                        value={this.state.month.collection}
                        displayType={"text"}
                        decimalScale={0}
                        thousandSeparator={true}
                      />
                    </b>
                    /
                    <NumberFormat
                      value={this.state.month.due}
                      displayType={"text"}
                      decimalScale={0}
                      thousandSeparator={true}
                    />
                  </label>
                  <br />
                  <progress
                    color="text-danger"
                    style={{
                      width: "100%",
                    }}
                    value={this.state.month.collection}
                    max={this.state.month.due}
                  ></progress>
                </div>
              </div>

              <div className="col-md-4 card p-3">
                <div className="card-shadow">
                  <h4>
                    Yearly Goal Completion ({this.state.year.percentage})%
                  </h4>
                  <b>Collections/Due</b>{" "}
                  <label className="float-right">
                    <b>
                      <NumberFormat
                        value={this.state.year.collection}
                        displayType={"text"}
                        decimalScale={0}
                        thousandSeparator={true}
                      />
                    </b>
                    /
                    <NumberFormat
                      value={this.state.year.due}
                      displayType={"text"}
                      decimalScale={0}
                      thousandSeparator={true}
                    />
                  </label>
                  <br />
                  <progress
                    color="text-danger"
                    style={{
                      width: "100%",
                    }}
                    value={this.state.year.collection}
                    max={this.state.year.due}
                  ></progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoanOverview;
