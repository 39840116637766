import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";

class StoppedTransacting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      startDate: null,
      endDate: null,
      total: 0,
      stoppedData: [],
      generate: null,
    };
  }

  componentDidMount() {
    this.getStoppedTransacting();
  }

  getStoppedTransacting = () => {
    this.setState({
      isSubmitting: true,
      generate: null,
    });

    axios
      .get(ConstantVar.API_BASE_URL + "/support/reports/stats", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
          type: "stoppedTransacting_report",
        },
      })
      .then((response) => {
        if (response.data.data != "undefined") {
          this.setState({
            stoppedData: 
            // response.data.data
            response.data.data.sort(
              (a, b) => new Date(a.last_transaction_at) - new Date(b.last_transaction_at)
            ),
            isSubmitting: false,
          });
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

 
  render() {
    return (
      <div className="">
        <div className="">
          <hr className="dash-hr" />

          <div className="p-5">
            <div className="form-inline">
              <label>Start Date </label>{" "}
              <input
                type="date"
                name="startDate"
                value={this.state.startDate || ""}
                className="form-control ml-2 mr-2"
                onChange={this.handleChange}
              />  
              <label>End Date </label>{" "}
              <input
                type="date"
                name="endDate"
                value={this.state.endDate || ""}
                className="form-control ml-2 mr-2"
                onChange={this.handleChange}
              />
              <button className="btn btn-primary" 
                              disabled={this.state.isSubmitting}

              onClick={this.getStoppedTransacting}>
                Continue
              </button>
            </div>

            {!this.state.isSubmitting ? (
              <div className="table-responsive mt-5 p-5">
                <table className="table align-items-center text-dark h2 table-flush">
                  <thead>
                    <th>Last Transaction Date</th>
                    <th>Email</th>
                    <th>Balance</th>
                    <th>Account Creation Date</th>
                  </thead>
                  <tbody>
                    {this.state.stoppedData.length > 0 ? (
                      this.state.stoppedData.map((item, index) => {
                        return (
                          <tr key={index}>
                          
                            <td
                              className="dash-td"
                              style={{ width: "9%" }}
                            >
                              <h4 className="text-muted trans-date">
                                {moment(item.last_transaction_at).format("Y MMM Do, h:mm:ss a")}
                              </h4>
                            </td>

                            <td className="">
                            <h4 >

                              <a
                                target="_blank"
                                href={"/user/" + item.email}
                              >
                                {item.email}
                              </a>
                              </h4>

                            </td>

                            <td>
                              <h4>
                                <NumberFormat
                                  value={item.balance}
                                  prefix="₦ "
                                  displayType={"text"}
                                  thousandSeparator={","}
                                />
                              </h4>
                            </td>

                            <td>
                              <h4 className="text-muted trans-date">
                                {moment(item.created_at).format(
                                "Y MMM Do, h:mm:ss a"
                                )}
                              </h4>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td></td>
                        <td                            
                        className="text-muted text-center no-trans-text-o"
                        >           
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <h3 className="text-danger text-center no-trans-text-o">
                              No Stopped-Transacting user yet!
                            </h3>
                          </div>
                        </td>
                      </tr>
                    )}

                  
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="p-5 text-center">
                <h3>Loading....</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default StoppedTransacting;
