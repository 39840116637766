import React, { Component } from "react";
import "./assets/index.css";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class ActiveLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      isLoading: true,

      perPage: 30,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getLoans();
  }

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getLoans();
    });
  };

 
  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value ,
      paginationLoaded: false,
    });
  };

  getLoans = () => {
    //bind this to self
    let self = this;
    this.setState({
      isLoading: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/active_loans", {
        token: localStorage.getItem("supportToken"),
        current_page: this.state.currentPage,
        per_page: this.state.perPage,
        // start_date: this.state.start_date,
        // end_date: this.state.end_date,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            loans: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  render() {


    let principal = 0;
    let due = 0;
    let amountPaid = 0;
    let balance = 0;

    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div>
          
        <hr className="dash-hr" />
        {(() => {


          if (this.state.isLoading)
            return (
              <div style={{ padding: 50 }} className="text-center">
                <div>
                  <p>Loading...</p>
                </div>
              </div>
            );
          else
            return (
              <div className="table-responsive">
                <div className=" mr-4  mt-3 mb-3">
                 

                  <div className="form-inline float-right">
                    <label>Show </label>
                    <select
                      className="form-control mr-2"
                      name="perPage"
                      value={this.state.perPage}
                      onChange={(evt) => {
                        this.setState(
                          {
                            perPage: evt.target.value,
                            paginationLoaded: false,
                            currentPage: 1,
                          },
                          () => {
                            this.getLoans();
                          }
                        );
                      }}
                    >
                      <option>30</option>
                      <option>50</option>
                      <option>100</option>
                      <option>150</option>
                      <option>200</option>
                      <option>500</option>
                      <option value="700">500 above</option>
                    </select>
                    <label>entries </label>
                  </div>
                </div>

                <div className="pl-2 ml-5">{paginationElement}</div>
                <table className="table align-items-center table-flush">
                  <thead
                    style={{ background: "#ddddee", fontWeight: "bold" }}
                    className="h4"
                  >
                    <tr>

                    <td className="text-bold">Name</td>
                    <td className="text-bold">Email</td>
                    <td className="text-bold">Phone</td>
                    <td className="text-bold">Principal</td>
                    <td className="text-bold">Due</td>
                    <td className="text-bold">Paid</td>
                    <td className="text-bold">Pending Due</td>
                    <td className="text-bold">Next Due</td>
                    <td className="text-bold">Last Payment</td>
                    <td className="text-bold">Status</td>
                    </tr>
                  </thead>

                  {/* if no granted loans  */}
                  {!this.state.loans.length ? (
                    <tbody>
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No due loans!
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ""
                  )}

                  {this.state.loans.length > 0 ? (
                    <tbody>
                      {this.state.loans.map((loan, index) => {
                        // calculate
                        principal += loan.requested_amount;
                        due += loan.repayment_amount + loan.penalty;
                        amountPaid += loan.amount_paid;
                        balance += loan.balance;

                        return (
                          <tr key={index}>
                            <td className="dash-td">
                              <h4>
                                <a
                                  target="_blank"
                                  href={`/user/${loan.email}`}
                                  className="text-dark"
                                  rel="noreferrer"
                                >
                                  {loan.lname} {loan.fname}
                                </a>
                              </h4>
                            </td>
                            <td className="dash-td">
                              <h5>{loan.email}</h5>
                            </td>

                            <td className="dash-td">{loan.phone}</td>

                            <td className="dash-td">
                              <NumberFormat
                                value={loan.requested_amount}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={true}
                                suffix=".00"
                              />
                            </td>

                            <td className="dash-td">
                              <NumberFormat
                                value={loan.repayment_amount + loan.penalty}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={true}
                                suffix=".00"
                              />
                            </td>

                            <td className="dash-td">
                              <NumberFormat
                                value={loan.amount_paid}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={true}
                                suffix={loan.amount_paid > 0 ? ".00" : ""}
                              />
                            </td>

                            <td className="dash-td">
                              <NumberFormat
                                value={loan.balance}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={true}
                                suffix={loan.balance > 0 ? ".00" : ""}
                              />
                            </td>

                            <td className="dash-td">
                              {moment(loan.due_at).format("DD/MM/YYYY")}
                            </td>

                            <td className="dash-td">
                              {loan.last_payment ? (
                                <div>
                                  {moment(loan.last_payment.created_at).format(
                                    "DD/MM/YYYY"
                                  )}
                                  <br />
                                  <NumberFormat
                                    value={loan.last_payment.amount}
                                    displayType={"text"}
                                    decimalScale={0}
                                    thousandSeparator={true}
                                    suffix=".00"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </td>

                            <td className="dash-td">
                              {moment(loan.due_at).format("DD/MM/YYYY") ===
                              moment().format("DD/MM/YYYY") ? (
                                <p className="btn-primary btn btn-sm text-white">
                                  Due Today
                                </p>
                              ) : moment(loan.due_at) < moment() ? (
                                <p className="btn-primary btn btn-sm text-white">
                                  Past maturity
                                </p>
                              ) : moment(loan.due_at) > moment() ? (
                                <p className="btn-primary btn btn-sm text-white">
                                  Current
                                </p>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}

                      <tr style={{ background: "#eeeeff" }}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="dash-td">
                          <NumberFormat
                            className="h3"
                            value={principal}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            suffix={principal > 0 ? ".00" : ""}
                          />
                        </td>
                        <td className="dash-td">
                          <NumberFormat
                            className="h3"
                            value={due}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            suffix={due > 0 ? ".00" : ""}
                          />
                        </td>
                        <td className="dash-td">
                          <NumberFormat
                            className="h3"
                            value={amountPaid}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            suffix={amountPaid > 0 ? ".00" : ""}
                          />
                        </td>
                        <td className="dash-td">
                          <NumberFormat
                            className="h3"
                            value={balance}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            suffix=".00"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td colSpan="10">
                          <h2>
                            <small>
                              Showing {this.state._from + 1} to {this.state._to}{" "}
                              of {this.state.totalEntries} entries (filtered
                              from {this.state.loans.length} total entries)
                            </small>
                          </h2>
                          <div className="float-right">{paginationElement}</div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ""
                  )}
                </table>
              </div>
            );
        })()}
      </div>
    );
  }
}

export default ActiveLoans;
