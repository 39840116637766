import React, { Component } from 'react'
import ReactPaginate from "react-paginate";
import NumberFormat from "react-number-format";

import * as ConstantVar from "../../constants_var.js";

import axios from "axios";
import moment from "moment/moment";

export default class Loans extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }




  render() {
    
    return (
      <div
        className="tab-pane fade show active"
        id="nav-loans"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0">Loans</h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              {(() => {
                if (this.props.user.loans.length === 0)
                  return (
                    <div className="p-5">
                      <div className="text-center">
                        <h3>No loans for user</h3>
                      </div>
                    </div>
                  );
                else {
                  return (
                    <div>
                      {this.props.user.loans.map((loan, index) => {
                        return (
                          <div className="loan-card p-4" key={index}>
                            <div className="row">
                              <div className="col-sm-1">
                                <small className="text-muted">Status</small>
                                <div>
                                  {loan.status === 1 ? (
                                    <i className="fa text-success fa-dot-circle" />
                                  ) : loan.status === 3 ? (
                                    <i className="fa text-warning fa-dot-circle" />
                                  ) : loan.status === 2 ? (
                                    <i className="fa text-info fa-dot-circle" />
                                  ) : (
                                    <i className="fa text-muted fa-dot-circle" />
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-1">
                                <small className="text-muted">
                                  Date Collected
                                </small>
                                <p className="font-weight-bold">
                                  {moment(loan.created_at).format(
                                    "MMM Do YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="col-sm-1">
                                <small className="text-muted">Amount</small>
                                <p>
                                  <NumberFormat
                                    value={loan.requested_amount}
                                    className="font-weight-bold"
                                    displayType={"text"}
                                    prefix={"NGN "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </p>
                              </div>
                              <div className="col-sm-2">
                                <small className="text-muted">
                                  Repayment Amount
                                </small>
                                <p>
                                  <NumberFormat
                                    value={loan.repayment_amount}
                                    className="font-weight-bold"
                                    displayType={"text"}
                                    prefix={"NGN "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </p>
                              </div>
                              <div className="col-sm-1">
                                <small className="text-muted">Balance</small>
                                <p>
                                  <NumberFormat
                                    value={loan.balance}
                                    className="font-weight-bold"
                                    displayType={"text"}
                                    prefix={"NGN "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </p>
                              </div>
                              <div className="col-sm-1">
                                <small className="text-muted">
                                  Amount Paid
                                </small>
                                <p>
                                  <NumberFormat
                                    value={loan.amount_paid}
                                    className="font-weight-bold"
                                    displayType={"text"}
                                    prefix={"NGN "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </p>
                              </div>

                              <div className="col-sm-2">
                                <small className="text-muted">Penalty</small>
                                <p>
                                  <NumberFormat
                                    value={loan.penalty}
                                    className="font-weight-bold"
                                    displayType={"text"}
                                    prefix={"NGN "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </p>
                              </div>
                              <div className="col-sm-1">
                                <small className="text-muted">Duration</small>
                                <p className="font-weight-bold">
                                  {loan.duration} days
                                </p>
                              </div>
                              <div className="col-sm-1">
                                <small className="text-muted">Rate</small>
                                <p className="font-weight-bold">{loan.rate}%</p>
                              </div>
                              <div className="col-sm-1">
                                <small className="text-muted">Due Date</small>
                                <p className="font-weight-bold">
                                  {moment(loan.due_at).format("MMM Do YYYY")}
                                </p>
                              </div>
                            </div>

                            {loan.status !== 2 ? (
                              <h5 className="text-right">
                                <i className="fa fa-check-double mr-2 text-success "></i>
                                Approved By: {loan.approved_by}
                              </h5>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
