import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import NumberFormat from "react-number-format";
import moment from "moment";

import * as ConstantVar from "../../constants_var.js";

import axios from "axios";
// import moment from "moment/moment";

export default class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      perPage: 50,
      offset: 0,
      currentPage: 1,
      paginationLoaded: false,
      isLoading: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    this.getUserTransactions();
  }

  getUserTransactions = () => {

    this.setState({isLoading :true})
    let email = this.props.email,
      token = localStorage.getItem("supportToken"),
      per_page = this.state.perPage,
      current_page = this.state.currentPage;

      
    axios
      .get(
        ConstantVar.API_BASE_URL +
          `/support/user/transactions?email=${email}&token=${token}&per_page=${per_page}&current_page=${current_page}`
      )
      .then((res) => {
        if (res.data.success === "true") {
          this.setState({
            isLoading:false,
            transactions: res.data.data.data,
          });

          if (!this.state.paginationLoaded) {
            let total = res.data.data.pagination.total;
            this.setState({
              pageCount: Math.ceil(total / this.state.perPage),

              currentPage: res.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getUserTransactions();
    });
  };

  render() {
    var paginationElement;

    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div
        className="tab-pane fade show active"
        id="nav-transactions"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0">Transactions</h3>
              </div>
              <div className="pl-2 ml-5 success">{paginationElement}</div>
            </div>
          </div>
          {this.state.isLoading ? (
            <div style={{ padding: 50 }} className="text-center">
              <div>
                <p>Loading...</p>
              </div>
            </div>
          ) :
          <div className="">
            <div className="">
              <hr className="dash-hr" />
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead>
                    <tr>
                      <th>..</th>
                      <th>Date</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Description</th>
                      <th>M.W. Bal</th>
                      <th>L.W. Bal.</th>
                    </tr>
                  </thead>
                  <tbody>
              
                    {this.state.transactions.length > 0 ? (
                      this.state.transactions.map((trx, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                {trx.slug === "wallet_deposit" ? (
                                  <div className="wallet-bckg">
                                    <i className="fas fa-wallet wallet-color" />
                                  </div>
                                ) : trx.slug === "airtime_recharge" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-phone phone-color" />
                                  </div>
                                ) : trx.slug === "pay_cable" ? (
                                  <div className="tv-bckg">
                                    <i className="fas fa-tv tv-color" />
                                  </div>
                                ) : trx.slug === "tv_subscription" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-phone phone-color" />
                                  </div>
                                ) : trx.slug === "pay_electric" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-bolt phone-color" />
                                  </div>
                                ) : trx.slug === "fund_bet" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-money-bill-alt phone-color" />
                                  </div>
                                ) : trx.slug === "transfer" ? (
                                  <div className="tv-bckg">
                                    <i className="fas fa-paper-plane tv-color" />
                                  </div>
                                ) : trx.slug === "repay" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-coins phone-color" />
                                  </div>
                                ) : trx.slug === "akawo_credit" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-wallet phone-color" />
                                  </div>
                                ) : trx.slug === "invest" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-wallet phone-color" />
                                  </div>
                                ) : trx.slug === "loan" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-money-bill-alt phone-color" />
                                  </div>
                                ) : (
                                  <p>Hometown</p>
                                )}
                              </div>
                            </td>
                            
                            <td className="dash-td" style={{ width: "9%" }}>
                              <h4 className="text-muted trans-date">
                              
                                  {moment(trx.created_at).format(
                                    "Y MMM Do, h:mm:ss a"
                                  )}
                                <br />
                              
                              </h4>
                            </td>
                            <td
                              className="dash-td"
                              align="left"
                              style={{ width: "12%" }}
                            >
                              <p className="table-dash-details2 mb-0">
                                {trx.direction === "credit" ? (
                                  <span className="float-left trans-credit-color font-weight-normal">
                                    <NumberFormat
                                      value={trx.amount}
                                      className="card-bal-your"
                                      displayType={"text"}
                                      prefix={"NGN "}
                                      decimalScale={0}
                                      thousandSeparator={true}
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </td>
                            <td
                              className="dash-td"
                              align="left"
                              style={{ width: "12%" }}
                            >
                              <p className="table-dash-details2 mb-0">
                                {trx.direction === "debit" ? (
                                  <span className="float-left trans-debit-color font-weight-normal">
                                    <NumberFormat
                                      value={trx.amount}
                                      className="card-bal-your"
                                      displayType={"text"}
                                      prefix={"NGN "}
                                      decimalScale={0}
                                      thousandSeparator={true}
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </td>
                            <td className="dash-td">
                              <span className="badge-dot mr-4">
                                <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                  {trx.type}
                                </p>
                                <p className="table-dash-details2 mb-0">
                                  {trx.description}{" "}
                                </p>
                              </span>
                            </td>
                            <td className="dash-td">
                              <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                <NumberFormat
                                  value={trx.balance}
                                  className="card-bal-your"
                                  displayType={"text"}
                                  prefix={"NGN "}
                                  decimalScale={0}
                                  thousandSeparator={true}
                                />
                              </p>
                            </td>
                            <td className="dash-td">
                              <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                <NumberFormat
                                  value={trx.balance2}
                                  className="card-bal-your"
                                  displayType={"text"}
                                  prefix={"NGN "}
                                  decimalScale={0}
                                  thousandSeparator={true}
                                />
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No transaction done yet!
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pl-2 ml-5 success">{paginationElement}</div>
         
            </div>
          }
        </div>
      </div>
    );
  }
}
