import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

export default class send_bank_statement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      to: "",
      subject: "",
      message: null,
      pdf: null,
    };
  }

  handleChange = (e) => {
    e.target.name === "pdf"
      ? this.setState({ [e.target.name]: e.target.files[0] })
      : this.setState({ [e.target.name]: e.target.value });
  };

  sendAccountStatementPdf = (e) => {

    let self = this;

    self.setState({
      isLoading: true,
    });
    e.preventDefault();
    const data = new FormData();

    data.append("pdf", this.state.pdf);
    data.append("to", this.state.to);
    data.append("subject", this.state.subject);
    data.append("message", this.state.message);
    data.append("token", localStorage.getItem("supportToken"));

    alert("Do you confirm the entered details as correct?");

    axios
      .post(ConstantVar.API_BASE_URL + "/support/accountpdf/mail", data)
      .then((res) => {
        if (res.data.success === "true") {
          self.setState({
            isLoading: false,
            to: "",
            subject: "",
            message: "",
            pdf: null,
          });
          alert(res.data.message);
        } else {
          this.setState({
            isLoading: false,
          });
        }
      });

      
  };
  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Bank Statement</h2>
          </div>
          <div className="p-3 col-md-7 mx-auto">
            <div className="form-group mt-3">
              <label>Subject</label>
              <input
                type="text"
                className="form-control"
                name="subject"
                value={this.state.subject}
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                name="to"
                value={this.state.to}
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group mt-3 ">
              <label>Message</label>
              <textarea
                required
                name="message"
                className="form-control"
                placeholder="Enter your message here"
                value={this.state.message}
                onChange={this.handleChange}
                type="text"
              />
            </div>

            <div className="form-group">
              <label>Pdf file</label>
              <input
                required
                name="pdf"
                className="form-control"
                onChange={this.handleChange}
                type="file"
              />
            </div>
            <div className="mt-4">
              <button
                disabled={this.state.isLoading}
                onClick={this.sendAccountStatementPdf}
                className="btn btn-success"
              >
                Send Statement
              </button>
            </div>
          </div>

          <div></div>
        </div>
      </div>
    );
  }
}
