/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import TransferFund from "./transfer_fund.js";
import UpdateBVNNumber from "./update_bvn_number";
import VerifyBVN from "./verify_bvn";
import VerifyAcct from "./verify_acct";
import FundAcct from "./fund_account";
import UpdateAccess from "./update_access";
import DebitAcct from "./debit_account";
import GetUserOTP from "./get_user_otp.js";
import RavenCalls from "./raven_collection.js";
import CardCollections from "./card_collection.js";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "transfer-fund",
      verified: false,
    };
  }

  componentDidMount() {
    // send the otp to user email
    axios
      .post(ConstantVar.API_BASE_URL + "/support/send_otp_to_email", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        if (response.data.success === "true") {
        } else {
          alert(response.data.message);
        }
      });
  }

  confirmOTP = (otp) => {
    axios
      .post(ConstantVar.API_BASE_URL + "/support/confirm_auth_code", {
        token: localStorage.getItem("supportToken"),
        otp: otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          this.setState({
            otp: otp,
            verified: true,
          });
        } else {
          alert(response.data.message);
        }
      });
  };

  render() {
    let clearance = localStorage.getItem("supportClearance");

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">CPanel</h2>
                  </div>

                  <hr />
                  {this.state.verified ? (
                    <div>
                      <ul className="nav nav-pills  p-3">
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "transfer-fund" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "transfer-fund" })}
                          >
                            {" "}
                            Transfer Account Fund
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "update-bvn" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "update-bvn" })}
                          >
                            {" "}
                            Update BVN Number
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "verify-bvn" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "verify-bvn" })}
                          >
                            {" "}
                            Verify BVN
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "verify-acct" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "verify-acct" })}
                          >
                            {" "}
                            Update / Verify Account No.
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "fund-acct" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "fund-acct" })}
                          >
                            {" "}
                            Fund Account
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "update-access" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "update-access" })}
                          >
                            Update Access
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "debit-acct" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "debit-acct" })}
                          >
                            Debit Account
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " + (this.state.tab === "get-otp" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "get-otp" })}
                          >
                            Get OTP
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "raven-calls" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "raven-calls" })}
                          >
                            Raven Collection
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "card_collection" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "card_collection" })}
                          >
                            Card Collection
                          </a>
                        </li>
                      </ul>
                      <div className="p-3">
                        {this.state.tab === "transfer-fund" && clearance === "admin" ? (
                          <TransferFund otp={this.state.otp} />
                        ) : this.state.tab === "update-bvn" && clearance === "admin" ? (
                          <UpdateBVNNumber otp={this.state.otp} />
                        ) : this.state.tab === "verify-acct" && clearance === "admin" ? (
                          <VerifyAcct otp={this.state.otp} />
                        ) : this.state.tab === "verify-bvn" && clearance === "admin" ? (
                          <VerifyBVN otp={this.state.otp} />
                        ) : this.state.tab === "fund-acct" && clearance === "admin" ? (
                          <FundAcct otp={this.state.otp} />
                        ) : this.state.tab === "debit-acct" && clearance === "admin" ? (
                          <DebitAcct otp={this.state.otp} />
                        ) : this.state.tab === "update-access" ? (
                          <UpdateAccess otp={this.state.otp} />
                        ) : this.state.tab === "raven-calls" &&
                          (clearance === "admin" || clearance === "account") ? (
                          <RavenCalls otp={this.state.otp} />
                        ) : this.state.tab === "get-otp" && clearance === "admin" ? (
                          <GetUserOTP />
                        ) : this.state.tab === "card_collection" &&
                          clearance === "admin" ? (
                          <CardCollections />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-4 mx-auto">
                      <div className="form-group ">
                        <label>Enter OTP</label>
                        <input
                          required
                          name="otp"
                          className="form-control"
                          placeholder="Enter otp"
                          onChange={(evt) => {
                            let otp = evt.target.value;
                            if (otp.length === 6) {
                              // get user full name
                              this.confirmOTP(otp);
                            }
                          }}
                          type="text"
                        />
                        <small>
                          An otp has been sent to your email to perform this activity
                        </small>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
