import React, { Component } from 'react'
import BlockTransfer from './block_transfer';
import BlockAccount from './block_account';
import BlockP2PTransfer from "./block_p2p_transfer";

export default class Blockings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activity: ""

        };
    }

    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    render() {
        return (
          <div>
            <div className="form-group mt-3 ">
              <label>Block Activity List</label>
              <select
                required
                name="activity"
                className="form-control"
                onChange={this.handleChange}
              >
                <option value="">Select a blocking activity</option>
                <option value="block_transfer">Block Transfer</option>
                <option value="block_account">Block Account</option>
                <option value="block_p2p_transfer">Block P2P Transfer</option>
              </select>
            </div>
            {this.state.activity == "block_transfer" && <BlockTransfer />}
            {this.state.activity == "block_p2p_transfer" && <BlockP2PTransfer />}
            {this.state.activity == "block_account" && <BlockAccount />}
          </div>
        );
    }
}
