import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class BankLinkingException extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      nin: "",
      user_nin: null,
      user_info: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  applyBankLinking = () => {
    //bind this to self
    if (this.state.email === null || this.state.email.length < 5) {
      alert("Enter a valid email");
      return false;
    }
    this.setState({
      isLoading: true,
      email: null,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/increase_linked_banks", {
        token: localStorage.getItem("supportToken"),
        email: this.state.email,
      })
      .then((response) => {
        self.setState({
          isLoading: false,
          email: null,
        });
        alert(response.data.message);
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Manually Increase Linked Banks</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="email"
                className="form-control"
                placeholder="User Email"
                value={this.state.email}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button onClick={this.applyBankLinking} className="btn btn-success">
              Apply
            </button>
          </div>

          {this.state.isLoading ? (
            <div class="text-center">
              <label>Loading....</label>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default BankLinkingException;
