import React, { Component } from "react";
import { CSVLink } from "react-csv";

export default class HometownModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className="modal fade"
        id="trxSearchModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="trxSearchModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="trxSearchModalLabel">
                Filter
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form autoComplete="off">
              <div className="modal-body">
                <div className="form-group">
                  STATUS
                  <select
                    className="form-control form-control-sm"
                    name="status"
                    onChange={this.props.handleChange}
                  >
                    <option value="all">All</option>
                    <option value="successful">Successful Withdrawals</option>
                    <option value="pending">Pending Withdrawals</option>
                    <option value="failed">Failed Withdrawals</option>
                  </select>
                  <label className="float-right">
                    <small>Search P2P</small>
                    <input
                      name="isP2P"
                      checked={this.props.isP2P ? "checked" : ""}
                      onChange={this.props.handleChange}
                      type="checkbox"
                      className="ml-2"
                    />
                  </label>
                </div>
                <div className="form-group">
                  PROVIDER
                  <select
                    className="form-control form-control-sm"
                    name="provider"
                    onChange={this.props.handleChange}
                  >
                    <option value="all">All</option>
                    <option value="raven">Raven</option>
                    <option value="providus">Providus</option>
                    <option value="paystack">Paystack</option>
                  </select>
                </div>
                {/* REFERENCE      
                  <div className="form-group">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      name="reference"
                      onChange={this.props.handleChange}

                        //   placeholder={
                        //     this.props.state.searchTransaction
                        //       ? "Enter transactionID"
                        //       : "FirstName or Email"
                        //   }
                        //   value={this.props.state.search_text}
                        //   onChange={this.props.handleSearchTextInput}
                    />
                  </div> */}
                EMAIL
                <div className="form-group">
                  <input
                    className="form-control form-control-sm"
                    type="email"
                    name="email"
                    onChange={this.props.handleChange}
                  />
                </div>
                DESTINATION ACCOUNT NUMBER
                <div className="form-group">
                  <input
                    className="form-control form-control-sm"
                    type="numeric"
                    name="account_number"
                    maxLength={10}
                    onChange={this.props.handleChange}
                  />
                </div>
                {/* {!this.props.state.searchTransaction ? ( */}
                <div className="form-group row">
                  <div className="col-6 form-inline">
                    <label className="form-control-label">Start Date</label>
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      name="start_date"
                      onChange={this.props.handleChange}
                    />
                  </div>
                  <div className="col-6 form-inline">
                    <label className="form-control-label">End Date</label>
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      name="end_date"
                      // value={this.props.state?.sEndDate}
                      onChange={this.props.handleChange}
                    />
                  </div>
                </div>
                {/* ) : null} */}
              </div>
              <div className="modal-footer d-flex flex-column justify-content-center align-items-center">
                <CSVLink
                  data={this.props.data}
                  className="btn btn-warning btn-sm my-1"
                  filename={this.props.getFileName}
                  download={true}
                >
                  Download report
                </CSVLink>
                <button
                  onClick={this.props.handleSearch}
                  className="btn my-1 btn-primary btn-sm"
                >
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
