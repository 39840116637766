import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class DisbursementProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      provider: null,
      providerList: [],
      currentProvider: "",
      deactivate_account: true,
      pin: null,
    };
  }

  componentDidMount() {
    this.getProvider();
    this.getProviderList();
  }
  handleChange = (evt) => {
    if (evt.target.name === "provider") {
      this.setState({ [evt.target.name]: evt.target.value, currentProvider: null });
    } else {
      this.setState({ [evt.target.name]: evt.target.value });
    }
  };

  getProvider = () => {
    axios
      .post(ConstantVar.API_BASE_URL + "/support/settings_get_provider", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        let data = response.data.data;
        this.setState({
          currentProvider: data,
        });
      });
  };

  getProviderList = () => {
    axios
      .get(
        ConstantVar.API_BASE_URL +
          `/support/settings_list_provider?token=${localStorage.getItem("supportToken")}`
      )
      .then((response) => {
        let data = response.data.data;

        this.setState({
          providerList: data,
        });
      });
  };

  setProvider = () => {
    let provider = this.state.provider;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/settings_provider", {
        token: localStorage.getItem("supportToken"),
        provider: provider,
      })
      .then((response) => {
        let data = response.data;
        if (data.success === "true") {
          this.setState({ currentProvider: provider });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Disbursement Provider</h2>
          </div>
          {/* Switch Platform */}
          <hr />
          <div className="my-2 d-flex align-items-center justify-content-center">
            <div className="col-md-4">
              <label>SWITCH PLATFORM</label>
            </div>
            <div className="col-md-4">
              {/* <label htmlFor="normal-switch">
                        {" "}
                        <Switch
                          onChange={this.handlePlatformSwitch}
                          checked={this.state.currentPlatform}
                          uncheckedIcon={false}
                          value={this.state.currentPlatform}
                          checkedIcon={false}
                          id="normal-switch"
                        />
                      </label> */}

              <select
                className="form-control"
                name="provider"
                value={
                  this.state.currentProvider !== ""
                    ? this.state.currentProvider
                    : this.state.provider
                }
                onChange={this.handleChange}
              >
                {this.state.providerList.length > 0 &&
                  this.state.providerList.map((item, index) => (
                    <option key={index} value={item.provider_name}>
                      {item.provider_name.toUpperCase()}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4">
              <button
                disabled={this.state.currentProvider}
                className={`btn ${
                  !this.state.currentProvider ? "btn-success" : "btn-light"
                }`}
                onClick={this.setProvider}
              >
                {this.state.currentProvider
                  ? `${this.state.currentProvider.toUpperCase()} Active `
                  : ` Activate ${this.state.provider} `}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DisbursementProvider;
