import React, { Component } from 'react'
import NumberFormat from "react-number-format";
import axios from "axios";
import moment from "moment/moment";
import * as ConstantVar from "../../constants_var.js";

export default class Guarantors extends Component {
    constructor(props) {
    super(props);
    }

  render() {
    return (
      <div>
        <div
          className="tab-pane fade show active"
          id="nav-guarantors"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div className="card bg-secondary shadow">
            <div className="card-header bg-white border-0">
              <div className="row align-items-center">
                <div className="col-12">
                  <h3 className="mb-0">Guarantors</h3>
                </div>
              </div>
            </div>
            <div className="">
              <div className="">
                {(() => {
                  if (this.props.user.guarantors.length === 0)
                    return (
                      <div className="p-5">
                        <div className="text-center">
                          <h3>No guarantors</h3>
                        </div>
                      </div>
                    );
                  else {
                    return (
                      <div>
                        {this.props.user.guarantors.map((guarantor, index) => {
                          return (
                            <div className="loan-card p-4" key={index}>
                              <div className="row">
                                <div className="col-sm-1">
                                  <small className="text-muted">S/N</small>
                                  <div className="P-3">{index + 1}</div>
                                </div>
                                <div className="col-sm-3">
                                  <small className="text-muted">Name</small>
                                  <p className="font-weight-bold">
                                    {guarantor.name.toUpperCase()}
                                  </p>
                                </div>

                                <div className="col-sm-3">
                                  <small className="text-muted">
                                    Phone Number
                                  </small>
                                  <p className="font-weight-bold">
                                    {guarantor.phone}
                                  </p>
                                </div>
                                <div className="col-sm-3">
                                  <small className="text-muted">
                                    Relationship
                                  </small>
                                  <p className="font-weight-bold">
                                    {guarantor.relationship}
                                  </p>
                                </div>

                                <div className="col-sm-1">
                                  <small className="text-muted">Added at</small>
                                  <div>
                                    {moment(guarantor.created_at).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
