import axios from "axios";
import React, { Component } from "react";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";

export default class NewFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      accountNumber: "",
      raven_trx:"",
      destination_accounts: [],
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  activityUrl = (activity) => {
    if (activity == "nameAndLocationBlock") {
      return "stop_block_loan";
    } else if (activity == "overrideLoan") {
      return "override_loan";
    } else if (activity == "bvnPhoneNumberUpdate") {
      return "bvn_phone_update";
    } else if (activity == "confirmNinBvnPhoneVerification") {
      return "bvn_unset";
    } else if (activity == "loanCounterReset") {
      return "loan_discarded_counter";
    } else if (activity == "alternateEmail") {
      return "alt_email";
    } else if (activity == "Wallet Transfer") {
      return "in_app_transactions";
    } else if (activity == "destination_accounts") {
      return "destination_accounts";
    } else if (activity == "reset_transaction") {
      return "reset_transaction";
    } else {
      return "";
    }
  };

  performUpdate = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      transactions: [],
    });
    if(this.state.activity === "overrideLoan"){
     if  (!window.confirm("Are you sure you want to perfom this Loan Override?"))
     return
    }
    
    let self = this;
    axios
      .post(
        ConstantVar.API_BASE_URL +
          `/support/activities/${this.activityUrl(this.state.activity)}`,
        {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
          altEmail: this.state.altEmail,
          start: this.state.start,
          end: this.state.end,
          transactionRef: this.state.raven_trx.trim(),
          accountNumber: this.state.accountNumber,
        }
      )
      .then((response) => {      
        
        self.setState({
          isLoading: false
        });
        if (response.data.success === "true") {
          self.setState({
            email: "",
            altEmail: "",
          });
          alert(response.data.message);

        } else {
        
          alert(response.data.message);
          self.setState({ ...this.state, transactions: response.data.data });
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  handleDestinationsSearch = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    
    let self = this;
    axios
      .post(
        ConstantVar.API_BASE_URL +
          `/support/activities/${this.activityUrl(this.state.activity)}`,
        {
          token: localStorage.getItem("supportToken"),
          accountNumber: this.state.accountNumber,
        }
      )
      .then((response) => {
        let data = response.data;
        self.setState({
          isLoading: false
        });
        if (data.success == true) {
          this.setState({
            destination_accounts: data.message.flat(2),
          });
        } else {
          console.log("error")
        }
      })
      .catch((error) => {
        alert(error);
      });
      
  };

  render() {
    let clearance = localStorage.getItem("supportClearance");
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Resets & Overrides / Account_Info</h2>
          </div>
          <div className="row mt-1">
            <div className="col-md-6 mt-3">

              {
                this.state.activity  !== "reset_transaction"
              ?
                <>
                  {this.state.activity !==  "destination_accounts" && (
                  <div className="input-group ">
                    <input
                      required
                      name="email"
                      className="form-control"
                      placeholder="User Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      type="text"
                    />
                  </div>
                )}
                {this.state.activity == "Wallet Transfer" && (
                  <>
                    <div className="form-inline mt-3 row">
                      <label>Start Date:</label>
                      <input
                        type="date"
                        className="ml-2 mr-2 form-control"
                        name="start"
                        value={this.state.start}
                        onChange={this.handleChange}
                      />
                      <label>End Date:</label>
                      <input
                        type="date"
                        className="ml-2 mr-2 form-control"
                        name="end"
                        value={this.state.end}
                        onChange={this.handleChange}
                      />
                    </div>
                    {this.state?.transactions?.map((trx, i) => (
                      <table
                        className="table align-items-center table-flush"
                        key={i}
                      >
                        <tbody>
                          <tr>
                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                {trx.slug === "wallet_deposit" ? (
                                  <div className="wallet-bckg">
                                    <i className="fas fa-wallet wallet-color" />
                                  </div>
                                ) : trx.slug === "airtime_recharge" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-phone phone-color" />
                                  </div>
                                ) : trx.slug === "pay_cable" ? (
                                  <div className="tv-bckg">
                                    <i className="fas fa-tv tv-color" />
                                  </div>
                                ) : trx.slug === "tv_subscription" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-phone phone-color" />
                                  </div>
                                ) : trx.slug === "pay_electric" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-bolt phone-color" />
                                  </div>
                                ) : trx.slug === "fund_bet" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-money-bill-alt phone-color" />
                                  </div>
                                ) : trx.slug === "transfer" ? (
                                  <div className="tv-bckg">
                                    <i className="fas fa-paper-plane tv-color" />
                                  </div>
                                ) : trx.slug === "repay" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-coins phone-color" />
                                  </div>
                                ) : trx.slug === "akawo_credit" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-wallet phone-color" />
                                  </div>
                                ) : trx.slug === "invest" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-wallet phone-color" />
                                  </div>
                                ) : trx.slug === "loan" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-money-bill-alt phone-color" />
                                  </div>
                                ) : trx.slug === "refund" ? (
                                  <div className="phone-bckg">
                                    <i className="fas fa-undo phone-color" />
                                  </div>
                                ) : (
                                  <p>Hometown</p>
                                )}
                              </div>
                            </th>
                            <td className="dash-td" style={{ width: "9%" }}>
                              <h4 className="text-muted trans-date">
                                {moment(trx.created_at).format(
                                  "MMM Do, h:mm:ss a"
                                )}
                              </h4>
                            </td>
                            <td className="dash-td">
                              <span className="badge-dot mr-4">
                                <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                  {trx.type}
                                </p>
                                <p className="table-dash-details2 mb-0">
                                  <small>{trx.description} </small>
                                </p>
                              </span>
                            </td>
                            <td className="dash-td">
                              <p className="margin-user-text">
                                {trx.fname} {trx.lname}
                              </p>
                              <small>
                                <a target="_blank" href={"/user/" + trx.email}>
                                  {trx.email}
                                </a>
                              </small>
                            </td>
                            <td className="dash-td">
                              {trx.direction === "credit" ? (
                                <span className="float-right trans-credit-color font-weight-normal">
                                  <NumberFormat
                                    value={trx.amount}
                                    className="card-bal-your"
                                    displayType={"text"}
                                    prefix={"+ NGN "}
                                    decimalScale={0}
                                    thousandSeparator={true}
                                  />
                                </span>
                              ) : (
                                <span className="float-right trans-debit-color font-weight-normal">
                                  <NumberFormat
                                    value={trx.amount}
                                    className="card-bal-your"
                                    displayType={"text"}
                                    prefix={"- NGN "}
                                    decimalScale={0}
                                    thousandSeparator={true}
                                  />
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
                  </>
                )}

                {this.state.activity == "alternateEmail" && (
                  <div className="input-group mt-3 ">
                    <input
                      required
                      name="altEmail"
                      className="form-control"
                      placeholder="Enter alternate Email"
                      value={this.state.altEmail}
                      onChange={this.handleChange}
                      type="text"
                    />
                  </div>
                )}
                {this.state.activity == "destination_accounts" &&
                  (clearance == "loan-officer" || "admin") && (
                    <div className="input-group mt-3 ">
                      <input
                        required
                        name="accountNumber"
                        className="form-control"
                        placeholder="Enter account number"
                        value={this.state.accountNumber}
                        onChange={this.handleChange}
                        type="text"
                      />
                    </div>
                  )
                }
              </>
              :
              <div className="input-group ">
              <input
                required
                name="raven_trx"
                className="form-control"
                placeholder="Raven transaction reference"
                value={this.state.raven_trx}
                onChange={this.handleChange}
                type="text"
              />
            </div>
              }

              <div className="form-group mt-3 ">
                <label>Activities List</label>
                <select
                  required
                  name="activity"
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option value="">Select an Activity</option>
                  <option value="nameAndLocationBlock">
                    Block Loan Offer Reset
                  </option>
                  <option value="alternateEmail">Add Alternate Email</option>
                  {(clearance === "admin" || clearance === "loan-officer") && (
                    <>
                      <option value="loanCounterReset">
                        Loan Counter Reset
                      </option>
                      <option value="overrideLoan">Loan Override</option>
                      
                      <option value="destination_accounts">
                        Destination Accounts
                      </option>
                      <option value="Wallet Transfer">
                        Wallet Transfer Info.
                      </option>
                    </>
                  )}
                  <option value="bvnPhoneNumberUpdate">
                    Reset NIN/BVN Phone-number (Skip Confirmation){" "}
                  </option>
                { clearance === "admin" &&
                  <option value="confirmNinBvnPhoneVerification">
                    Reset NIN/BVN Verification
                  </option>
                }
                  {
                         (clearance === "admin" || clearance === "account") && 
                          <option value="reset_transaction">Reset Raven Transaction Status</option>
                        }
                </select>
              </div>
              <button
                disabled={!this.state.activity || this.state.isLoading}
                className={!this.state.isLoading ? "btn btn-success" : "btn btn-danger"}
                onClick={
                  this.state.activity === "destination_accounts"
                    ? this.handleDestinationsSearch
                    : this.performUpdate
                }
              >
                Go
              </button>
            </div>
            {(this.state.activity == "destination_accounts" && this.state.destination_accounts.length) && (
              <div className="col-md-6 mb-3">
                <h4 className="mt-2 pl-4">Destination Emails</h4>
                {this.state.destination_accounts.map((item, index) => (
                  <div key={index}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
