import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";


class UpdateAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      pin: "",
      account_name: "",
      available_bal: 0,
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  updateAccess = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    let amount = this.state.amount


    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/update_user_access", {
        token: localStorage.getItem("supportToken"),
        phone: this.state.phone,
        pin: this.state.pin,
        option: this.state.option,
        amount,
        otp: this.props.otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            phone: "",
            account_name: "",
            pin: "",
            amount:0
          });
        } else {
          self.setState({
            isLoading: false,
          });
        }
        alert(response.data.message);
      });
  };

  getAccountDetails = (phone) => {
    this.setState({
      account_name: "",
    });

    this.setState({
      account_name: "",
    });
    let self = this;
    axios
      .post(
        ConstantVar.API_BASE_URL + "/support/get_hometown_account_details",
        {
          token: localStorage.getItem("supportToken"),
          phone: phone,
        }
      )
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            account_name: response.data.account_name,
          });
        } else {
          self.setState({
            account_name: "Not Found",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Update Access</h2>
          </div>

          <form className=" p-3 col-md-7" onSubmit={this.updateAccess}>
            <div className="form-group ">
              <label>Phone Number</label>
              <input
                required
                name="phone"
                className="form-control"
                placeholder="Phone Number"
                value={this.state.phone}
                onChange={(evt) => {
                  let phone = evt.target.value;
                  if (phone.length === 11) {
                    // get user full name
                    this.getAccountDetails(phone);
                  }
                  this.handleChange(evt);
                }}
                type="phone"
              />
              <small className="text-info">{this.state.account_name}</small>
            </div>

            <div className="form-group ">
              <label>Options</label>
              <select
                required
                name="option"
                className="form-control"
                value={this.state.option}
                onChange={this.handleChange}
              >
                <option value="">Select option</option>
                <option value="remove-as-def">Remove as defaulter</option>
                <option value="unset-phone">Unset Phone Number</option>
                <option value="unset-bvn">Unset BVN Number</option>
                <option value="block">Block User</option>
                <option value="unblock">Unblock User</option>
                <option value="clear-loan-wallet">Clear Loan Wallet</option>
                <option value="clear-main-wallet">Clear Main Wallet</option>
                <option value="increase-cs">Increase Credit Score</option>
                <option value="decrease-cs">Decrease Credit Score</option>
                <option value="move-loan-bal">
                  Move Loan Fund to Main wallet
                </option>
                <option value="move-main-bal">
                  Move Main Fund to Loan wallet
                </option>
              </select>
            </div>

            {/* {this.state.option == "move-main-bal"  &&
              <div className="form-group ">
                 <NumberFormat
                    allowNegative={false}
                    placeholder="Amount"
                    value={this.state.amount}
                    name="amount"
                    // onChange={this.setAmount}
                    onChange={this.handleChange}

                    thousandSeparator={true}
                    className="form-control"
                  />
              </div>
            } */}
            <div className="form-group ">
              <label>Your PIN</label>
              <input
                required
                name="pin"
                className="form-control"
                placeholder="PIN"
                value={this.state.pin}
                onChange={this.handleChange}
                type="password"
              />
            </div>

            <button className="btn btn-success" disabled={this.state.isLoading}>
              Continue
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default UpdateAccess;
