import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import InApp from "./in_app.js";
import Emails from "./emails.js";
// import Blog from "./blog.js";


class Messaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "in-app",
    }
  }

 
  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Message Center</h2>
                    <ul className="nav nav-pills  p-3">
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "in-app"
                              ? "active"
                              : "")
                          }
                          href="#"
                          onClick={() =>
                            this.setState({ tab: "in-app" })
                          }
                        >
                          {" "}
                          In-App
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "emails"
                              ? "active"
                              : "")
                          }
                          href="#"
                          onClick={() =>
                            this.setState({ tab: "emails" })
                          }
                        >
                          Emails
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "blog"
                              ? "active"
                              : "")
                          }
                          href="#"
                          onClick={() =>
                            this.setState({ tab: "blog" })
                          }
                        >
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {this.state.tab ==="in-app" ? (
                      <InApp />
                      ) : this.state.tab === "emails" ? (
                        <Emails/>
                      // ) : this.state.tab === "blog" ? (
                      //   <Blog/>
                    ): (
                       "" 
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Messaging;
