import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";

class Loan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      today_num: null,
      today_sum: null,
      week_num: null,
      week_sum: null,
      month_num: null,
      month_sum: null,
      start_date: null,
      end_date: null,
      noOfLoans_range: 0,
      sumOfLoans_range: 0,
      year_num: null,
      year_sum: null,
      overall_num: null,
      overall_sum: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount() {
    let self = this;
    axios
      .get(
        ConstantVar.API_BASE_URL +
          "/support/loan_report?token=" +
          localStorage.getItem("supportToken")
      )
      .then((response) => {
        if (response.data.success == "true"){
          let data = response.data.data;
          self.setState({
            today_num: data.today.num,
            today_sum: data.today.sum,
            week_num: data.week.num,
            week_sum: data.week.sum,
            month_num: data.month.num,
            month_sum: data.month.sum,
            year_num: data.year.num,
            year_sum: data.year.sum,
            overall_num: data.overall.num,
            overall_sum: data.overall.sum,
          });

        }

      });
  }

  getLoanReportRange = (evt) => {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/loan_report_range", {
        token: localStorage.getItem("supportToken"),
        start_date: self.state.start_date,
        end_date: self.state.end_date,
      })
      .then((response) => {
        let data = response.data.data;
        self.setState({
          isLoading: false,
          noOfLoans_range: data.num,
          sumOfLoans_range: data.sum,
        });
      });
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="card shadow col-md-5 p-3 mr-2">
            <div className="row">
              <h4 className="col-md-9">Loan Granted</h4>
              {/*<a href="#" className="col-md-3 text-right ">
              Filter
          </a>  */}
            </div>

            <table>
              <tbody>
                <tr>
                  <th className="p-3">Today</th>
                  <td className="p-3">{this.state.today_num}</td>
                  <td className="p-3">
                    <NumberFormat
                      value={this.state.today_sum}
                      thousandSeparator={true}
                      displayType={"text"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="p-3">This Week</th>
                  <td className="p-3">{this.state.week_num}</td>
                  <td className="p-3">
                    <NumberFormat
                      value={this.state.week_sum}
                      thousandSeparator={true}
                      displayType={"text"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="p-3">This Month</th>
                  <td className="p-3">{this.state.month_num}</td>
                  <td className="p-3">
                    <NumberFormat
                      value={this.state.month_sum}
                      thousandSeparator={true}
                      displayType={"text"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="p-3">This Year</th>
                  <td className="p-3">{this.state.year_num}</td>
                  <td className="p-3">
                    <NumberFormat
                      value={this.state.year_sum}
                      thousandSeparator={true}
                      displayType={"text"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="p-3">Overall</th>
                  <td className="p-3">{this.state.overall_num}</td>
                  <td className="p-3">
                    <NumberFormat
                      value={this.state.overall_sum}
                      thousandSeparator={true}
                      displayType={"text"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card shadow col-md-5 p-3 ">
            <div className="row">
              <h4 className="col-md-9">Filter Loan Granted</h4>
              {/* <a
              href="#"
              className="col-md-3 text-right fa fa-arrow-circle-left"
            ></a>
           */}
            </div>

            <table>
              <tbody>
                <tr>
                  <th className="p-3">From</th>
                  <td className="p-3">
                    <input
                      type="date"
                      className="form-control"
                      name="start_date"
                      value={this.state.start_date}
                      onChange={this.handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="p-3">To</th>
                  <td className="p-3">
                    <input
                      type="date"
                      className="form-control"
                      name="end_date"
                      value={this.state.end_date}
                      onChange={this.handleChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="p-3 text-center" colSpan="2">
                    <button
                      className="btn btn-primary"
                      onClick={this.getLoanReportRange}
                    >
                      View
                    </button>
                  </td>
                </tr>

                <tr>
                  <td className="p-3 text-center" colSpan="2">
                    Loan granted from <strong>{this.state.start_date}</strong>{" "}
                    to <strong>{this.state.end_date}</strong>
                    <table
                      border="1"
                      align="center"
                      className="mt-2"
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <th className="p-3">No of Loans</th>
                          <td className="p-3">{this.state.noOfLoans_range}</td>
                        </tr>
                        <tr>
                          <th className="p-3">Total</th>
                          <td className="p-3">
                            <NumberFormat
                              value={this.state.sumOfLoans_range}
                              thousandSeparator={true}
                              displayType={"text"}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Loan;
