import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";
import { CSVLink,  } from "react-csv";
import moment from "moment";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
        tab :"celebrating-users",
        // date:"",
        date:moment().format("YYYY-MM-DD"),
      total: 0,
      reports: [],
      generate: null,
      isSubmitting: false,

    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount() {
    this.getBirthDays()
  }

  getBirthDays = () => {
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/support/reports/stats", {
       params: { token: localStorage.getItem("supportToken"),
        birthday: self.state.date,
        type:"birthday-report"}
      })
      .then((response) => {
        if (response.data.status === "true") {
            this.setState({
              total: response.data.data,
              isSubmitting: false,
            });
          }      });
  };

  getBirthDayList = () => {
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/support/reports/stats", {
       params: { token: localStorage.getItem("supportToken"),
        birthday: self.state.date,
        type:"birthday"}
      })
      .then((response) => {
        if (response.data.status === "true") {
            this.setState({
                reports: response.data.data,
                generate: 1,
            });
        }    
     });
  };

  getFileName = () => {
    let date = this.state.date
      ? `_${this.state.date}`
      : `_${moment().format("YYYY-MM-DD")}`;

    return `Customers birthday list for ${date}.csv`;
  };

  render() {
    return (
      <div className="container">
       
       <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">Users Stats</h3>
              </div>

              <hr />

                <div>
                    <ul className="nav nav-pills  p-3">
                        <li className="nav-item">
                            <a
                            className={
                                "nav-link " +
                                (this.state.tab === "celebrating-users" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "celebrating-users" })}
                            >
                            {" "}
                            Celebrating Users
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                            className={
                                "nav-link " +
                                (this.state.tab === "celebrating-users" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "celebrating-users" })}
                            >
                            {" "}
                            Celebrating Users
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="p-5">
                <div className="form-inline">
                <label>Start Date </label>{" "}
                <input
                    type="date"
                    name="date"
                    value={this.state.date || ""}
                    className="form-control ml-2 mr-2"
                    onChange={this.handleChange}
                />
                {/* <label>End Date </label>{" "}
                <input
                    type="date"
                    name="endDate"
                    value={this.state.endDate || ""}
                    className="form-control ml-2 mr-2"
                    onChange={this.handleChange}
                /> */}
                <button className="btn btn-primary" onClick={this.getBirthDays}>
                    Continue
                </button>
                </div>

                {!this.state.isSubmitting ? (
                <div className="table-responsive mt-5 p-5">
                    <strong>
                        <address
                            style={{
                            fontSize: "22px",
                            }}
                        >
                            <NumberFormat
                            value={this.state.total}
                            displayType={"text"}
                            thousandSeparator={","}
                            className="h1"
                            suffix=" Users"
                            />
                        </address>
                    </strong>

                    <p>
                    {this.state.generate === null && this.state.total > 0 ? (
                        <a href="#" onClick={this.getBirthDayList}>
                        Generate Report
                        </a>
                    ) : this.state.generate === 0 ? (
                        <div className="p-5 text-center">
                        <h5>Generating....</h5>
                        </div>
                    ) : this.state.generate === 1 ? (
                        <CSVLink
                        data={this.state.reports}
                        className="btn btn-info"
                        filename={this.getFileName()}
                        >
                        Download report
                        </CSVLink>
                    ) : (
                        ""
                    )}
                    </p>
                </div>
                ) : (
                <div className="p-5 text-center">
                    <h3>Loading....</h3>
                </div>
                )}
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default User;
