import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class GetUserOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      otp: "",
    };
  }

  handleChange = (evt) => {
    this.setState({ phone: evt.target.value });
  };

  getUserOTP = (type) => {
    // if the request is to block the transfer
    //validate
    let phone = this.state.phone;
    if (phone.length < 11) {
      alert("Enter User Phone Number");
      return false;
    }

    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/get_user_otp", {
        token: localStorage.getItem("supportToken"),
        phone: phone,
        type: type,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            otp: response.data.data,
          });
        } else {
          self.setState({
            isLoading: false,
          });
          alert(response.data.message);
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Get User OTP</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="phone"
                className="form-control"
                placeholder="Enter User Phone"
                value={this.state.phone}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button
              onClick={() => this.getUserOTP("registration")}
              className="btn btn-success"
            >
              Get Registration OTP
            </button>

            <button
              onClick={() => this.getUserOTP("verification")}
              className="btn btn-success ml-3"
            >
              Get Verification OTP
            </button>
          </div>

          <hr />

          {this.state.isLoading ? (
            <div class="text-center">
              <label>Please wait....</label>
            </div>
          ) : (
            <div class="p-3">
              <hi>OTP: {this.state.otp}</hi>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GetUserOTP;
