import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import Auto from "./auto";
import ManualWithdrawals from "./manual_withdrawsl";
import WithdrawalsWithIssues from "./withdrawsl_issues";

class Withdrawals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "auto-withdrawls",
    };
  }

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Withdrawals</h2>
                    <ul className="nav nav-pills  p-3">
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "auto-withdrawls"
                              ? "active"
                              : "")
                          }
                          href="#"
                          onClick={() =>
                            this.setState({ tab: "auto-withdrawls" })
                          }
                        >
                          {" "}
                          Automatic Withdrawals
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "manually-withdrawls"
                              ? "active"
                              : "")
                          }
                          href="#"
                          onClick={() =>
                            this.setState({ tab: "manually-withdrawls" })
                          }
                        >
                          Manually Verified Withdrawals
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "withdrawls-issues"
                              ? "active"
                              : "")
                          }
                          href="#"
                          onClick={() =>
                            this.setState({ tab: "withdrawls-issues" })
                          }
                        >
                          Withdrawals with Issues
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div>
                    {this.state.tab === "auto-withdrawls" ? (
                      <Auto />
                    ) : this.state.tab === "manually-withdrawls" ? (
                      <ManualWithdrawals />
                    ) : this.state.tab === "withdrawls-issues" ? (
                      <WithdrawalsWithIssues />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Withdrawals;
