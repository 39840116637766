import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class In_App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: [],
      isLoading: true,
      target_users: [],
      pin:""
    };
    this.createMessage = this.createMessage.bind(this);
    this.clearInputField = this.clearInputField.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  createMessage(e) {
    e.preventDefault();
    
    if (e.target.title.value.length < 5) {
      alert("message title is to short");
      return;
    }
    if (e.target.body.value.length < 10) {
      alert("message content is to short");
      return;
    }
    if (e.target.pin.value.length < 4) {
      alert("incomplete PIN");
      return;
    }
  let  target_users = this.state.target_users;

  
  if(target_users.length == 0){ 
    const allowBroadcast = window.confirm("Are you sure you wish to make a broadCast?")
    if (!allowBroadcast) return 
  }

    this.setState({
      isSubmitting: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/message_users ", {
        title: this.state.title,
        body: e.target.body.value,
        token: localStorage.getItem("supportToken"),
        target_users: target_users,        
        pin: this.state.pin,


      })
      .then((response) => {
        this.setState({
          isSubmitting: false,
          pin:""
        });
        if (response.data.success === "true") {

          alert(response.data.message);

          // window.location.href = "/messaging";
        } else {
          alert(response.data.message);
        }
      });
  }

  handleChange = (e) => {
     this.setState({ [e.target.name]: e.target.value });
  };

  

  handleFiles = (event) => {
    var input = event.target;
    let self = this;
    var reader = new FileReader();
    reader.onload = function () {
      var text = reader.result;
      var data = window.$.csv.toObjects(text);
      self.setState({
        target_users: data,
      });
    };
    if (input.files[0]) reader.readAsText(input.files[0]);
  };

  clearInputField = (e) => {
    e.preventDefault()
  this.setState({
        target_users: [],
      }); };

  render() {

    return (
      <div>
        <div className="card shadow">
          <form
            role="form"
            style={{ padding: 50 }}
            onSubmit={this.createMessage}
            autoComplete="off"
          >
            <h2>Message All Users</h2>
            <div className="form-group mb-3">
              <div className="input-group input-group-alternative">
                <input
                  required
                  autoComplete="off"
                  name="title"
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Title of message"
                  type="text"
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <div className="input-group input-group-alternative">
                <textarea
                  rows={10}
                  required

                  autoComplete="off"
                  name="body"
                  className="form-control"
                  placeholder="Body of message"
                />
              </div>
            </div>
            <div className="form-inline mb-3  d-flex align-items-center">
              <label>Target Users</label>
              <div className="input-group input-group-alternative">
                <input
                  name="targets"
                  accept=".csv"
                  className="form-control"
                  type="file"
                  onChange={this.handleFiles}
                />

              </div>
                { this.state.target_users.length > 0 &&
                <h4 className="text-danger" onClick={this.clearInputField}>Clear Input</h4>
                }
            </div>
            <div className="form-group mb-3">
              <div className="input-group input-group-alternative">
                <input
                  required
                  autoComplete="off"
                  name="pin"
                  className="form-control"
                  placeholder="Confirm PIN"
                  onChange={this.handleChange}
                  value={this.state.pin}

                  type="text"
                />
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                disabled={this.state.isSubmitting}
                className="btn btn-primary my-2 w-100"
              >
                Send now (Kindly crosscheck before clicking this)
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default In_App;
