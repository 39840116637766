import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      transaction_reference:this.props.reference || "",
      transfer :undefined
      // 202303141507EHFBFGJ
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount(){
    if (this.state.transaction_reference !== "") {
    this.getTransfer()
    }
  }

  getTransfer = () => {
    this.setState({
      isLoading: true,
    });

    let self = this;
    let token = localStorage.getItem("supportToken")

    axios.post(ConstantVar.API_BASE_URL + `/support/get_raven/transfer`,{
      token,
      transaction_reference:this.state.transaction_reference
    }).then((res) => {
       
         if (res.data.status == "success") {
          self.setState({
            isLoading:false,
            transfer : res.data.data,
            transaction_reference:""
          });
         }else{
            window.alert(res.data.message.toUpperCase())
            self.setState({
              isLoading:false,
            });
         }
      });
  };

  render() {

    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Transfers</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="transaction_reference"
                className="form-control"
                placeholder="Enter Transaction reference"
                value={this.state.transaction_reference}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button
              onClick={() => this.getTransfer()}
              className="mx-2 btn btn-success"
              disabled={this.state.isLoading}
            >
              Search
            </button>
          </div>

          <hr />

          {this.state.isLoading && (
            <div className="text-center">
              <label>Please wait....</label>
            </div>
         
          )}

          {this.state.transfer !== undefined ? (
            <div className="mx-5">
              <h3 className="p-3">Transfer Details</h3>

              <div>
                <div className="mx-5" >
                  <h5>Amount</h5>
                  <p className="d-flex justify-content-between align-items-center">
                    
                    
                    <span className="h1" >
                    
                    <NumberFormat
                      className={ this.state.transfer.status === "failed" ? " text-danger h1 rounded px-3 " : " text-success h1 rounded px-3"}
                      value=  { this.state.transfer.amount }
                      prefix="₦ "
                      displayType={"text"}
                      thousandSeparator={","}
                    />
                  
                    
                    </span>
                    <span className={ this.state.transfer.status === "failed" ? " bg-danger h1 rounded px-3 text-white" : " bg-success h1 rounded px-3 text-white"} >{this.state.transfer.status }</span>
                    
                  </p>
                </div>

                <div className="mx-5 mw-50 mb-3">

                <table >
                  <tbody >

                    <tr>
                      <td className="px-2">Account Name</td>
                      <td className="px-2">{this.state.transfer.account_name}</td>
                    </tr>
                    <tr>
                      <td className="px-2">Account Number</td>
                      <td className="px-2">{this.state.transfer.account_number}</td>
                    </tr>
                    <tr>
                      <td className="px-2">Bank</td>
                      <td className="px-2">{  this.state.transfer.bank }</td>

                    </tr>
                    <tr>
                      <td className="px-2">Bank Code</td>
                      <td className="px-2">{  this.state.transfer.bank_code}</td>
                    </tr>
                    <tr>
                      <td className="px-2">Narration</td>
                      <td className="px-2">{  this.state.transfer.narration }</td>
                    </tr>
                    <tr>
                      <td className="px-2">Currency</td>
                      <td className="px-2">{  this.state.transfer.currency }</td>
                    </tr>
                    <tr>
                      <td className="px-2">Session ID</td>
                      <td className="px-2">{  this.state.transfer.session_id }</td>
                    </tr>


                    
                    <tr>
                      <td className="px-2">Fee</td>
                      <td className="px-2">₦{  this.state.transfer.fee}</td>
                    </tr>
                    <tr>
                      <td className="px-2">System Reference</td>
                      <td className="px-2">{  this.state.transfer.currency }</td>
                    </tr>
                    <tr >
                      <td className="px-2">Merchant Reference</td>
                      <td className="px-2">{  this.state.transfer.trx_ref}</td>
                    </tr>
                    <tr >
                      <td className="px-2">Transaction Date</td>
                      <td className="px-2">{  this.state.transfer.trx_ref && moment(this.state.transfer.trx_ref.substring(0,8)).format("yy MMM Do")}</td>
                    </tr>
                  </tbody>

                </table>

                </div>
              </div>

            </div>
           ): (
            ""
          )}
        
        </div>
      </div>
    );
  }
}

export default Transaction;
