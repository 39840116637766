import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";
import * as ConstantVar from "../../constants_var.js";
import { CSVLink,  } from "react-csv";


class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      transactions: [],

      // disabling other searchs
      searchTransaction: false,
      transactionDetails: {},
      // search state
      sType: "all",
      search_text: "",
      sStartDate: "",
      sEndDate: "",

      clearance: localStorage.getItem("supportClearance"),
      supportPin: localStorage.getItem("supportPin"),

      // refund user state
      trxId: "",
      trxEmail: "",
      trxFullname: "",
      trxAmount: 0,
      pin: "",

      //pagination state
      perPage: 50,
      currentPage: 0,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  getTransactions = () => {
    //bind this to self
    let self = this

    this.setState({
      isLoading: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/support/transactions", {
        token: localStorage.getItem("supportToken"),
        current_page: self.state.currentPage,
        per_page: self.state.perPage,
        type: self.state.sType,
        search_text: self.state.search_text,
        start_date: self.state.sStartDate,
        end_date: self.state.sEndDate,
      })
      .then((response) => {

        if (response.data.success === "true") {
          self.setState({
            transactions: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!self.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / self.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
    });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getTransactions();
    });
  };

  openTransactionSearchDialog = () => {
    window.$("#trxSearchModal").modal("show");
  };

  handleSearch = (evt) => {
    evt.preventDefault();

    let type = evt.target.type.value,
      search_text = evt.target.search_text.value;
    if (type == "transactionID") {
      axios
        .post(ConstantVar.API_BASE_URL + "/support/transactions_id", {
          token: localStorage.getItem("supportToken"),
          search_text,
        })
        .then((res) => {
          if (res.data.success == "true") {
            this.setState({
              searchTransaction: false,
              search_text: "",
              transactionDetails: res.data.data,
              showDetails: true,
            });
            // window.$("#trxSearchModal").modal("hide");
            return;
          } else {
            this.setState({
              searchTransaction: false,
              transactionError: res.data.message,
            });
            window.confirm(res.data.message);
          }

          return;
        });
    } else {
      // window.$("#trxSearchModal").modal("hide");
      let start_date = evt.target.start_date.value,
        end_date = evt.target.end_date.value;

      // if start date or end date is not set, set current date
      let current_date = moment(new Date()).format("YYYY-MM-DD");
      let next_date = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
      if (!end_date) {
        end_date = next_date;
      }
      if (!start_date) {
        start_date = current_date;
      }

      this.setState(
        {
          sType: type,
          search_text: search_text,
          sStartDate: start_date,
          sEndDate: end_date,
          currentPage: 1,
          paginationLoaded: false,
        },
        () => {
          this.getTransactions();
        }
      );
    }
  };

  resetForm = () => {
    let current_date = moment(new Date()).format("YYYY-MM-DD");
    let next_date = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
    this.setState(
      {
        sType: "all",
        search_text: "",
        sStartDate: current_date,
        sEndDate: next_date,
        currentPage: 1,
        paginationLoaded: false,
      },
      () => {
        this.getTransactions();
      }
    );
  };

  handleChange = (evt) => {
    let value = evt.target.value;
    this.setState({ searchTransaction: false });
    if (value === "transactionID") {
      this.setState({ searchTransaction: true });
      return;
    }
    
    if ( evt.target.name === "perPage") {
      this.setState({ perPage: value });
      return;
    }

    this.setState({ sType: evt });
  };

  handleSearchTextInput = (evt) => {
    this.setState({ search_text: evt.target.value });
  };

  handleStartDateInput = (evt) => {
    this.setState({ sStartDate: evt.target.value });
  };

  handleEndDateInput = (evt) => {
    this.setState({ sEndDate: evt.target.value });
  };

  handlePinInput = (evt) => {
    this.setState({ pin: evt.target.value });
  };

  openRefundDialog = (trx) => {
    this.setState({
      trxId: trx.id,
      trxEmail: trx.email,
      trxFullname: trx.fname + " " + trx.lname,
      trxAmount: trx.amount,
    });
    window.$("#refundTrxModal").modal("show");
  };

  handleRefund = () => {
    window.$("#refundTrxModal").modal("hide");
    // check if the support pin is equal to provided pin
    if (this.state.pin !== this.state.supportPin) {
      alert("Pin is invalid");
      return false;
    } else {
      // refund the user
      axios
        .post(ConstantVar.API_BASE_URL + "/support/refund", {
          token: localStorage.getItem("supportToken"),
          id: this.state.trxId,
          email: this.state.trxEmail,
        })
        .then((response) => {
          if (response.data.success === "true") {
            alert("Refund successful");
            return false;
          } else {
            alert("Refund failed");
          }
        });
    }
  };

  componentDidMount() {
    let current_date = moment(new Date()).format("YYYY-MM-DD");
    let next_date = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
    this.setState({ sStartDate: current_date, sEndDate: next_date }, () => {
      this.getTransactions();
    });
  }

  getFileName = () => {
    let from = this.state.startDate
      ? `_${this.state.startDate}_${this.state.endDate}`
      : `_${moment().format("YYYY-MM-DD")}`;
    return `Transactions${from}.csv`;
  };

  render() {

    var paginationElement;
    let skipForRefundList = ["transfer", "repay", ""];

    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar name="trx" func={this.openTransactionSearchDialog} />
          <div className="header bg-gradient-transactions pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>

          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  {this.state.showDetails ? (
                    <div>
                      <h3 className="text-center bg-primary">Details</h3>

                      <button
                        type="button"
                        className="close pr-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.setState({ showDetails: false })}
                      >
                        <span  className="text-danger" aria-hidden="true">&times;</span>
                      </button>
                      <div className="p-2">
                        <div className="w-40 container-fluid ">
                            <p>
                              Date :{" "}
                              <strong>
                                {moment(
                                  this.state.transactionDetails.created_at
                                ).subtract("1", "h").format("MMM Do, h:mm:ss a")}
                              </strong>
                            </p>
                            <p>
                              Email :{" "}
                              <strong>
                                {this.state.transactionDetails.email}
                              </strong>
                            </p>
                            <p>
                              Amount :{" "}
                              <strong>
                                {this.state.transactionDetails.amount}
                              </strong>
                            </p>
                            <p>
                              Status :{" "}
                              <strong>
                                {this.state.transactionDetails.status === 2
                                  ? "FAILED"
                                  : this.state.transactionDetails.status === 3
                                  ? "PENDING"
                                  : this.state.transactionDetails.status === 4
                                  ? "SUCCESSFUL"
                                  : null}
                              </strong>
                            </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="card-header border-0 d-flex align-items-center">
                        <h2 className="mb-0">
                          Transactions
                          {/* <div> */}

                            {/* Show:{" "} */}
                            <input
                              className="m-2"
                              value={this.state.perPage}
                              onChange={this.handleChange}
                              name="perPage"
                              // width="10%"
                              style={{width:"10%",height:"10%"}}
                              onKeyUp={this.getTransactions}
                            />
                          {/* </div> */}
                          <div className="form-inline float-right">
                            <button
                              className="btn btn-sm"
                              onClick={this.resetForm}
                            >
                              Reset
                            </button>
                          </div>
                        </h2>
                      </div>
                      <hr className="dash-hr" />
                      {(() => {
                        if (this.state.isLoading)
                          return (
                            <div
                              style={{ padding: 50 }}
                              className="text-center"
                            >
                              <div>
                                <p>Loading...</p>
                              </div>
                            </div>
                          );
                        else
                          return this.state.transactions.length ? (
                            <div>
                              <div className="pl-2 ml-5">
                                {paginationElement}
                              </div>
                              <div className="table-responsive">
                                <table className="table align-items-center table-flush">
                                  <tbody>
                                    {this.state.transactions.map(
                                      (trx, index) => {
                                        return (
                                          <tr key={index}>
                                            <th
                                              scope="row dash-td"
                                              style={{ width: "1%" }}
                                            >
                                              <div className="media align-items-center">
                                                {trx.slug ===
                                                "wallet_deposit" ? (
                                                  <div className="wallet-bckg">
                                                    <i className="fas fa-wallet wallet-color" />
                                                  </div>
                                                ) : trx.slug ===
                                                  "airtime_recharge" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-phone phone-color" />
                                                  </div>
                                                ) : trx.slug === "pay_cable" ? (
                                                  <div className="tv-bckg">
                                                    <i className="fas fa-tv tv-color" />
                                                  </div>
                                                ) : trx.slug ===
                                                  "tv_subscription" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-phone phone-color" />
                                                  </div>
                                                ) : trx.slug ===
                                                  "pay_electric" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-bolt phone-color" />
                                                  </div>
                                                ) : trx.slug === "fund_bet" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-money-bill-alt phone-color" />
                                                  </div>
                                                ) : trx.slug === "transfer" ? (
                                                  <div className="tv-bckg">
                                                    <i className="fas fa-paper-plane tv-color" />
                                                  </div>
                                                ) : trx.slug === "repay" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-coins phone-color" />
                                                  </div>
                                                ) : trx.slug ===
                                                  "akawo_credit" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-wallet phone-color" />
                                                  </div>
                                                ) : trx.slug === "invest" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-wallet phone-color" />
                                                  </div>
                                                ) : trx.slug === "loan" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-money-bill-alt phone-color" />
                                                  </div>
                                                ) : trx.slug === "refund" ? (
                                                  <div className="phone-bckg">
                                                    <i className="fas fa-undo phone-color" />
                                                  </div>
                                                ) : (
                                                  <p>Hometown</p>
                                                )}
                                              </div>
                                            </th>
                                            <td
                                              className="dash-td"
                                              style={{ width: "9%" }}
                                            >
                                              <h4 className="text-muted trans-date">
                                                {moment(trx.created_at).format(
                                                  "MMM Do, h:mm:ss a"
                                                )}
                                              </h4>
                                            </td>
                                            <td className="dash-td">
                                              <span className="badge-dot mr-4">
                                                <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                                  {trx.type}
                                                </p>
                                                <p className="table-dash-details2 mb-0">
                                                  <small>
                                                    {trx.description}{" "}
                                                  </small>
                                                </p>
                                              </span>
                                            </td>
                                            <td className="dash-td">
                                              <p className="margin-user-text">
                                                {trx.fname} {trx.lname}
                                              </p>
                                              <small>
                                                <a
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  href={"/user/" + trx.email}
                                                >
                                                  {trx.email}
                                                </a>
                                              </small>
                                            </td>
                                            <td className="dash-td">
                                              {trx.direction === "credit" ? (
                                                <span className="float-right trans-credit-color font-weight-normal">
                                                  <NumberFormat
                                                    value={trx.amount}
                                                    className="card-bal-your"
                                                    displayType={"text"}
                                                    prefix={"+ NGN "}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                  />
                                                </span>
                                              ) : (
                                                <span className="float-right trans-debit-color font-weight-normal">
                                                  <NumberFormat
                                                    value={trx.amount}
                                                    className="card-bal-your"
                                                    displayType={"text"}
                                                    prefix={"- NGN "}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                  />
                                                </span>
                                              )}
                                            </td>

                                            {trx.direction === "debit" &&
                                            skipForRefundList.indexOf(
                                              trx.slug
                                            ) == -1 &&
                                            trx.refunded_at === null &&
                                            trx.email !==
                                              "hometownrefund1@gmail.com" &&
                                            (this.state.clearance === "admin" ||
                                              this.state.clearance ===
                                                "account") ? (
                                              <td className="dash-td">
                                                <button
                                                  onClick={() =>
                                                    this.openRefundDialog(trx)
                                                  }
                                                  className="btn btn-success text-white"
                                                >
                                                  Refund
                                                </button>
                                              </td>
                                            ) : null}
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className="p-5 ml-5">
                                {paginationElement}
                              </div>
                            </div>
                          ) : (
                            <h4 className="p-3 text-center">
                              No transactions found
                            </h4>
                          );
                      })()}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="trxSearchModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="trxSearchModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="trxSearchModalLabel">
                  Search Transactions
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.handleSearch} autoComplete="off">
                <div className="modal-body">
                  <div className="form-group">
                    <select
                      className="form-control form-control-sm"
                      name="type"
                      onChange={this.handleChange}
                    >
                      <option value="all">All Transactions</option>
                      <option value="airtime">Airtime Recharge</option>
                      <option value="grantedLoans">Loan Granted</option>
                      <option value="transfer">Transfer</option>
                      <option value="walletDeposit">Wallet Deposit</option>
                      <option value="data_subscription">Data Subscription</option>
                      <option value="waec">WAEC</option>
                      <option value="electricity">Electricity</option>
                      <option value="betting">Betting</option>
                      <option value="cable_subscription">Cable</option>
                      <option value="flight_booking">Flight Bookings</option>
                      <option value="transactionID">Transaction ID</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder={
                        this.state.searchTransaction
                          ? "Enter transactionID"
                          : "FirstName or Email"
                      }
                      name="search_text"
                      value={this.state.search_text}
                      onChange={this.handleSearchTextInput}
                    />
                  </div>
                  {!this.state.searchTransaction ? (
                    <div className="form-group row">
                      <div className="col-6 form-inline">
                        <label className="form-control-label">Start Date</label>
                        <input
                          className="form-control form-control-sm"
                          type="date"
                          name="start_date"
                          value={this.state.sStartDate}
                          onChange={this.handleStartDateInput}
                        />
                      </div>
                      <div className="col-6 form-inline">
                        <label className="form-control-label">End Date</label>
                        <input
                          className="form-control form-control-sm"
                          type="date"
                          name="end_date"
                          value={this.state.sEndDate}
                          onChange={this.handleEndDateInput}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* <div className="modal-footer">
                  <button type="submit" className="btn btn-primary btn-sm">
                    Search
                  </button>
                </div> */}

                <div className="modal-footer d-flex flex-column justify-content-center align-items-center">
                  
                  <CSVLink
                      data={this.state.transactions}
                      className="btn btn-warning btn-sm my-1"
                      filename={this.getFileName()}
                      download={true}
                    >
                      Download report
                    </CSVLink>
                    <button type="submit"   className="btn my-1 btn-primary btn-sm">
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="refundTrxModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="refundTrxModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="refundTrxModalLabel">
                  Confirm Refund
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label>
                  Are you sure you want to refund {this.state.trxFullname} with{" "}
                  {this.state.trxAmount}
                </label>
                <div className="form-group">
                  <input
                    className="form-control form-control-sm"
                    type="password"
                    placeholder="PIN"
                    name="pin"
                    value={this.state.pin}
                    onChange={this.handlePinInput}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.handleRefund}
                  className="btn btn-primary btn-sm"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Transactions;
