import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import Switch from "react-switch";
import NumberFormat from "react-number-format";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAccess: false,
      transferAccess: false,
      currentPlatform: false,
      maxLoanPerDay: null,
      isLoading: false,
      bank_name: "",
      option: "All",
      banks: [],
      bankNames:[]
    };
    this.handleLoanAccessChange = this.handleLoanAccessChange.bind(this);
    this.handleTransferAccess = this.handleTransferAccess.bind(this);
    this.handlePlatformSwitch = this.handlePlatformSwitch.bind(this);
    this.handleBankNameChange = this.handleBankNameChange.bind(this);
    this.getBanks = this.getBanks.bind(this);
    this.setAmount = this.setAmount.bind(this);
  }

  handleLoanAccessChange(checked) {
    this.setState({ loanAccess: checked });
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };



  handleTransferAccess(checked) {
    this.setState({ transferAccess: checked });
  }

  setAmount(e) {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({
      maxLoanPerDay: amount,
    });
  }

  componentDidMount() {
    this.getLoanAccess();
    this.getBanks();
  }

  getLoanAccess = () => {
    axios
      .post(ConstantVar.API_BASE_URL + "/support/settings_data", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        let data = response.data.data;
        this.setState({
          loanAccess: data.loan_access_status,
          maxLoanPerDay: data.max_loan_per_day,
          transferAccess: data.suspend_transfer == "true" ? true : false,
        });
      });
  };


  handleBankNameChange(e) {
    // this.setState({ bank_name: e.target.value });
  
    // let target = e.target
    //   let name = target.name
    //   //here
    //   let value = Array.from(target.bankNames, option => option.value);
    //   this.setState({
    //     // "bank_names": value
    //     [name]: value
    //   });

    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    this.setState({
      bankNames: value
    });

  
  }

  handleBankSwitch = (option) => {
    this.setState({bankNames:[], option,}, () => {
      this.getBanks(option);
    });
  };

  getBanks = (option) => {
    // getBanks = (option) => {
    axios
      .post(ConstantVar.API_BASE_URL + "/support/list_banks", {
        token: localStorage.getItem("supportToken"),
        // option:option == undefined ? this.state.option:option,
        option: option == (undefined || "") ? this.state.option:option
        })
      .then((response) => {
        let data = response.data;
        this.setState({
          banks: data.banks,
          totalBanks: data.rows,
        });
      });
  };

  updateBankStatus = () => {
    let bank_names = this.state.bankNames;

    bank_names == ("" || undefined) && window.alert("Please select a bank");

    let acceptUpdate = window.confirm(
      `Are you sure you want to update this bank status?`
    );

    let option = this.state.option == "enabled" ? "disabled" : "enabled";
    if (!acceptUpdate) return;

    axios
      .post(ConstantVar.API_BASE_URL + "/support/update_bank_status", {
        token: localStorage.getItem("supportToken"),
        option,
        bank_names,
      })
      .then((res) => {
        let data = res.data;
        if (data.status == "true") {
            window.alert(data.message);
          
        } else {
          window.alert(data.message);
        }
      })
      .then( ()=> this.handleBankSwitch(option == "enabled"? "disabled":"enabled")
      )
  };

  saveLoanSettings = () => {
    this.setState({
      isLoading: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/save_loan_settings", {
        token: localStorage.getItem("supportToken"),
        loan_access_status: this.state.loanAccess,
        max_loan_per_day: this.state.maxLoanPerDay,
      })
      .then((response) => {
        let message = response.data.message;
        alert(message);
        this.setState({
          isLoading: false,
        });
      });
  };

  handleTransferSettings = () => {
    this.setState({
      isLoading: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/save_transfer_settings", {
        token: localStorage.getItem("supportToken"),
        suspend_transfer: this.state.transferAccess,
      })
      .then((response) => {
        let message = response.data.message;
        alert(message);
        this.setState({
          isLoading: false,
        });
      });
  };

  handlePlatformSwitch = (checked) => {
    this.setState({
      currentPlatform:checked
    })
    
    // axios
    //   .post(ConstantVar.API_BASE_URL + "/support/save_transfer_settings", {
    //     token: localStorage.getItem("supportToken"),
    //     suspend_transfer: this.state.transferAccess,
    //   })
    //   .then((response) => {
    //     let message = response.data.message;
    //     alert(message);
    //     this.setState({
    //       isLoading: false,
    //     });
    //   });
  };

  render() {
   
    let option = this.state.currentPlatform ? "raven":"monnify" 
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Settings</h2>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-7">
                        <label>GRANT LOAN ACCESS</label>
                      </div>

                      <div className="col-md-5">
                        <label htmlFor="normal-switch">
                          {" "}
                          <Switch
                            onChange={this.handleLoanAccessChange}
                            checked={this.state.loanAccess}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            id="normal-switch"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7">
                        <label>MAXIMUM LOAN PER DAY</label>
                      </div>

                      <div className="col-md-5">
                        <div className="form-group w-50">
                          <NumberFormat
                            allowNegative={false}
                            placeholder="Maximum Loan"
                            value={this.state.maxLoanPerDay}
                            onChange={this.setAmount}
                            thousandSeparator={true}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7"></div>
                      <div className="col-md-5">
                        <button
                          disabled={this.state.isLoading}
                          className="btn btn-primary"
                          onClick={this.saveLoanSettings}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* disable transfer */}
                  <hr />
                  <div className="my-2 d-flex align-items-center justify-content-center">
                    <div className="col-md-7">
                      <label>BLOCK TRANSFER ACCESS</label>
                    </div>

                    <div className="col-md-2">
                      <label htmlFor="normal-switch">
                        {" "}
                        <Switch
                          onChange={this.handleTransferAccess}
                          checked={this.state.transferAccess}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          id="normal-switch"
                        />
                      </label>
                    </div>

                    <div className="col-md-3">
                      <button
                        disabled={this.state.isLoading}
                        className={`btn ${
                          this.state.transferAccess
                            ? "btn-warning"
                            : "btn-primary"
                        }`}
                        onClick={this.handleTransferSettings}
                      >
                        {this.state.transferAccess ? "Suspend" : "Restore"}
                      </button>
                    </div>
                  </div>
                 
                    {/* Banks section */}
                    <hr />

                  <div className="my-4 ">
                  <label>BANKS SWITCH </label>

                    <div className="p-3 bg-light">
                      <div className="row p-2">
                        
                        <h4 className="px-2" onClick={() =>this.handleBankSwitch("All")}>All  {this.state.option == "All" && `(${this.state.totalBanks} Banks)`}</h4>
                        <h4
                          className="px-2"
                          onClick={() => this.handleBankSwitch("enabled")}
                        >
                          Enabled {this.state.option == "enabled" && `(${this.state.totalBanks} Banks)`}
                        </h4>
                        <h4
                          className="px-2"
                          onClick={() => this.handleBankSwitch("disabled")}
                        >
                          Disabled  {this.state.option == "disabled" && `(${this.state.totalBanks} Banks)`}
                        </h4>
                      </div>
                      <div className="col-md-12">
                        <label  htmlFor="bankNames" className="text-dark" >Select atleast one bank</label>
                        <div className="d-flex align-items-center">
                          <select
                            name="bankNames"
                            className="form-control form-control-sm col-md-6"
                            value={this.state.bankNames}
                            onChange={this.handleBankNameChange}
                            multiple={true}
                            size={"15"}

                          >
                            {this.state.banks.map((item, index) => (
                              <option key={index} value={item.bank_name}>
                                {item.bank_name.toUpperCase()}
                              </option>
                            ))}
                          </select>
                          <div className="col-md-6">
                              {
                                this.state.bankNames.length > 0 &&
                                this.state.bankNames.map((item, index) => 
                                <div>
                                  <small className="text-dark">{item.toUpperCase()}</small>
                                </div>
                                )
                              }
                            {/* {this.state.option !== "All" && (
                              <button
                                className="btn btn-primary m-2 col-md-3"
                                disabled={this.state.bankNames.length === 0}
                                onClick={(e) => {
                                  this.updateBankStatus(e);
                                }}
                              >
                                {this.state.option == "enabled"
                                  ? "Disable"
                                  : "Enable"}
                              </button>
                            )} */}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center pt-2">
                        {this.state.option !== "All" && (
                          <button
                            className="btn btn-primary col-md-3"
                            disabled={this.state.bankNames.length === 0}
                            onClick={(e) => {
                              this.updateBankStatus(e);
                            }}
                          >
                            {this.state.option == "enabled"
                              ? "Disable"
                              : "Enable"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
