import React, { Component, useReducer } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class LoanApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      isLoading: true,
      isSubmitting: false,
      beneficiary: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //bind this to self
    this.loadLoanApplications();
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  loadLoanApplications = () => {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/loans_application", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            loans: response.data.data,
            isLoading: false,
          });
        }
      });
  };
  

  handlePinInput = (evt) => {
    this.setState({ pin: evt.target.value });
  };

  handleApprove = () => {
    this.setState({
      isSubmitting: true,
    });
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/grant_loan", {
        token: localStorage.getItem("supportToken"),
        email: this.state.beneficiary.email,
        pin: this.state.pin,
        reference: this.state.beneficiary.reference,
      })
      .then((response) => {
        alert(response.data.message);
        self.setState({
          isSubmitting: false,
        });
        if (response.data.success === "true") {
          window.$("#confirmModal").modal("hide");
          this.loadLoanApplications();
        }
      });
  };

  discardLoan = (loan) => {
    let confirm = window.confirm("Are you sure you want to discard this loan.");
    if (confirm) {
      this.setState({
        isSubmitting: true,
      });
      let self = this;
      axios
        .post(ConstantVar.API_BASE_URL + "/support/discard_loan", {
          token: localStorage.getItem("supportToken"),
          reference: loan.reference,
        })
        .then((response) => {
          alert(response.data.message);
          self.setState({
            isSubmitting: false,
          });
          if (response.data.success === "true") {
            this.loadLoanApplications();
          }
        });
    }
  };

  discardLoanByDateRange = () => {
    let confirm = window.confirm("Are you sure you want to discard this loan.");
    if (confirm) {
      this.setState({
        isSubmitting: true,
      });
      let self = this;
      axios
        .post(
          ConstantVar.API_BASE_URL + "/support/discard_loan_by_date_range",
          {
            token: localStorage.getItem("supportToken"),
            start_date: this.state.start_date,
            end_date: this.state.end_date,
          }
        )
        .then((response) => {
          alert(response.data.message);
          self.setState({
            isSubmitting: false,
          });
          if (response.data.success === "true") {
            this.loadLoanApplications();
          }
        });
    }
  };

  render() {
    return (
      <div>
        <hr className="dash-hr" />

        <div className="btn-group float-right mr-4 filter-akawo mt-3 mb-2">
          <button
            type="button"
            className="btn btn-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            Discard By Date Range{" "}
          </button>
          <form className="dropdown-menu px-4 py-3">
            <div className="form-group">
              <label>Start Date</label>
              <input
                type="date"
                name="start_date"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.start_date}
              />
            </div>
            <div className="form-group">
              <label>End date </label>
              <input
                type="date"
                className="form-control"
                name="end_date"
                onChange={this.handleChange}
                value={this.state.end_date}
              />
            </div>
            <button
              type="button"
              className="btn btn-warning"
              onClick={this.discardLoanByDateRange}
            >
              Discard
            </button>
          </form>
        </div>

        {(() => {
          if (this.state.isLoading)
            return (
              <div style={{ padding: 50 }} className="text-center">
                <div>
                  <p>Loading...</p>
                </div>
              </div>
            );
          else
            return (
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <tbody>
                    {this.state.loans.length > 0 ? (
                      <>
                        {this.state.loans.map((loan, index) => 
                          <tr key={index}>
                            <td scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                <div className="phone-bckg">
                                  <i className="fas fa-user-alt phone-color" />
                                </div>
                              </div>
                            </td>
                            <td className="dash-td" style={{ width: "9%" }}>
                              <h5 className="text-muted trans-date">
                                {moment(loan.created_at).format(
                                  "MMM Do, h:mm A"
                                )}
                              </h5>
                            </td>

                            <td className="dash-td">
                              <p className="margin-user-text">
                                {loan.fname} {loan.lname}
                              </p>
                            </td>

                            <td className="dash-td">
                              <p className="margin-user-text">
                                <a target="_blank" href={"/user/" + loan.email}>
                                  {loan.email}
                                </a>
                              </p>
                            </td>
                            <td className="dash-td">
                                <a  target="_blank" href={"/followup_schedules/" + loan.email}>
                                {/* <i className="fas fa-envelope" />{" "} */}
                                <i class="fas fa-question-circle"></i>
                                </a>
                            </td> 
                            <td className="dash-td">
                              <h3 className=" mr-4 text-dark">
                                <NumberFormat
                                  value={loan.requested_amount}
                                  displayType={"text"}
                                  decimalScale={0}
                                  prefix="NGN "
                                  thousandSeparator={true}
                                />
                              </h3>
                            </td>

                            <td className="dash-td">
                              <button
                                className="btn btn-warning btn-sm"
                                disabled={this.state.isSubmitting}
                                onClick={() => {
                                  this.discardLoan(loan);
                                }}
                              >
                                Discard
                              </button>
                              <button
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                  this.setState({
                                    beneficiary: loan,
                                  });
                                  window.$("#confirmModal").modal("show");
                                }}
                              >
                                Approve
                              </button>
                            </td>
                          </tr> 
                        )}
                        <tr>
                          <td>
                            <h2>

                            Total
                            </h2>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <h2>
                                <NumberFormat
                                  value={this.state.loans.map(i =>i.requested_amount).reduce( (a, b) => a + b, 0)}
                                  displayType={"text"}
                                  decimalScale={0}
                                  prefix="NGN "
                                  thousandSeparator={true}
                                />
                            </h2>
                          </td>
                        </tr>
                      
                        </>
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No loan application!
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            );
        })()}

        <div
          className="modal fade"
          id="confirmModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="confirmModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="rconfirmModalLabel">
                  Confirm Operation
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label>
                  Are you sure you want to grant loans to?
                  <b>
                    {this.state.beneficiary.fname}{" "}
                    {this.state.beneficiary.lname}
                  </b>{" "}
                </label>

                <div className="form-group">
                  <label>Enter PIN</label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="PIN"
                    name="pin"
                    value={this.state.pin}
                    onChange={this.handlePinInput}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.handleApprove}
                  disabled={this.state.isSubmitting}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoanApplication;
