import React, { Component } from 'react'
import axios from "axios";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";

export default class AgentSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agents: [],
            agent: "",
            tickets: [],
            open:[],
            closed:[],
        archived:[],
        
        start_date:null,
        end_date:null,
        disabled :true

        };
    }
  
    componentDidMount() {
        // window.scrollTo(0, 0);
        this.getAgents()
    }


      getAgents = () => {
        // let self = this;
        axios
          .post(ConstantVar.API_BASE_URL + "/support/staff", {
            token: localStorage.getItem("supportToken"),
          })
          .then((response) => {
            if (response.data.success === "true") {
              let  agents = response.data.data,
              agentList = []

              for (let i =0; i< agents.length; i++){
                let agent = agents[i]
                if (agent.clearance !== "admin"){
                    agentList.push(agent)
                }
               

              }
              this.setState({
                agents:agentList,
                isLoading: false,
              });
            }
          });
      }

      getAgentsTickets = () => {
        let start_date = this.state.start_date,
        end_date =this.state.end_date,
        open=[],
        closed=[],
        archived=[];
        // this.setState({
            // disabled:true
        // })
        // if start date or end date is not set, set current date
        let current_date = moment(new Date()).format("YYYY-MM-DD");
        let next_date = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
        if (!end_date) {
            end_date = next_date;
        }
        if (!start_date) {
            start_date = current_date;
        }
        axios
          .post(ConstantVar.API_BASE_URL + "/support/agent_tickets", {
            token: localStorage.getItem("supportToken"),
            email:this.state.agent,
            start_date,
            end_date
          })
          .then((response) => {
              let agentTickets = response.data.agent_tickets;
            if (agentTickets != undefined ){
                for( let i=0; i < agentTickets.length; i++ ){
                    const ticket = agentTickets[i];
                    console.log("ticket.is_opened ",typeof ticket.is_opened,ticket.is_opened)

                    if (ticket.is_opened == 1){
                        open.push(ticket)
                    }else if(ticket.closed_by == this.state.agent){
                        closed.push(ticket)
                    }else if(ticket.archived == 1){
                        archived.push(ticket)
                    }
                    
                }

                this.setState({
                tickets: agentTickets,
                closed:closed,
                open:open,
                archived:archived,
                disabled:true

                });
            }else{
                this.setState({
                    disabled:true
                    });
                    
                    window.alert(response.data.message)
                    return  
            }
          });
      }

        handleChange = (evt) => {
            this.setState({ 
                [evt.target.name]: evt.target.value ,
            });
        };

        handleAgentChange = (evt) => {
            this.setState({ 
                agent: evt.target.value,
                disabled:false,
                tickets : []
            })
            
        };

    render() {
        return (
            <div>
                <div className="form-group mt-3 ">
                    <label>Agent Ticket Search</label>
                    <br />
                    <div className='row'>
                        <div className='col-7 '>
                            <select
                                required
                                name="agent"
                                className="form-control col-6"
                                onChange={this.handleAgentChange}
                            >
                                <option value="">Select Agent</option>

                                {this.state.agents.length > 0 &&
                                this.state?.agents?.map((agent,key) => 
                                    <option 
                                        value={agent.email} 
                                        key={key}
                                    
                                    >
                                        {agent.email.toUpperCase()}
                                    </option>
                                )
                                }
                            </select>
                            <div className="form-group row col-5">
                                <div className="col-6 form-inline">
                                    <label className="form-control-label">Start Date</label>
                                    <input
                                    className="form-control form-control-sm"
                                    type="date"
                                    name="start_date"
                                      min="2023-04-26"
                                    onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-6 form-inline">
                                    <label className="form-control-label">End Date</label>
                                    <input
                                    className="form-control form-control-sm"
                                    type="date"
                                    name="end_date"
                                    // value={this.state?.sEndDate}
                                    onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <button onClick={this.getAgentsTickets} 
                                    className={this.state.disabled ?'btn btn-gray' :'btn btn-success'}
                                    disabled={this.state.disabled}

                                >
                                    Go
                                </button>
                            </div>
                        </div>
                        {
                            this.state.tickets.length > 0 &&
                            <table className='col-5' >
                                <thead>
                                    <strong>
                                         Tickets Statistics
                                    </strong>

                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            OPEN
                                        </th>
                                        <th>
                                            CLOSED
                                        </th>
                                        <th>
                                            ARCHIVED
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.state.open.length}
                                        </td>
                                        <td>
                                            {this.state.closed.length}
                                        </td>
                                        <td>
                                            {this.state.archived.length}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>

                </div>
            </div>
        )
    }
}
