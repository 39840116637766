import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactPaginate from "react-paginate";

class Transfers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      perPage: 10,
      currentPage: 0,
      isPaginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
      transferList: [],
      loading: false,

      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.getTransfers = this.getTransfers.bind(this);
  }

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
      isPaginationLoaded: false,
    });
  };

  componentDidMount() {
    this.getTransfers();
  }

  getTransfers = () => {
    this.setState({ isLoading: true });
    let token = localStorage.getItem("supportToken");

    axios
      .post(ConstantVar.API_BASE_URL + `/support/p2p_transfers`, {
        token,

        current_page: this.state.currentPage,
        per_page: this.state.perPage,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
      })
      .then((response) => {
        if (response.data.success === "true") {
          this.setState({
            isLoading: false,
            transferList: response.data.data.data,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

         if (!this.state.isPaginationLoaded) {
            let total = response.data.data.pagination.total;
            this.setState(
              {
                pageCount: Math.ceil(total / this.state.perPage),
                currentPage: response.data.data.pagination.currentPage,
                isPaginationLoaded: true,
                totalEntries: total,
              },
              () => {}
            );
          }
        }
        this.setState({
          isLoading: false,
        });
      });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getTransfers();
    });
  };

  render() {
    var paginationElement;

    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div className="container">
        <div className="form-inline mt-3">
          <label>Start Date:</label>
          <input
            type="date"
            className="ml-2 mr-2 form-control"
            name="start_date"
            value={this.state.start_date}
            onChange={this.handleChange}
          />
          <label>End Date:</label>
          <input
            type="date"
            className="ml-2 mr-2 form-control"
            name="end_date"
            value={this.state.end_date}
            onChange={this.handleChange}
          />
          <button
            disabled={this.state.isLoading}
            onClick={this.getTransfers}
            className={"btn btn-success"}
          >
            Go
          </button>
        </div>

        {this.state.transferList.length > 0 ? (
          <div className="card  mx-auto">
            <div className="pl-2 ml-5">{paginationElement}</div>

            {this.state.isLoading ? (
              <div className="card py-5  mx-auto align-items-center">
                <h3> ...Loading </h3>
              </div>
            ) : (
              <table className="table align-items-center table-flush">
                <thead className="bg-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">User</th>
                    <th scope="col">Amount</th>
                    <th scope="col">To Account</th>
                  </tr>
                </thead>
                {this.state.loading ? (
                  <div className="align-this-center">
                    <h4>Loading ... </h4>
                  </div>
                ) : (
                  <tbody>
                    {this.state.transferList?.map((trx, index) => {
                      return (
                        <tr key={index}>
                          <td
                            className=""
                            // style={{ width: "20%" }}
                          >
                            <h4 className="text-muted trans-date">
                              {moment(trx.created_at).format(
                                "MMM Do, h:mm:ss a"
                              )}
                            </h4>
                          </td>

                          <td></td>
                          <td className="">
                            <p className="margin-user-text">
                              {trx.fname} {trx.lname}
                            </p>
                            <small>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={"/user/" + trx.email}
                              >
                                {trx.email}
                              </a>
                            </small>
                          </td>
                          <td className="">
                            <span className="float-right text-warning font-weight-normal">
                              <NumberFormat
                                value={trx.amount}
                                className="card-bal-your"
                                displayType={"text"}
                                prefix={"NGN "}
                                decimalScale={0}
                                thousandSeparator={true}
                              />
                            </span>
                          </td>

                          <td className="">
                            <p className="margin-user-text">{trx.to}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            )}
          </div>
        ) : (
          <div className="card py-5  mx-auto align-items-center">
            <h4>No transactions yet</h4>
          </div>
        )}
      </div>
    );
  }
}

export default Transfers;
