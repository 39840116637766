import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class Akawo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      akawo: [],
      isLoading: true,
      created_at: null,
      plan_type: "all",
      maturity_date: null,
      tab: "",
      status: 1,
      showCompleted: false,
      completed_start: moment().format(),
      completed_end: moment().format(),
    };

    this.filterAkawoBalance = this.filterAkawoBalance.bind(this);
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  filterAkawoBalance = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    let reqData = !this.state.showCompleted
      ? {
          token: localStorage.getItem("supportToken"),
          created_at: this.state.created_at,
          plan_type: this.state.plan_type,
          maturity_date: this.state.maturity_date,
        }
      : {
          token: localStorage.getItem("supportToken"),
          completed_start: this.state.completed_start,
          completed_end: this.state.completed_end,
          plan_type: this.state.plan_type,
          status: this.state.status,
        };

    axios
      .post(ConstantVar.API_BASE_URL + "/support/akawo_filter", reqData)
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            akawo: response.data.filtered_akawo,
            isLoading: false,
          });
        }
      });
  };

  loadAkawo = () => {
    let self = this;

    axios
    .post(ConstantVar.API_BASE_URL + "/support/akawo", {
      token: localStorage.getItem("supportToken"),
    })
    .then((response) => {
      if (response.data.success === "true") {
        self.setState({
          akawo: response.data.data,
          isLoading: false,
        });
      }
    });
  };

  handleTabChange = (evt) => {
      this.setState({ showCompleted: !this.state.showCompleted }, () => {
        if ( !this.state.showCompleted ){
          this.loadAkawo();
        }else{
          this.filterAkawoBalance();
        }
      });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window
      .$(document)
      .on("click", ".filter-akawo .dropdown-menu", function (e) {
        e.stopPropagation();
      });

    //bind this to self
    this.loadAkawo()
    // axios
    //   .post(ConstantVar.API_BASE_URL + "/support/akawo", {
    //     token: localStorage.getItem("supportToken"),
    //   })
    //   .then((response) => {
    //     if (response.data.success === "true") {
    //       self.setState({
    //         akawo: response.data.data,
    //         isLoading: false,
    //       });
    //     }
    //   });
  }

  render() {
    var totalInvestedAmount = 0;
    var totalInvestedReturnsAmount = 0;

    return (
      <div style={{ overflowY: "scroll" }}>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0">All Akawo Balances</h2>

                    <ul className="nav nav-pills  p-3">
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.showCompleted ? "active" : "")
                          }
                          href="#"
                          name="completed"
                          onClick={this.handleTabChange}

                          // () => this.setState({ showCompleted: !this.state.showCompleted })}
                        >
                          {" "}
                          Completed Akawos
                        </a>
                      </li>
                    </ul>
                    <div className="btn-group float-right mr-4 filter-akawo">
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        Filter Akawo{" "}
                      </button>
                      <form className="dropdown-menu px-4 py-3">
                        {this.state.showCompleted ? (
                          <div className="form-group ">
                            <div className="form-group">
                              <label>Completed_start_date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="completed_start"
                                value={this.state.completed_at}
                                onChange={this.handleChange}
                              />
                            </div>

                            <div className="form-group">
                              <label>Completed_end_date</label>

                              <input
                                type="date"
                                className="form-control"
                                name="completed_end"
                                value={this.state.completed_end}
                                min={this.state.completed_start}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="form-group">
                              <label>Plan Type</label>
                              <select
                                className="form-control"
                                name="plan_type"
                                value={this.state.plan_type}
                                onChange={this.handleChange}
                              >
                                <option value="all">All</option>
                                <option value="0">Open Akawo</option>
                                <option value="1">Lock-up Akawo</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Days created (ago)</label>
                              <input
                                type="text"
                                className="form-control"
                                name="created_at"
                                value={this.state.created_at}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label>Days to maturity</label>
                              <input
                                type="text"
                                className="form-control"
                                name="maturity_date"
                                value={this.state.maturity_date}
                                onChange={this.handleChange}
                              />
                            </div>
                          </>
                        )}
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.filterAkawoBalance}
                        >
                          Apply
                        </button>
                      </form>
                    </div>
                  </div>
                  <hr className="dash-hr" />
                  {(() => {
                    if (this.state.isLoading)
                      return (
                        <div style={{ padding: 50 }} className="text-center">
                          <div>
                            <p>Loading...</p>
                          </div>
                        </div>
                      );
                    else
                      return (
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush">
                            <thead>
                              <th></th>
                              <th>Date</th>
                              <th>Account</th>
                              <th>Plan</th>
                              <th>period</th>
                              <th>Maturity</th>
                              <th>Amount</th>
                              <th>Returns</th>
                              <th>Progress</th>
                            </thead>
                            <tbody>
                              {this.state.akawo.length > 0 ? (
                                this.state.akawo.map((akw, index) => {
                                  let startDate = moment(akw.created_at);
                                  let maturityDate = moment(akw.maturity_date);
                                  let today = moment();
                                  let totalInvestmentDays = maturityDate.diff(
                                    startDate,
                                    "days"
                                  );
                                  let noPassedDays = today.diff(
                                    startDate,
                                    "days"
                                  );

                                  totalInvestedAmount += akw.invested_amount;
                                  totalInvestedReturnsAmount +=
                                    akw.total_returns;

                                  return (
                                    <tr>
                                      <th
                                        scope="row dash-td"
                                        style={{ width: "1%" }}
                                      >
                                        <div className="media align-items-center">
                                          <div className="loan-img-bckg">
                                            <i className="fas fa-piggy-bank loan-img-color" />
                                          </div>
                                        </div>
                                      </th>
                                      <td
                                        className="dash-td"
                                        style={{ width: "9%" }}
                                      >
                                        <h6 className="text-muted trans-date">
                                          {moment(akw.created_at).format(
                                            "Y MMM Do, h:mm:ss a"
                                          )}
                                        </h6>
                                      </td>
                                      <td className="">
                                        <a
                                          target="_blank"
                                          href={"/user/" + akw.email}
                                        >
                                          {akw.email}
                                        </a>
                                      </td>
                                      <td className="" style={{ width: "5%" }}>
                                        <p className="">{akw.plan_name}</p>
                                      </td>
                                      <td className="">
                                        <p className="">{akw.period}</p>
                                      </td>
                                      <td className="dash-td">
                                        <h6 className="text-muted trans-date">
                                          {moment(akw.maturity_date).format(
                                            "Y MMM Do, h:mm:ss a"
                                          )}
                                        </h6>
                                      </td>
                                      <td className="dash-td">
                                        <span className="font-weight-normal">
                                          <NumberFormat
                                            value={akw.invested_amount}
                                            className=""
                                            displayType={"text"}
                                            prefix={" NGN "}
                                            decimalScale={0}
                                            thousandSeparator={true}
                                          />
                                        </span>
                                      </td>
                                      <td className="dash-td">
                                        <span className="font-weight-normal">
                                          <NumberFormat
                                            value={akw.total_returns}
                                            className=""
                                            displayType={"text"}
                                            prefix={" NGN "}
                                            decimalScale={0}
                                            thousandSeparator={true}
                                          />
                                        </span>
                                      </td>
                                      <td className="dash-td">
                                        {noPassedDays}
                                        {" /"} {totalInvestmentDays} days
                                        <br />
                                        <progress
                                          value={noPassedDays}
                                          max={totalInvestmentDays}
                                        ></progress>
                                      </td>
                                     
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td>
                                    <div className="text-center no-data full-width-data">
                                      {/*<img className="no-trans-overview" src={transimg} />*/}
                                      <p className="text-muted no-trans-text-o">
                                        No akawo transaction done yet!
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              )}

                              {this.state.akawo.length > 0 ? (
                                <tr>
                                  <td></td>
                                  <td></td> <td></td> <td></td> <td></td>{" "}
                                  <td></td>{" "}
                                  <td>
                                    <span className="font-weight-bold">
                                      <NumberFormat
                                        value={totalInvestedAmount}
                                        className=""
                                        displayType={"text"}
                                        prefix={" NGN "}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                      />
                                    </span>
                                  </td>{" "}
                                  <td>
                                    <span className="font-weight-bold">
                                      <NumberFormat
                                        value={totalInvestedReturnsAmount}
                                        className=""
                                        displayType={"text"}
                                        prefix={" NGN "}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                            </tbody>
                          </table>
                        </div>
                      );
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Akawo;
