import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import NumberFormat from "react-number-format";

import * as ConstantVar from "../../constants_var.js";

import axios from "axios";
import moment from "moment/moment";

export default class Deposits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deposits: [],
      perPage: 20,
      offset: 0,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    this.getUserDeposits();
  }

  getUserDeposits = () => {
    this.setState({isLoading :true})
    let email = this.props.email,
      token = localStorage.getItem("supportToken"),
      per_page = this.state.perPage,
      current_page = this.state.currentPage;

    axios
      .get(
        ConstantVar.API_BASE_URL +
          `/support/user/deposits?email=${email}&token=${token}&per_page=${per_page}&current_page=${current_page}`
      )
      .then((res) => {
        if (res.data.success === "true") {
          this.setState({
            isLoading:false,
            deposits: res.data.data.data,
          });

          if (!this.state.paginationLoaded) {
            let total = res.data.data.pagination.total;
            this.setState({
              pageCount: Math.ceil(total / this.state.perPage),

              currentPage: res.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getUserDeposits();
    });
  };

  render() {
    var paginationElement;

    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div
        className="tab-pane fade show active"
        id="nav-transactions"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0">Deposits</h3>
              </div>
              <div className="pl-2 ml-5 success">{paginationElement}</div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />


              {this.state.isLoading ? (
            <div style={{ padding: 50 }} className="text-center">
              <div>
                <p>Loading...</p>
              </div>
            </div>
          ) :
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead>
                    <tr>
                      <th>..</th>
                      <th>Date</th>
                      <th>Reference</th>
                      <th>Description</th>
                      <th>Source Account</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>

                 
                    {this.state.deposits.length > 0 ? (
                      this.state.deposits.map((deposit, index) => {
                        return (
                          <tr key={index}>
                            <td scope="row dash-td">
                              <div className="media align-items-center">
                                <div className="wallet-bckg">
                                  <i className="fas fa-wallet wallet-color" />
                                </div>
                              </div>
                            </td>
                            <td className="dash-td">
                              <h4 className="text-muted trans-date">
                                {moment(deposit.created_at).format(
                                  "DD-MM-YYYY "
                                )}{" "}
                                <br />
                                {moment(deposit.created_at).format("h:mm:ss a")}
                              </h4>
                            </td>
                            <td className="dash-td">{deposit.reference} </td>
                            <td className="dash-td">{deposit.description} </td>
                            <td className="dash-td">
                              {deposit.source_acount_name}{" "}
                            </td>
                            <td className="dash-td">
                              <span className="float-left trans-credit-color font-weight-normal">
                                <NumberFormat
                                  value={deposit.amount}
                                  className="card-bal-your"
                                  displayType={"text"}
                                  prefix={"NGN "}
                                  decimalScale={0}
                                  thousandSeparator={true}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No deposits done yet!
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
  }
            </div>

            <div className="pl-2 ml-5 success">{paginationElement}</div>
          </div>
        </div>
      </div>
    );
  }
}
