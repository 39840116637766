import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import { CSVLink,  } from "react-csv";


class AllTransactedUsers extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      startDate: null,
      endDate: null,
      total: 0,
      reports: [],
      generate: null,
    };
  }

  componentDidMount() {
    this.getAllTransactedUsers();
  }

  getAllTransactedUsers = () => {
    this.setState({
      isSubmitting: true,
      generate: null,
    });

    axios
      .get(ConstantVar.API_BASE_URL + "/support/reports/stats", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
          type: "all_transacted",
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            total: response.data.data,
            isSubmitting: false,
          });
        }
      })
  };

  getAllTransactedUsersReport = () => {
    this.setState({generate: 0,});

    axios
      .get(ConstantVar.API_BASE_URL + "/support/reports/stats", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
          type: "all_transacted-report",
        },
      })
      .then((response) => {
        if (response.data.status == "true") {
            // remove any null data
          const list = []
          for( let i =0; i < response.data.data.length; i++ ){
            if (response.data.data[i] !== null ){
              list.push(response.data.data[i])
            }
          }
          this.setState({
            generate: 1,
            reports: list,
          });
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };


  getFileName = () => {
    let from = this.state.startDate
      ? `_${this.state.startDate}_${this.state.endDate}`
      : `_${moment().format("YYYY-MM-DD")}`;

    return `All Transacted_users${from}.csv`;
  };

  render() {

    return (
      <div className="">
        <div className="">
          <hr className="dash-hr" />

          <div className="p-5">
            <div className="form-inline">
              <label>Start Date </label>{" "}
              <input
                type="date"
                name="startDate"
                value={this.state.startDate || ""}
                className="form-control ml-2 mr-2"
                onChange={this.handleChange}
              />
              <label>End Date </label>{" "}
              <input
                type="date"
                name="endDate"
                value={this.state.endDate || ""}
                className="form-control ml-2 mr-2"
                onChange={this.handleChange}
              />
              <button className="btn btn-primary" onClick={this.getAllTransactedUsers}>
                Continue
              </button>
            </div>

            {!this.state.isSubmitting ? (
              <div className="table-responsive mt-5 p-5">
                <strong>
                  <address
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    <NumberFormat
                      value={this.state.total}
                      displayType={"text"}
                      thousandSeparator={","}
                      className="h1"
                      suffix=" Users"
                    />
                  </address>
                </strong>

                <p>
                  {this.state.generate === null && this.state.total > 0 ? (
                    <a href="#" onClick={this.getAllTransactedUsersReport}>
                      Generate Report
                    </a>
                  ) : this.state.generate === 0 ? (
                    <div className="p-5 text-center">
                      <span className="p-5 text-bold ">Generating....</span>
                    </div>
                 ) : this.state.generate === 1 ? (
                  <CSVLink
                    data={this.state.reports}
                    className="btn btn-info"
                    filename={this.getFileName()}
                  >
                    Download report
                  </CSVLink>
                  
                ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              <div className="p-5 text-center">
                <h3>Loading....</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AllTransactedUsers;
