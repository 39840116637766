import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './assets/index.css';
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";

import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class WithdrawalsIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withdrawals: [],
      isLoading: true,
      isP2P:false,

      clearance: localStorage.getItem("supportClearance"),
      supportPin: localStorage.getItem("supportPin"),

      // transfer  state
      pin: "",

      email: "",   
      account_number:"",


      //pagination state
      perPage: 50,
      offset: 0,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    this.getWithdrawls();
  }

  getWithdrawls() {
    //bind this to self
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/withdrawals", {
        token: localStorage.getItem("supportToken"),
        type: "issues",
        current_page: this.state.currentPage,
        per_page: this.state.perPage,
        email: this.state.email,  
        account_number: this.state.account_number,

      })
      .then((res) => {

        if (res.data.success === "true") {

          self.setState({
            withdrawals: res.data.data.data,
            isLoading: false,
            _from: res.data.data.pagination.from,
            _to: res.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = res.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: res.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  }

  handlePinInput = (evt) => {
    this.setState({ pin: evt.target.value });
  };

  openTransferResolveDialog = (withdraw) => {
    this.setState({
      withdrawId: withdraw.id,
      withdrawEmail: withdraw.email,
      amount: withdraw.amount,
      account_name: withdraw.account_name,
      account_number: withdraw.account_number,
    });
    window.$("#confirmManualWithdrawModal").modal("show");
  };

  resolveTransfer = () => {
    // check if the support pin is equal to provided pin
    if (this.state.pin !== this.state.supportPin) {
      alert("Pin is invalid");
      return false;
    } else {
      // refund the user
      window.$("#confirmManualWithdrawModal").modal("hide");

      axios
        .post(ConstantVar.API_BASE_URL + "/support/resolve_transfer", {
          token: localStorage.getItem("supportToken"),
          id: this.state.withdrawId,
          email: this.state.withdrawEmail,
        })
        .then((response) => {
          if (response.data.success === "true") {
            alert("Resolved successfully");
            this.getWithdrawls();
            this.setState({
              isLoading: true,
            });
            return false;
          } else {
            alert("Transfer failed");
          }
        });
    }
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getWithdrawls();
    });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  filterWithdraws = () => {
    this.setState(
      {
        withdrawals: [],
        paginationLoaded: false,
        currentPage: 0,
      },
      () => {
        this.getWithdrawls();
      }
    );
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-3">Withdrawals with Issues</h2>
            <form
              autoComplete={false}
              onSubmit={(evt) => {
                evt.preventDefault();
              }}
            >
              <div className=" form-inline float-right">
                <input
                  className="form-control"
                  placeholder="Email Address:"
                  name="email"
                  onChange={this.handleChange}
                  autoComplete={false}
                />
                <button className="btn btn-primary" onClick={this.filterWithdraws}>
                  Filter
                </button>
              </div>
            </form>
          </div>
          <hr className="dash-hr" />
          {(() => {
            if (this.state.isLoading)
              return (
                <div style={{ padding: 50 }} className="text-center">
                  <div>
                    <p>Loading...</p>
                  </div>
                </div>
              );
            else
              return (
                <div className="table-responsive">
                  {this.state.withdrawals.length > 0 ? (
                    <div>
                      <div className="pl-2 ml-5">{paginationElement}</div>
                      <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                          <tbody>
                            {this.state.withdrawals
                              .slice(
                                this.state.offset,
                                this.state.offset + this.state.perPage
                              )
                              .map((withdraw) => (
                                <tr>
                                  <th scope="row dash-td" style={{ width: "1%" }}>
                                    <div className="media align-items-center">
                                      <p className="text-muted trans-date">
                                        {moment(withdraw.created_at).format("MMM Do")}

                                        <br />
                                        {moment(withdraw.created_at).format("hh:mm A")}
                                      </p>
                                    </div>
                                  </th>
                                  <td className="dash-td" style={{ width: "9%" }}>
                                    <div>
                                      <p className="font-weight-bold trans-date">
                                        {withdraw.account_name}
                                        <br />
                                        {withdraw.bank}
                                      </p>
                                      <a target="_blank" href={"/user/" + withdraw.email}>
                                        {withdraw.email}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="dash-td">
                                    <p>{withdraw.account_number}</p>
                                  </td>
                                  <td className="dash-td">
                                    <p>
                                      <NumberFormat
                                        className="text-danger"
                                        value={withdraw.amount}
                                        prefix={"NGN "}
                                        displayType={"text"}
                                        thousandSeparator={","}
                                      />
                                    </p>
                                  </td>
                                  <td className="dash-td">
                                    {withdraw.resolved === "resolved" ? (
                                      <button className="success btn">Resolved</button>
                                    ) : (
                                      <div>
                                        <button
                                          className="btn btn-warning"
                                          onClick={() =>
                                            this.openTransferResolveDialog(withdraw)
                                          }
                                        >
                                          Resolve
                                        </button>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="p-5 ml-5">{paginationElement}</div>
                    </div>
                  ) : (
                    <div className="text-center no-data full-width-data">
                      <p className="text-muted no-trans-text-o">No withdrawal yet!</p>
                    </div>
                  )}
                </div>
              );
          })()}
        </div>

        <div
          class="modal fade"
          id="confirmManualWithdrawModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="confirmManualWithdrawModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="confirmManualWithdrawModalLabel">
                  Confirm Withdrawals
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label>
                  Are you sure you want to confirm the resolve of {this.state.amount} to{" "}
                  {this.state.account_name} ( {this.state.account_number})
                </label>
                <div class="form-group">
                  <input
                    class="form-control form-control-sm"
                    type="password"
                    placeholder="PIN"
                    name="pin"
                    value={this.state.pin}
                    onChange={this.handlePinInput}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  onClick={this.resolveTransfer}
                  class="btn btn-primary btn-sm"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithdrawalsIssues;
