import React, { Component } from 'react';
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import Switch from "react-switch";

export default class IncreaseLoan extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        isLoading: false,
        email:"",
        reqAmt:"",
        duration:"",
        repayAmt:"",
        rate:"",
        dueAt:"",
        token:"",
        loanDetails :false,
        onlyExtendDate:false,
        existingRequestedAmount: "",
        };
    this.handleChange = this.handleChange.bind(this);
    }


    // updateLoan
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    // extendDate
    handleExtendDate = (checked) => {
        this.setState({onlyExtendDate: checked});
    };


    getUserLoanDetails = (e) => {
        e.preventDefault()
        axios
        .get(
        ConstantVar.API_BASE_URL +
        `/support/activities/loan_details/${this.state.email}`
        )
        .then((res) => {
        if(res.data.success === "true"){
            this.setState({
                ...this.state, 
                loanDetails :true,
                existingRequestedAmount :res.data.message.requested_amount,
                reqAmt :res.data.message.requested_amount,
                repayAmt :res.data.message.repayment_amount,
                duration :res.data.message.duration,
                balance :res.data.message.balance,
                amount_paid :res.data.message.amount_paid,
                rate :res.data.message.rate,
                dueAt :res.data.message.due_at?.split("T")[0],
                userEmail :res.data.message.email,
            })
            return
        }
            alert(res.data.message);
        })
        .catch((error) => {
            console.log("ERROR:: ", error)
            alert(error.res.data.message);
        });
    }

    updateUserLoanDetails = (e) => {
        e.preventDefault()
        if (this.state.reqAmt < this.state.existingRequestedAmount){
           return window.alert("Can not decrease requested_amount.")
        }else if (this.state.reqAmt > this.state.repayAmt){
           return window.alert("requested_amount cannot be greater than repayment_amount")
        }
         
       if (this.state.onlyExtendDate){
        if (!window.confirm("Are you sure you wish to only extend Date?(Press ESC key to cancel)"))return
       }
       else {
           if(!window.confirm("Are you sure you wish to update this data ?(Press ESC key to cancel)")){
               return
           }
       }

        axios
        .post(
            ConstantVar.API_BASE_URL +
            `/support/activities/increase_loan`,{
                reqAmt :this.state.reqAmt,
                repayAmt :this.state.repayAmt,
                duration :this.state.duration,
                rate :this.state.rate,
                dueAt :this.state.dueAt,
                userEmail :this.state.email,
                onlyExtendDate :this.state.onlyExtendDate,
                token: localStorage.getItem("supportToken"),
            }
        )
        .then((res) => {
        if(res.data.success === "true"){
            alert(res.data.message)
            this.setState({...this.state, 
                loanDetails :false,onlyExtendDate:false })
        return
        }
        alert(res.data.message);
        })
        .catch((error) => {
        alert(error.res.data.message);
        });
    }

    render() {
        return (
        <div>
            <div className="card shadow">
            {!this.state.loanDetails ? (
                <div className="col-md-9 mt-3">
                <div className="input-group ">
                    <input
                    required
                    name="email"
                    className="form-control"
                    placeholder="User Email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    type="text"
                    />
                </div>
                <div>
                    <button
                    disabled={this.state.isLoading}
                    className="btn btn-success"
                    onClick={this.getUserLoanDetails}
                    >
                    Get User
                    </button>
                </div>
                </div>
            ) : (
                <div className="col-md-12 mt-3">
                <div className="form-inline row p-3">
                    <div className="input-group ">
                        <label>Requested_amount</label>
                        <input
                            required
                            name="reqAmt"
                            className="ml-2 mr-2 form-control"
                            placeholder="Enter request Amount"
                            value={this.state.reqAmt}
                            onChange={this.handleChange}
                            type="text"
                        />
                    </div>

                    <div className="input-group ">
                    <label>Duration (days)</label>
                    <input
                        required
                        name="duration"
                        className="ml-2 mr-2 form-control"
                        placeholder="Enter loan duration"
                        value={this.state.duration}
                        onChange={this.handleChange}
                        type="text"
                    />
                    </div>
                </div>
                <div className="form-inline row p-3">
                    <div className="input-group ">
                        <label>Loan Balance</label>
                        <input
                            required
                            name="duration"
                            className="pl-2 mr-2 form-control"
                            value={this.state.balance}
                            // onChange={this.handleChange}
                            readOnly={true}
                            type="text"
                        />
                    </div>

                    <div className="input-group ">
                    <label>Amount paid</label>
                    <input
                        required
                        name="amount_paid"
                        className="pl-2 mr-2 form-control"
                        value={this.state.amount_paid}

                        readOnly={true}
                        type="text"
                    />
                    </div>
                </div>

                <div className="form-inline row p-3">
                    <div className="input-group ">
                    <label>Repayment_amount</label>
                    <input
                        required
                        name="repayAmt"
                        className="ml-2 mr-2 form-control"
                        placeholder="Enter repayment Amount"
                        value={this.state.repayAmt}
                        onChange={this.handleChange}
                        type="text"
                    />
                    </div>

                    <div className="input-group ">
                    <label>Rate (%)</label>
                    <input
                        required
                        name="rate"
                        className="ml-2 mr-2 form-control"
                        placeholder="Enter repayment rate"
                        value={this.state.rate}
                        onChange={this.handleChange}
                        type="text"
                    />
                    </div>

                    <div className="input-group ">
                    <label>Due At</label>
                    <input
                        required
                        name="dueAt"
                        className="ml-2 mr-2 form-control"
                        placeholder="Enter repayment Amount"
                        value={this.state.dueAt ? this.state.dueAt : new Date()}
                        onChange={this.handleChange}
                        min={ new Date().toISOString().split("T")[0]}
                        type="date"
                    />
                    </div>
                </div>
                
                <div className="col-md-7 row ">
                    <label htmlFor="normal-switch" className='text-danger pr-3'>
                        Extend only date
                        
                    </label>

                    <Switch
                    onChange={this.handleExtendDate}
                    checked={this.state.onlyExtendDate}
                    value={this.state.onlyExtendDate}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    name='onlyExtendDate'
                    id="normal-switch"
                    />
                </div>
                <div>
                    <button
                    disabled={this.state.isLoading}
                    className="btn btn-success"
                    onClick={this.updateUserLoanDetails}
                    >
                    Update User Loan
                    </button>
                </div>
                </div>
            )}
            </div>
        </div>
        );
    }
}