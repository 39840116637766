import React, { Component, useRef } from 'react'
import axios from "axios";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import EmailEditor from 'react-email-editor';


export default class Emails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "messages",
      isSubmitting:false,
      sender:null,
      recipients:[],
      subject:"",
      activity:"",
      designs:[],
      showDesign:false,
      mailList:[],
      designName:"",
      sendersList:[]
    };
  }

  handleChange = (e) => {
    if(e.target.name == "activity"){
      this.setState({ [e.target.name]: e.target.value },
        () => {
          this.getUsers(e)
          }
      );

    }

      this.setState({ [e.target.name]: e.target.value });
  };
  
  listMails = () => {
    axios.get(ConstantVar.API_BASE_URL + "/support/email_messages/")
      .then(res => {
        console.log(res.data,"responseListMail")
        if(res.data.success === "true"){
          this.setState({...this.state, mailList:res.data.message})
        } else{
          alert(res.data.message)
        }
      })
  }

  deleteMail = (mailId) => {
     const foundMail =  this.state?.mailList?.filter(({id}) => id != mailId )
      this.setState({...this.state,mailList:foundMail})
      axios.post(ConstantVar.API_BASE_URL + "/support/email_messages/delete/"+ mailId)
        .then(res => {
        if(res.data.success === "true"){
          // this.setState({...this.state, mailList:res.data.message})/
          this.listMails()
        } else{
          alert(res.data.message)
        }
      })
  }

  // listSenders = () => {
  //   axios.get(ConstantVar.API_BASE_URL + "/support/email_messages/senders")
  //     .then(res => {
  //       // console.log(res,"responseListSenders")
  //       if(res.data.success === "true"){
  //         this.setState({...this.state, sendersList:res.data.message})
  //       } else{
  //         alert(res.data.message)
  //       }
  //     })
  // }

  getSenderDetails = () => {
    axios.post(ConstantVar.API_BASE_URL + "/support/email_messages/senders",{  
      token: localStorage.getItem("supportToken")}
    ).then(res => {
      // console.log(res,"response")
      if(res.data.success === "true"){
        this.setState({sender:res.data.message})
      } else{
        alert(res.data.message)
      }
    })
  }

  componentDidMount(){
    this.getSenderDetails()
    this.listDesign()
    this.listMails()
    // this.listSenders()
  } 

  getReceipientType = (activity) =>{
    switch (activity) {
      case "activeUsers":
        return "active";
      case "transactedUsers":
        return "transacted";
      case "unActivatedUsers":
        return "unactivated";
      default:return ;
    }
  }
  // show/hide form
  activateForm = () => {
    this.setState({messageForm: !this.state.messageForm})
  }

  // geting activeUsers
  getUsers = async(e) => {
    e.persist()
    const res = await axios.get(ConstantVar.API_BASE_URL + "/support/email_messages/" + this.getReceipientType(this.state.activity))
      if(res.data.success === "true"){
        let newList = res.data.message.map(i => i.email)
        this.setState({recipients: newList.toString()})
        // console.log("recipients::", this.state.recipients)
      }
  }

  // saving tEMPLATE
  saveDesign = (e) => {
    e.preventDefault()
    // throww alert if no name is provided
    if(!this.state.designName){
          return alert("Enter a name for this design.")
        }
    this.editor.saveDesign(design => {
      // const {design, html} = data
      // console.log("design", design)
      axios.post(ConstantVar.API_BASE_URL + "/support/email_messages/templates", {
        design,
        designName:this.state.designName
        }).then(res => {
          // console.log("req", res)
        if(res.data.success === "true"){
          alert(res.data.message)
          this.setState({...this.state,designName:""})
          this.listDesign()
          return
        }
        alert(res.data.message)
      })
    });

  }

  showDesignList = (e) => {
    e.preventDefault()
    this.setState({...this.state, showDesign: !this.state.showDesign})
  }

  // list design
  listDesign = () => {
    axios.get(ConstantVar.API_BASE_URL + "/support/email_messages/templates", {
      }).then(res => {
        // console.log("DesignList", res.data)
        if(res.data.success === "true"){
        // console.log("DesignS", res.data.message)
        // let
        this.setState({designs:res.data.message})
          // res.data.message.forEach(i => {JSON.parse(i.design), i.created_at} )
}
    })

  }
  
  // messaging form

  sendMail = (e) => {
     e.preventDefault()

      this.setState({
          isSubmitting: true,
        });
      
      // if(!this.state.recipients || !this.state.subject){
      //   alert("Kindly fill all fields.")

        // this.setState({
        //   isSubmitting: false,
        // });
      // }
       
      this.editor.exportHtml((data) => {
        const { design, html } = data;
        // trigger an alert to add recipietn
        if(!this.state.recipients){
          return alert("Add at least 1 recipient.")
        }
        axios.post(ConstantVar.API_BASE_URL + "/support/email_messages", {
          design:design,
          html:html,
          recipients:this.state.recipients,
          subject:this.state.subject,
          sender:this.state.sender,
        }).then(res => {
          // console.log("req", res)
        this.setState({...this.state,isSubmitting: false}, () => {
          this.listMails()
          this.activateForm()
        });
          if(res.data.success === "true"){
            alert(res.data.message)
            return
          }
          alert(res.data.message)
        })
      })
  };

  handleLoad = (designId) => {
   const foundDesign =  this.state?.designs?.find(({id}) => id == designId )

      // editor instance is created
      let itemJson = {};
      // you can load your template here;
      
      this.setState({...this.state,showDesign: !this.state.showDesign})
      itemJson["counters"] =  JSON.parse(foundDesign?.counters) 
      itemJson["body"] =  JSON.parse(foundDesign?.body) 
      const templateJson = (foundDesign?.design)
      this.editor.loadDesign(itemJson);
  }

  handleDeleteDesign = (designId) => {
    const foundDesign =  this.state?.designs?.filter(({id}) => id != designId )
    // console.log("foundDesign",foundDesign)
    this.setState({...this.state, design:foundDesign,showDesign: !this.state.showDesign}, () =>{
        // e.preventDefault()
      axios.post(ConstantVar.API_BASE_URL + "/support/email_messages/templates/"+designId)
      .then(res =>{
        // console.log("res    :: ", res)
        if(res.data.success === "true"){
          // close list
          this.listDesign()
          this.setState({...this.state,showDesign: !this.state.showDesign})
        }
      })
        // if(res.data.)
    }
      )
  }

  // deleteSender = (senderId) => {

  //   const foundsender =  this.state?.sendersList?.filter(({id}) => id != senderId )
  //   // console.log("foundsender",foundsender)
  //   this.setState({...this.state, sendersList:foundsender}, () =>{
  //       // e.preventDefault()
  //     axios.post(ConstantVar.API_BASE_URL + "/support/email_messages/senders/"+senderId)
  //     .then(res =>{
  //       // console.log("res    :: ", res)
  //       if(res.data.success === "true"){
  //         this.listSenders()
  //       }
  //     })
  //       // if(res.data.)
  //   }
  //     )
  // }

  onReady = () => {
      // editor is ready
      console.log('onReady');
  };



  render() {
    return (
      <div className="card shadow">
        {/* <ul className="nav nav-pills  p-3">
          <li className="nav-item">
            <a
              className={
                "nav-link " +
                (this.state.tab === "messages"
                  ? "active"
                  : "")
              }
              href="#"
              onClick={() =>
                this.setState({ tab: "messages" })
              }
            >
              {" "}
              Messages
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                "nav-link " +
                (this.state.tab === "senders"
                  ? "active"
                  : "")
              }
              href="#"
              onClick={() =>
                this.setState({ tab: "senders" })
              }
            >
              Senders
            </a>
          </li>
        </ul> */}
        <div className="p-5">
           <div className='d-flex p-3 justify-content-between'>
            <h4>Message History</h4>
            <h4 onClick={this.activateForm} className={!this.state.messageForm ? " btn text-success" : "btn text-danger"} >{!this.state.messageForm ? "Add new" :"Close Editor"}</h4>
          </div>
          <div className="table-responsive p-5">
            {this.state.messageForm  ?
            <form className='pl-3'>
               <h2>Email Messaging</h2>
                  <div className="form-group mb-3">
                    <div className="form-group pl-3 input-group-alternative">
                      <p>Subject:</p>{" "}
                      <input
                        required
                        autoComplete="off"
                        onChange={this.handleChange}
                        value={this.state.subject}
                        name="subject"
                        className="form-control input-sm"
                        placeholder="Subject of message"
                        type="text"
                      />
                      </div>
                  <div className="form-group mt-3 row pl-3 " >
                    <label>Recipients Type</label>
                    <select
                      required
                      name="activity"
                      className="form-control  "
                      onChange={this.handleChange}
                    >
                      <option >Select Recipients</option>
                      <option value="activeUsers">Active Users</option>
                      <option value="transactedUsers">Transacted Users</option>
                      <option value="unActivatedUsers">Unactivated Users</option>
                    </select>
                    {/* <div>
                      <button onClick={this.getUsers}  >Get</button>
                    </div> */}
                  </div>
                  
                  <div className="form-group input-group-alternative pl-3">
                    
                    <p>Recipients:</p>{" "}
                    <input
                      required
                      autoComplete="off"
                      name="recipients"
                      value={this.state.recipients}
                      className="form-control input-sm"
                      placeholder="Enter Recipients seperated with comma(,)s"
                      onChange={this.handleChange}
                      type="text"
                    />
                    
                  </div>
                </div>
              <div className='text-right'>
                <button className=' btn btn-primary mr-3' onClick={this.showDesignList}>Designs</button>
              </div>
              <div>
                {this.state.showDesign && 
                <ul>
                  <table  className="mx-auto p-2 mw-50"  >
                    <thead className=' bg-primary mx-auto text-light'>
                      <tr>
                        <td className='pl-3 text-center'>Design Name</td>
                        <td className='pl-3 text-center'>Created At</td>
                        <td/>
                        <td/>
                      </tr>
                    </thead>
                    {this.state.designs.map((i, j) => 
                      <tbody key={i.id}>
                        <tr >
                          {/* <td className='pl-3 btn' onClick={()=>this.handleLoad(i.id)}>{i.id}</td> */}
                          {/* <td className='pl-3' >{moment(i.created_at).format("YYYY-MM-DD HH:mm:ss")}</td> */}
                          {/* <td style={{cursor:'pointer'}} onClick={()=>this.handleDeleteDesign(i.id)} className='pl-3 text-danger'>Remove</td> */}
                          {/* <td className='pl-3' >{i.id}</td> */}
                          <td className='pl-3' >{i.designName}</td>
                          <td className='pl-3' >{moment(i.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                          <td  onClick={()=>this.handleLoad(i.id)} className='btn text-primary'>Use</td>
                          <td  onClick={()=>this.handleDeleteDesign(i.id)} className='btn text-danger'>Remove</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </ul>
                }
              </div>
              <EmailEditor ref={editor => this.editor = editor} onLoad={() =>this.handleLoad} onReady={this.onReady} />
              <div className=' input-group my-5 d-flex  justify-content-between '>
                <button className='btn text-left mr-3  btn-success' 
                  disabled={this.state.isSubmitting} 
                  onClick={this.sendMail}
                >SEND</button>

                {/* <div className='row ml-3 '> */}
                <div className="input-group-prepend text-right" >
                  <input 
                    required
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={this.state.designName}
                    name="designName"
                    className="form-control"
                    placeholder="Enter Design Name"
                    type="text"
                  />
                <div className="input-group-append" >
                  <button className='btn btn-warning' 
                  
                  disabled={this.state.isSubmitting} 
                  onClick={this.saveDesign}
                  >Save</button>
                </div>

                  </div>
                {/* </div> */}
                 
              </div>
              
            </form>
             :
            <table className="table align-items-center mw-25" >
              {this.state.tab === "messages" &&
                <>
                  <thead className=' bg-primary p-2 text-light'>
                    <tr>
                      <th>Date</th>
                      <th>Subject</th>
                      <th>No of Recipients</th>
                      <th>Sender  </th>
                      
                    </tr>
                  </thead>
                  <tbody className= "mw-50" >
                    {this.state.mailList.length ? this.state.mailList?.map(i => 
                    <tr key={i?.id} className= "mw-50" >
                      <td>{moment(i?.created_at).format("DD-MM-YYYY hh:mm:ss")}</td>
                      <td>{i?.subject}</td>
                      <td className= "mw-25">{ (JSON.parse(i?.recipient_option)).length}</td>
                      <td className= "mw-25">{i?.email_sender}</td>
                    </tr>
                    )
                    :
                    <tr className= "mw-50" >
                      <td>No Mails yet</td>
                    </tr>
                    }
                  </tbody>
                </>
              }

              {/* { this.state.tab === "senders" &&
                <>
                  <thead className=' bg-primary p-2 text-light'>
                    <tr> 
                      <th>
                        <i className="fas fa-plus "></i>
                      </th>
                      <th>name</th>
                      <th>date</th>
                      <th>email</th>
                      <th/>
                    </tr>
                
                  </thead>
                
                  <tbody>
        
                    {this.state.sendersList.map(i => 
                      <tr key={i.id}>
                        <td/>
                        <td>{i.fname}{" "}{i.lname} </td>
                        <td>{moment(i.created_at).format("DD-MM-YYYY hh:mm:ss")}</td>
                        <td>{i.email}</td>
                        <td className='text-danger btn' onClick={()=>this.deleteSender(i.id)}>Remove</td>
                      </tr>
                    )}
                  </tbody>
                </>
              } */}
            </table>
            }
          </div>
                      
        </div>    
      </div>
    )
  }
}
