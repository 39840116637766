/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";

import GrantedLoan from "./granted";
import DueLoan from "./due";
import LoanApplication from "./application";
import PastMaturity from "./past_maturity";
import NoRepayment from "./no_repayment";
import LoanRepayment from "./loan_repayment";
import LoanOverview from "./loan_overview";
import ActiveLoans from "./active.js";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "overview",
    };
  }

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>

          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Loans</h2>
                  </div>
                  <ul className="nav nav-pills  p-3">
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "overview" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "overview" })}
                      >
                        {" "}
                        Loan Overview
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "application" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "application" })}
                      >
                        {" "}
                        Loan Applications
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "approved" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "approved" })}
                      >
                        Granted Loans
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "due" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "due" })}
                      >
                        Due Loans
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "active" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "active" })}
                      >
                        Active Loans
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "past_maturity" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "past_maturity" })}
                      >
                        Past Maturity
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "no_repayments" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "no_repayments" })}
                      >
                        No Repayments
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "loan_repayments" ? "active" : "")
                        }
                        href="#"
                        onClick={() =>
                          this.setState({ tab: "loan_repayments" })
                        }
                      >
                        Loan Repayments
                      </a>
                    </li>
                  </ul>

                  <div className="p-3">
                    {this.state.tab === "overview" ? (
                      <LoanOverview />
                    ) : this.state.tab === "application" ? (
                      <LoanApplication />
                    ) : this.state.tab === "approved" ? (
                      <GrantedLoan />
                    ) : this.state.tab === "due" ? (
                      <DueLoan />
                    ) : this.state.tab === "active" ? (
                      <ActiveLoans />
                    ) 
                    : this.state.tab === "past_maturity" ? (
                      <PastMaturity />
                    ) : this.state.tab === "no_repayments" ? (
                      <NoRepayment />
                    ) : this.state.tab === "loan_repayments" ? (
                      <LoanRepayment />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
