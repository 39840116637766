import React, { Component } from 'react'
import BVNSearch from './bvn_lookup';
import BVN_Search from './bvn_search';
import NINLookup from './nin_lookup';

export default class BvnNinLookUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activity: ""

        };
    }

    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    render() {
        return (
            <div>
                <div className="form-group mt-3 ">
                    <label>Activities List</label>
                    <select
                        required
                        name="activity"
                        className="form-control"
                        onChange={this.handleChange}
                    >
                        <option value="">Select either NIN or BVN Look-Up</option>
                        <option value="bvn_lookUp">BVN LookUp</option>
                        <option value="nin_lookUp">NIN LookUp</option>
                        <option value="bvn_search">BVN Search</option>
                    </select>
                </div>
                    {this.state.activity == "bvn_lookUp" && <BVNSearch />}
                    {this.state.activity == "nin_lookUp" && <NINLookup />}
                    {this.state.activity == "bvn_search" && <BVN_Search />}
            </div>
        )
    }
}
