import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './assets/index.css';
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";
import HometownModal from "./modal.js";

class ManualWithdrawals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withdrawals: [],
      isLoading: true,
      isP2P: false,

      start_date: moment(new Date()).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
      status: "all",
      pin: "",
      perPage: 50,
      offset: 0,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
      email: "",
      account_number: "",
      total: 0,
      supportPin: localStorage.getItem("supportPin"),
    };
  }

  getWithdrawals = () => {
    let start_date = this.state.start_date,
      end_date = this.state.end_date;

    // if start date or end date is not set, set current date
    let current_date = moment(new Date()).format("YYYY-MM-DD");
    let next_date = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
    if (!end_date) {
      end_date = next_date;
    }
    if (!start_date) {
      start_date = current_date;
    }

    //bind this to self
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/withdrawals", {
        token: localStorage.getItem("supportToken"),
        type: "manually",
        current_page: this.state.currentPage,
        per_page: this.state.perPage,
        email: this.state.email,
        status: this.state.status,
        provider: this.state.provider,
        account_number: this.state.account_number,
        p2p: this.state.isP2P,
        start_date,
        end_date,
      })
      .then((res) => {
        if (res.data.success === "true") {
          self.setState({
            withdrawals: res.data.data.data,
            isLoading: false,
            _from: res.data.data.pagination.from,
            _to: res.data.data.pagination.to,
            total: res.data.data.data.length,
          });
          if (!this.state.paginationLoaded) {
            let total = res.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: res.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getWithdrawals();
  }

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getWithdrawals();
    });
  };

  filterWithdraws = (e) => {
    e.preventDefault();

    this.setState(
      {
        withdrawals: [],
        paginationLoaded: false,
        currentPage: 0,
      },
      () => {
        this.getWithdrawals();
      }
    );
  };

  handlePinInput = (evt) => {
    this.setState({ pin: evt.target.value });
  };

  openTransferDialog = (withdraw, actionType) => {
    this.setState({
      withdrawId: withdraw.id,
      withdrawEmail: withdraw.email,
      amount: withdraw.amount,
      account_name: withdraw.account_name,
      account_number: withdraw.account_number,
      action_type: actionType,
    });
    window.$("#confirmManualWithdrawModal").modal("show");
  };

  handleTransfer = () => {
    // check if the support pin is equal to provided pin
    if (this.state.pin !== this.state.supportPin) {
      alert("Pin is invalid");
      return false;
    } else {
      // refund the user
      window.$("#confirmManualWithdrawModal").modal("hide");

      axios
        .post(ConstantVar.API_BASE_URL + "/support/transfer", {
          token: localStorage.getItem("supportToken"),
          id: this.state.withdrawId,
          email: this.state.withdrawEmail,
          type: this.state.action_type,
          pin: this.state.pin,
        })
        .then((response) => {
          if (response.data.success === "true") {
            alert("Transfer successful");
            this.getWithdrawals();
            this.setState({
              isLoading: true,
            });
            return false;
          } else {
            alert("Transfer failed");
          }
        });
    }
  };

  handleChange = (evt) => {
    console.log("name:", evt.target.name, ":value: ", evt.target.value);
    if (evt.target.name == "isP2P") {
      this.setState({ [evt.target.name]: !this.state.isP2P });
    } else {
      this.setState({ [evt.target.name]: evt.target.value });
    }
  };

  render() {
    console.log("this.state", this.state);
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <div className=" form-inline d-flex justify-content-between ">
              <h2 className="mb-3">Manually Verified Withdrawals</h2>

              <input
                className="m-2"
                value={this.state.perPage}
                onChange={this.handleChange}
                name="perPage"
                style={{ width: "10%", height: "10%" }}
                onKeyUp={this.getWithdrawals}
              />
              <h4
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#trxSearchModal"
              >
                Filter
              </h4>
            </div>
          </div>
          <hr className="dash-hr" />
          <div>
            {this.state.isLoading ? (
              <div style={{ padding: 50 }} className="text-center">
                <div>
                  <p>Loading...</p>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                {this.state.withdrawals.length ? (
                  <div>
                    <div className="pl-2 pt-2 ml-5">
                      {this.state.total && (
                        <strong className="text-primary">
                          Total: {this.state.total}
                        </strong>
                      )}
                    </div>
                    <div className="pl-2 ml-5">{paginationElement}</div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <tbody>
                          {this.state.withdrawals
                            .slice(
                              this.state.offset,
                              this.state.offset + this.state.perPage
                            )
                            .map((withdraw, index) => (
                              <tr key={index}>
                                <td scope="row dash-td" style={{ width: "1%" }}>
                                  <div className="media align-items-center">
                                    <p className="text-muted trans-date">
                                      {moment(withdraw.created_at).format("MMM Do")}

                                      <br />
                                      {moment(withdraw.created_at).format("hh:mm A")}
                                    </p>
                                  </div>
                                </td>
                                <td className="dash-td" style={{ width: "9%" }}>
                                  <div>
                                    <p className="font-weight-bold trans-date">
                                      {withdraw.account_name}
                                      <br />
                                      {withdraw.bank}
                                    </p>
                                    <a target="_blank" href={"/user/" + withdraw.email}>
                                      {withdraw.email}
                                    </a>
                                  </div>
                                </td>
                                <td className="dash-td">
                                  <p>{withdraw.account_number}</p>
                                </td>
                                <td className="dash-td">
                                  <p>
                                    <NumberFormat
                                      className="text-danger"
                                      value={withdraw.amount}
                                      prefix={"NGN "}
                                      displayType={"text"}
                                      thousandSeparator={","}
                                    />
                                  </p>
                                </td>

                                <td className="dash-td">
                                  <label>
                                    Reference:
                                    <h4>{withdraw.reference}</h4>
                                    <a
                                      target={
                                        withdraw.provider == "raven" ? "_blank" : ""
                                      }
                                      href={
                                        withdraw.provider == "raven"
                                          ? "/raven/" + withdraw.trx_ref
                                          : ""
                                      }
                                    >
                                      <h5 className="text-primary">
                                        {withdraw.trx_ref !== null
                                          ? withdraw.trx_ref
                                          : ""}
                                      </h5>
                                    </a>
                                    <h6>{withdraw.provider}</h6>
                                  </label>
                                </td>
                                <td className="dash-td">
                                  {withdraw.approved === "approved" ? (
                                    <div>
                                      {withdraw.status === 1 && withdraw.version === 1 ? (
                                        <button
                                          className="success btn btn-sm text-dark"
                                          style={{
                                            backgroundColor: "yellow",
                                            width: "100px",
                                          }}
                                        >
                                          pending
                                        </button>
                                      ) : withdraw.status === 2 ? (
                                        <button
                                          className="success btn btn-sm btn-danger"
                                          style={{ width: "100px" }}
                                        >
                                          failed
                                        </button>
                                      ) : withdraw.status === 4 ? (
                                        <button
                                          className="success btn btn-sm btn-success"
                                          style={{ width: "100px" }}
                                        >
                                          success
                                        </button>
                                      ) : withdraw.status === 1 &&
                                        withdraw.version === null ? (
                                        <button
                                          className="success btn btn-sm"
                                          style={{ width: "100px" }}
                                        >
                                          paid
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <button
                                        className="btn btn-warning"
                                        onClick={() =>
                                          this.openTransferDialog(withdraw, "pay")
                                        }
                                      >
                                        Pay
                                      </button>
                                      <button
                                        className="btn btn-success"
                                        onClick={() =>
                                          this.openTransferDialog(withdraw, "mark")
                                        }
                                      >
                                        Mark as paid
                                      </button>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="p-5 ml-5">{paginationElement}</div>
                  </div>
                ) : (
                  <div className="text-center no-data full-width-data">
                    <p className="text-muted no-trans-text-o">No withdrawal yet!</p>
                  </div>
                )}
              </div>
            )}

            <HometownModal
              data={this.state.withdrawals}
              getFileName={this.getFileName}
              handleChange={this.handleChange}
              handleSearch={this.filterWithdraws}
              sEndDate={this.state.end_date}
              sStartDate={this.state.start_date}
              isP2P={this.state.isP2P}
            />
          </div>
        </div>

        <div
          className="modal fade"
          id="confirmManualWithdrawModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="confirmManualWithdrawModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="confirmManualWithdrawModalLabel">
                  Confirm Withdrawals
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label>
                  Are you sure you want to confirm transfer of {this.state.amount} to{" "}
                  {this.state.account_name} ( {this.state.account_number})
                </label>
                <div className="form-group">
                  <input
                    className="form-control form-control-sm"
                    type="password"
                    placeholder="PIN"
                    name="pin"
                    value={this.state.pin}
                    onChange={this.handlePinInput}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.handleTransfer}
                  className="btn btn-primary btn-sm"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManualWithdrawals;
