import React, { Component } from "react";
import "./assets/index.css";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class LoanRepayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loan_repayments: [],
      isLoading: true,

      //pagination state
      perPage: 30,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getLoans();
  }

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getLoans();
    });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  getLoans = () => {
    //bind this to self
    let self = this;
    this.setState({
      isLoading: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/loan_repayments", {
        token: localStorage.getItem("supportToken"),
        current_page: this.state.currentPage,
        per_page: this.state.perPage,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            loan_repayments: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  render() {
    let total = 0;

    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div>
        <hr className="dash-hr" />
        {(() => {
          if (this.state.isLoading)
            return (
              <div style={{ padding: 50 }} className="text-center">
                <div>
                  <p>Loading...</p>
                </div>
              </div>
            );
          else
            return (
              <div className="table-responsive">
                <div class=" mr-4  mt-3 mb-3">
                  <div className="form-inline d-inline">
                    <b className="text-warning">Filter by Date Range</b>
                    <input
                      className="form-control mr-2 ml-2"
                      placeholder="Start Date"
                      type="date"
                      name="start_date"
                      value={this.state.start_date}
                      onChange={this.handleChange}
                    />
                    <input
                      className="form-control mr-2"
                      placeholder="End Date"
                      type="date"
                      name="end_date"
                      value={this.state.end_date}
                      onChange={this.handleChange}
                    />
                    <button
                      onClick={() => {
                        this.setState(
                          {
                            paginationLoaded: false,
                            currentPage: 1,
                          },
                          () => {
                            this.getLoans();
                          }
                        );
                      }}
                      className="btn btn-primary"
                    >
                      View
                    </button>
                  </div>

                  <div className="form-inline float-right">
                    <label>Show </label>
                    <select
                      className="form-control mr-2"
                      name="perPage"
                      value={this.state.perPage}
                      onChange={(evt) => {
                        this.setState(
                          {
                            perPage: evt.target.value,
                            paginationLoaded: false,
                            currentPage: 1,
                          },
                          () => {
                            this.getLoans();
                          }
                        );
                      }}
                    >
                      <option>30</option>
                      <option>50</option>
                      <option>100</option>
                      <option>150</option>
                      <option>200</option>
                      <option>500</option>
                      <option value="700">500 above</option>
                    </select>
                    <label>entries </label>
                  </div>
                </div>

                <div className="pl-2 ml-5">{paginationElement}</div>

                <table className="table align-items-center table-flush">
                  <thead
                    style={{ background: "#ddddee", fontWeight: "bold" }}
                    className="h4"
                  >
                    <td className="text-bold">Collection Date</td>
                    <td className="text-bold">Name</td>
                    <td className="text-bold">Loan#</td>
                    <td className="text-bold">Method</td>
                    <td className="text-bold">Amount</td>
                  </thead>

                  {/* if no granted loans  */}
                  {!this.state.loan_repayments.length ? (
                    <tbody>
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No loan repayments!
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ""
                  )}

                  {this.state.loan_repayments.length > 0 ? (
                    <tbody>
                      {this.state.loan_repayments.map((repayment, index) => {
                        // calculate
                        total += repayment.amount;

                        return (
                          <tr>
                            <td className="dash-td">
                              {moment(repayment.created_at).format(
                                "DD/MM/YYYY"
                              )}
                            </td>

                            <td className="dash-td">
                              <h4>
                                <a
                                  target="_blank"
                                  href={`/user/${repayment.email}`}
                                  className="text-dark"
                                >
                                  {repayment.lname} {repayment.fname}
                                </a>
                              </h4>
                            </td>
                            <td className="dash-td">
                              {repayment.loan_reference}
                            </td>
                            <td className="dash-td">{repayment.method}</td>

                            <td className="dash-td">
                              <NumberFormat
                                value={repayment.amount}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={true}
                                suffix={repayment.amount > 0 ? ".00" : ""}
                              />
                            </td>
                          </tr>
                        );
                      })}

                      <tr style={{ background: "#eeeeff" }}>
                        <td className="dash-td"></td>
                        <td className="dash-td"></td>
                        <td className="dash-td"></td>
                        <td className="dash-td"></td>
                        <td className="dash-td">
                          <NumberFormat
                            className="h3"
                            value={total}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            suffix={total > 0 ? ".00" : ""}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="10">
                          <h2>
                            <small>
                              Showing {this.state._from + 1} to {this.state._to}{" "}
                              of {this.state.totalEntries} entries (filtered
                              from {this.state.loan_repayments.length} total
                              entries)
                            </small>
                          </h2>
                          <div className="float-right">{paginationElement}</div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ""
                  )}
                </table>
              </div>
            );
        })()}
      </div>
    );
  }
}

export default LoanRepayment;
