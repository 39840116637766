import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class TransferFund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      old_phone: "",
      new_phone: "",
      deactivate_account: true,
      pin: "",
      old_account_name: "",
      new_account_name: "",
    };
  }

  componentDidMount() {}
  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  transferFund = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/transfer_fund", {
        token: localStorage.getItem("supportToken"),
        old_phone: this.state.old_phone,
        new_phone: this.state.new_phone,
        deactivate_account: this.state.deactivate_account,
        pin: this.state.pin,
        otp: this.props.otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          alert(response.data.message);
          self.setState({
            isLoading: false,
            old_phone: "",
            new_phone: "",
            deactivate_account: true,
            pin: "",
          });
        } else {
          self.setState({
            isLoading: false,
          });
        }
        alert(response.data.message);
      });
  };

  getAccountName = (phone, type) => {
    if (type === "old") {
      this.setState({
        old_account_name: "",
      });
    } else {
      this.setState({
        new_account_name: "",
      });
    }
    this.setState({
      account_name: "",
    });
    let self = this;
    axios
      .post(
        ConstantVar.API_BASE_URL + "/support/get_hometown_account_details",
        {
          token: localStorage.getItem("supportToken"),
          phone: phone,
        }
      )
      .then((response) => {
        if (response.data.success === "true") {
          if (type === "old")
            self.setState({
              old_account_name: response.data.account_name,
            });
          else
            self.setState({
              new_account_name: response.data.account_name,
            });
        } else {
          if (type === "old") {
            self.setState({
              old_account_name: "Not Found",
            });
          } else {
            self.setState({
              new_account_name: "Not Found",
            });
          }
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Transfer Fund</h2>
          </div>

          <form className=" p-3 col-md-7" onSubmit={this.transferFund}>
            <div className="form-group ">
              <label>Old Phone Number</label>
              <input
                required
                name="old_phone"
                className="form-control"
                placeholder="Old email"
                value={this.state.old_phone}
                onChange={(evt) => {
                  let phone = evt.target.value;
                  if (phone.length === 11) {
                    // get user full name
                    this.getAccountName(phone, "old");
                  }
                  this.handleChange(evt);
                }}
                type="phone"
              />
              <small className="text-info">{this.state.old_account_name}</small>
              <label className="float-right">
                <small>Deactivate Account</small>
                <input
                  name="deactivate_account"
                  checked={this.state.deactivate_account ? "checked" : ""}
                  onChange={() => {
                    this.setState({
                      deactivate_account: !this.state.deactivate_account,
                    });
                  }}
                  type="checkbox"
                  className="ml-2"
                />
              </label>
            </div>
            <div className="form-group ">
              <label>New Phone Number</label>
              <input
                required
                name="new_phone"
                className="form-control"
                placeholder="New Phone"
                value={this.state.new_phone}
                onChange={(evt) => {
                  let phone = evt.target.value;
                  if (phone.length === 11) {
                    // get user full name
                    this.getAccountName(phone, "new");
                  }
                  this.handleChange(evt);
                }}
                type="phone"
              />
              <small className="text-info">{this.state.new_account_name}</small>
            </div>

            <div className="form-group ">
              <label>Your PIN</label>
              <input
                required
                name="pin"
                className="form-control"
                placeholder="PIN"
                value={this.state.pin}
                onChange={this.handleChange}
                type="password"
              />
            </div>

            <button className="btn btn-success" disabled={this.state.isLoading}>
              Continue
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default TransferFund;
