import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";

class BalanceCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      limit: 50,
      total: 0,
      balanceList: [],
      loading: false
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount() {
    this.getBalanceCounter();
  }

  getBalanceCounter = () => {
    this.setState({
      loading: true,    });

    axios
      .get(ConstantVar.API_BASE_URL + "/support/reports/stats", {
        params: {
          token: localStorage.getItem("supportToken"),
          // end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          // start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
          type: "balance_counter",
          limit: this.state.limit,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            loading:false,
            balanceList: response.data.data,
            total : response.data.data.reduce((a, b) => a + b.available_bal ,0)
          });
        }
      });
  };

  render() {
    return (
      <div className="container">
        <div className="card  mx-auto">
          <div className="d-flex align-items-center">
            <h4 className="p-4">Balance Counter</h4>
            <div>
              Show:{" "}
              <input
                className="m-2"
                value={this.state.limit}
                onChange={this.handleChange}
                name="limit"
                onKeyUp={this.getBalanceCounter}

              style={{width:"20%",height:"10%"}}
              />
              {/* <button onClick={this.getBalanceCounter}>Get</button> */}
            </div>
          </div>
          <table className="table">
            <thead className="bg-light" >
              <tr>
                <th scope="col">Email Address</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            {this.state.loading ?
            <div className="align-self-center">
              <h4  >Loading ... </h4>
            </div>:
            <tbody>
              {this.state.balanceList?.map((item, index) => (
                <tr key={index}>
                  <td>
                     <a target="_blank" href={"/user/" + item.email}>
                      {item.email}
                      </a>
                  </td>
                  <td>{item.fname}</td>
                  <td>{item.lname}</td>
                  <td>
                    <NumberFormat
                      value={item.available_bal}
                      className="card-bal-your"
                      displayType={"text"}
                      prefix={"NGN "}
                      decimalScale={0}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>

          }
         
          
          </table>
          <div className="container">

            <p className="h2 p-2 bg-light d-flex justify-content-between">
              <span className="text-right">Total</span>
              <span>
                <NumberFormat
                    value={this.state.total}
                    className="card-bal-your"
                    displayType={"text"}
                    prefix={"NGN "}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
              </span>
            </p>
            </div>
        </div>
      </div>
    );
  }
}

export default BalanceCounter;
