import React, { Component } from "react";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import * as ConstantVar from "../../constants_var.js";
import "../../components/assets/index.css";
import ReactPaginate from "react-paginate";

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      archive: [],
      category_tickets: [],
      openArchive: false,
      isLoading: true,
      isClosed: false,
      closePagination: false,
      email: "",
      page: "overview",
      view: "new",
      ticket: {
        replies: [],
      },
      open_tickets: [],
      closedTickets: [],
      positioned: null,

      //pagination state
      perPage: 50,
      offset: 0,
      currentPage: 1,
      // pageCount: 2,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
    this.handleReturn = this.handleReturn.bind(this);
    this.handleReplyReturn = this.handleReplyReturn.bind(this);
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value }, () => {});
  };

  handleEmailSearch = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value }, () => {
      // this.loadTicketByEmail()
    });
  };

  loadTicketByEmail = () => {
    let self = this.state;
    this.setState({ closePagination: true, closedTickets: [] });

    axios
      .post(ConstantVar.API_BASE_URL + "/support/user_tickets", {
        token: localStorage.getItem("supportToken"),
        current_page: self.currentPage,
        per_page: self.perPage,
        email: self.email,
      })
      .then((response) => {
        if (response.data.success === "true")
          this.setState({
            closedTickets: response.data.data.sort(
              (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
            ),
            isLoading: false,
          });
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const clearance = localStorage.getItem("supportClearance");

    if (clearance == "admin" || clearance !== "loan-officer") {
      this.setState({ view: "category" });
    }
    this.loadArchived();
    this.loadNewTickets();
    this.loadOpenTickets();
    this.loadClosed();
  }

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.componentDidMount();
    });
  };

  handleReturn = () => {
    this.setState({
      view: "new",
      page: "overview",
      closePagination: false,
    });

    this.loadArchived();
    this.loadNewTickets();
    this.loadOpenTickets();
  };

  handleReplyReturn = () => {
    switch (this.state.view) {
      case "new":
        this.setState({
          view: "new",
          page: "overview",
        });
        break;

      case "open":
        this.setState({ view: "open", page: "overview" });
        break;

      case "archive":
        this.setState({ view: "archive", page: "overview" });
        break;

      case "closed":
        this.setState({ view: "closed", page: "overview" });
        break;
      case "category":
        this.setState({ view: "category", page: "overview" });
        break;
    }
    this.componentDidMount();
  };

  archiveTicket = (ticket) => {
    this.setState({ isSubmitting: true });

    var answer = window.confirm(
      "Are you sure you need to Archive this ticket?"
    );
    if (!answer) {
      this.setState({ ...this.state, isSubmitting: false });
      return;
    }
    axios
      .post(ConstantVar.API_BASE_URL + "/support/archive_ticket", {
        token: localStorage.getItem("supportToken"),
        ticketId: ticket,
      })
      .then((response) => {
        this.loadArchived();
        this.loadNewTickets();
        this.setState({ isSubmitting: false, page: "overview" });
      });
  };

  loadNewTickets = () => {
    let self = this;
    this.setState({ tickets: [] });

    axios
      .post(ConstantVar.API_BASE_URL + "/support/tickets", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        if (response.data.success === "true")
          var all_tickets = response.data.data.sort(
            (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
          );
        var ticket_categories = localStorage.getItem("ticket_categories");
       
        var parsedticket_categories = JSON.parse(ticket_categories);
        var categories = new Set(parsedticket_categories);

        // // Filter tickets based on the categories using Set for constant-time lookup
        var filteredTickets = all_tickets.filter((ticket) =>
          categories.has(ticket.category)
        );

        self.setState({
          tickets: all_tickets,
          isLoading: false,
          category_tickets: filteredTickets,
        });
      });
  };

  loadOpenTickets = () => {
    let self = this;
    this.setState({ tickets: [] });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/open_tickets", {
        token: localStorage.getItem("supportToken"),

        current_page: this.state.currentPage,
        per_page: this.state.perPage,
      })
      .then((res) => {
        if (res.data.success === "true") {
          self.setState({
            open_tickets: res.data.data.data.sort(
              (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
            ),
            isLoading: false,
            _from: res.data.data.pagination.from,
            _to: res.data.data.pagination.to,
          });
          if (!this.state.paginationLoaded) {
            let total = res.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: res.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  loadArchived = () => {
    let self = this;
    this.setState({ archive: [] });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/archived_tickets", {
        token: localStorage.getItem("supportToken"),
      })
      .then((res) => {
        if (res.data.success === "true") {
          self.setState({
            archive: res.data.data?.sort(
              (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
            ),
            isLoading: false,
          });
        }
      });
  };

  loadClosed = () => {
    let self = this;
    this.setState({ archive: [] });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/closed_tickets", {
        token: localStorage.getItem("supportToken"),
        current_page: this.state.currentPage,
        per_page: this.state.perPage,
      })
      .then((res) => {
        if (res.data.success === "true") {
          self.setState({
            closedTickets: res.data.data.data.sort(
              (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
            ),
            isLoading: false,
            _from: res.data.data.pagination.from,
            _to: res.data.data.pagination.to,
          });
          if (!this.state.paginationLoaded) {
            let total = res.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: res.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  sendReply = () => {
    let self = this;
    if (this.state.message.length < 3) {
      return;
    }
    axios
      .post(ConstantVar.API_BASE_URL + "/support/reply_ticket", {
        token: localStorage.getItem("supportToken"),
        email: this.state.ticket.email,
        message: this.state.message,
        message_option: this.state.message_option,
        ticket_id: this.state.ticket.id,
      })
      .then((response) => {
        if (response.data.success === "true") {
          let tickets = self.state.tickets;
          let ticket = self.state.ticket;

          ticket.replies.unshift({
            message: this.state.message,
            updated_at: moment().toString(),
            is_agent: 1,
          });
          tickets[self.state.positioned] = ticket;

          self.setState({
            tickets: tickets,
            message: "",
            message_option: "",
          });
        } else {
          alert("Something went wrong");
        }
      });
  };

  closeTicket = () => {
    let self = this;

    if (!this.state.ticket.assigned_agent)
      return alert("You cannot close an unattended ticket.");
    var canClose = window.confirm(
      "Are you sure you want to close this ticket?"
    );
    if (canClose) {
      axios
        .post(ConstantVar.API_BASE_URL + "/support/close_ticket", {
          token: localStorage.getItem("supportToken"),
          ticket_id: this.state.ticket.id,
        })
        .then((response) => {
          if (response.data.success === "true") {
            self.setState({
              page: "overview",
            });
            this.loadNewTickets();
          } else {
            alert("Something went wrong");
          }
        });
    }
  };

  createTicket = (e) => {
    let self = this;
    e.preventDefault();
    axios
      .post(ConstantVar.API_BASE_URL + "/support/create_ticket", {
        token: localStorage.getItem("supportToken"),
        email: this.state.email_ct,
        message: this.state.message_ct,
        subject: this.state.subject_ct,
        message_option: this.state.message_option_ct,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            message_ct: "",
            message_option_ct: "",
            email_ct: "",
            subject_ct: "",
            page: "overview",
          });
          self.loadNewTickets();
        } else {
          alert(response.data.message);
        }
      });
  };

  ticketsPage = () => {
    let tickets = this.state.tickets || [];
    let category_tickets = this.state.category_tickets || [];
    let archive = this.state.archive || [];
    let open_tickets = this.state.open_tickets || [];
    let closedTickets = this.state.closedTickets || [];

    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination py-4"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div className=" d-flex justify-content-between ">
        {/* archived */}
        {this.state.view == "archive" ? (
          <div className="card shadow col m-2">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button className="btn btn-sm m-2" onClick={this.handleReturn}>
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                Archived (
                {this.state.archive?.length > 0 ? this.state.archive.length : 0}
                ){" "}
              </h2>
            </div>
            {/* <div className="pl-2 ml-5 ">
              {!this.state.closePagination &&
                <>
                  {paginationElement}
                </>
              }
              
            </div> */}
            <hr className="dash-hr" />
            {/* archive */}
            <div
              className="col-6"
              style={{ overflowY: "scroll", minHeight: "70%" }}
            >
              {!this.state.isLoading ? (
                archive.map((ticket, index) => {
                  return (
                    <div
                      key={index}
                      className="card card-style bg-light"
                      style={{
                        margin: 20,
                        padding: "20",
                        borderBottom: "1px solid #cccccc",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState({
                          ticket: ticket,
                          page: "chatPage",
                          positioned: index,
                        })
                      }
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <h3 className="text-warning">{ticket.category}</h3>
                          <p>
                            <span className="font-weight-bold">
                              <a target="_blank" href={"/user/" + ticket.email}>
                                {ticket.email}
                              </a>
                            </span>{" "}
                            - {ticket.phone}
                          </p>

                          <div>
                            {ticket.replies >= 1 && (
                              <span className="font-weight-bold">
                                {ticket.replies[0]?.is_agent === 1
                                  ? "Reply:"
                                  : "Message"}
                              </span>
                            )}{" "}
                            {ticket.replies && (
                              <address>
                                {ticket.replies[0]?.is_image === 1 ? (
                                  "Image attached"
                                ) : (
                                  <span className="itm-title">
                                    {ticket.replies[0]?.message}
                                  </span>
                                )}
                                <div className="text-right d-flex justify-content-between align-items-center">
                                  <small>
                                    {moment(ticket.updated_at).fromNow()}
                                  </small>
                                </div>
                              </address>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  <p style={{ padding: 100 }}>Loading...</p>
                </div>
              )}
            </div>
          </div>
        ) : this.state.view == "open" ? (
          <div className="card shadow col m-2">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button
                  className="btn btn-sm m-2"
                  onClick={this.handleReturn}
                  //   () =>
                  //   this.setState({
                  //     view: "new",
                  //   })
                  // }
                >
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                Opened ({this.state.open_tickets.length}){" "}
              </h2>
            </div>
            <div className="pl-2 ml-5 ">
              {!this.state.closePagination && <>{paginationElement}</>}
            </div>
            <hr className="dash-hr" />
            {/* archive */}
            <div
              className="col-6"
              style={{ overflowY: "scroll", minHeight: "70%" }}
            >
              {!this.state.isLoading ? (
                open_tickets.map((ticket, index) => {
                  return (
                    <div
                      key={index}
                      className="card card-style bg-white"
                      style={{
                        margin: 20,
                        padding: "20",
                        borderBottom: "1px solid #cccccc",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState({
                          ticket: ticket,
                          page: "chatPage",
                          positioned: index,
                        })
                      }
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <h3 className="text-warning">{ticket.category}</h3>
                          <p>
                            <span className="font-weight-bold">
                              <a target="_blank" href={"/user/" + ticket.email}>
                                {ticket.email}
                              </a>
                            </span>{" "}
                            - {ticket.phone}
                          </p>

                          <div>
                            {ticket.replies >= 1 && (
                              <span className="font-weight-bold">
                                {ticket.replies[0]?.is_agent === 1
                                  ? "Reply:"
                                  : "Message"}
                              </span>
                            )}{" "}
                            {ticket.replies && (
                              <address>
                                {ticket.replies[0]?.is_image === 1 ? (
                                  "Image attached"
                                ) : (
                                  <span className="itm-title">
                                    {ticket.replies[0]?.message}
                                  </span>
                                )}
                                <div className="text-right d-flex justify-content-between align-items-center">
                                  <small>
                                    {moment(ticket.updated_at).fromNow()}
                                  </small>
                                </div>
                              </address>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  <p style={{ padding: 100 }}>Loading...</p>
                </div>
              )}
            </div>
          </div>
        ) : this.state.view == "new" ? (
          <div className="card shadow col m-2 ">
            <div className="card-header border-0">
              <h2 className="mb-0">
                Tickets ({this.state.tickets.length}){" "}
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "category",
                      })
                    }
                  >
                    My Tickets
                  </a>
                </small>
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "archive",
                      })
                    }
                  >
                    Archive
                  </a>
                </small>
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "open",
                      })
                    }
                  >
                    Opened
                  </a>
                </small>
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "closed",
                      })
                    }
                  >
                    Closed
                  </a>
                </small>
                <button
                  className="btn btn-sm float-right"
                  onClick={() =>
                    this.setState({
                      page: "createTicketPage",
                    })
                  }
                >
                  <i className="fa fa-plus fa-2x"></i>
                </button>
              </h2>
            </div>

            <hr className="dash-hr" />

            <div
              className="col-6  mb-5 "
              style={{ overflowY: "scroll", minHeight: "70%" }}
            >
              {!this.state.isLoading ? (
                tickets.map((ticket, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-light card-style card"
                      style={{
                        margin: 20,
                        padding: "20",
                        borderBottom: "1px solid #cccccc",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState({
                          ticket: ticket,
                          page: "chatPage",
                          positioned: index,
                        })
                      }
                    >
                      <div className="row ">
                        <div className="col-sm-12">
                          <h3 className="text-warning">{ticket.category}</h3>
                          <p>
                            <span className="font-weight-bold">
                              <Link
                                style={{ color: "midnightblue" }}
                                to={"/user/" + ticket.email}
                              >
                                {ticket.email}
                              </Link>
                            </span>{" "}
                            - {ticket.phone}
                          </p>

                          <div>
                            <span className="font-weight-bold">
                              {ticket.replies[0]?.is_agent === 1
                                ? "Reply:"
                                : "Message"}
                            </span>{" "}
                            <address>
                              {ticket.replies[0]?.is_image === 1 ? (
                                "Image attached"
                              ) : (
                                <span className="itm-title">
                                  {ticket.replies[0]?.message}
                                </span>
                              )}
                              <div className="text-right d-flex justify-content-between align-items-center">
                                <small>
                                  {moment(ticket.updated_at).fromNow()}
                                </small>
                              </div>
                            </address>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  <p style={{ padding: 100 }}>Loading...</p>
                </div>
              )}
            </div>
          </div>
        ) : this.state.view == "category" ? (
          <div className="card shadow col m-2 ">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button className="btn btn-sm m-2" onClick={this.handleReturn}>
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                My Tickets ({this.state.category_tickets.length}){" "}
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "archive",
                      })
                    }
                  >
                    Archive
                  </a>
                </small>
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "open",
                      })
                    }
                  >
                    Opened
                  </a>
                </small>
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "closed",
                      })
                    }
                  >
                    Closed
                  </a>
                </small>
                <button
                  className="btn btn-sm float-right"
                  onClick={() =>
                    this.setState({
                      page: "createTicketPage",
                    })
                  }
                >
                  <i className="fa fa-plus fa-2x"></i>
                </button>
              </h2>
            </div>

            <hr className="dash-hr" />

            <div
              className="col-6  mb-5 "
              style={{ overflowY: "scroll", minHeight: "70%" }}
            >
              {!this.state.isLoading ? 
              <div>
                   {category_tickets.length > 0 ? 
                    category_tickets.map((ticket, index) => {
                      return (
                        <div
                          key={index}
                          className="bg-light card-style card"
                          style={{
                            margin: 20,
                            padding: "20",
                            borderBottom: "1px solid #cccccc",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.setState({
                              ticket: ticket,
                              page: "chatPage",
                              positioned: index,
                            })
                          }
                        >
                          <div className="row ">
                            <div className="col-sm-12">
                              <h3 className="text-warning">{ticket.category}</h3>
                              <p>
                                <span className="font-weight-bold">
                                  <Link
                                    style={{ color: "midnightblue" }}
                                    to={"/user/" + ticket.email}
                                  >
                                    {ticket.email}
                                  </Link>
                                </span>{" "}
                                - {ticket.phone}
                              </p>
    
                              <div>
                                <span className="font-weight-bold">
                                  {ticket.replies[0]?.is_agent === 1
                                    ? "Reply:"
                                    : "Message"}
                                </span>{" "}
                                <address>
                                  {ticket.replies[0]?.is_image === 1 ? (
                                    "Image attached"
                                  ) : (
                                    <span className="itm-title">
                                      {ticket.replies[0]?.message}
                                    </span>
                                  )}
                                  <div className="text-right d-flex justify-content-between align-items-center">
                                    <small>
                                      {moment(ticket.updated_at).fromNow()}
                                    </small>
                                  </div>
                                </address>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
               
             :
             
             <div className="">
              <h3>
              No assigned ticket, Go to <a href="#" onClick={this.handleReturn}>General tickets</a> 
                </h3>
                </div>
             }        
                 
              </div>
               : 
                <div className="text-center">
                  <p style={{ padding: 100 }}>Loading...</p>
                </div>
              }
            </div>
          </div>
        ) : this.state.view == "closed" ? (
          <div className="card shadow col m-2 ">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button className="btn btn-sm m-2" onClick={this.handleReturn}>
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                Closed Tickets ({this.state.closedTickets.length}){" "}
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "archive",
                      })
                    }
                  >
                    Archive
                  </a>
                </small>
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={() =>
                      this.setState({
                        view: "open",
                      })
                    }
                  >
                    Opened
                  </a>
                </small>
              </h2>
            </div>

            <div className="pl-2 ml-5 d-flex justify-content-center align-items-center">
              {!this.state.closePagination && <>{paginationElement}</>}

              <input
                name="email"
                onKeyDown={this.loadTicketByEmail}
                type="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>
            <hr className="dash-hr" />

            <div
              className="col-6  mb-5 "
              style={{ overflowY: "scroll", minHeight: "70%" }}
            >
              {!this.state.isLoading ? (
                closedTickets.map((ticket, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-light card-style card"
                      style={{
                        margin: 20,
                        padding: "20",
                        borderBottom: "1px solid #cccccc",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState({
                          ticket: ticket,
                          page: "chatPage",
                          isClosed: true,
                          positioned: index,
                        })
                      }
                    >
                      <div className="row ">
                        <div className="col-sm-12">
                          <h3 className="text-warning">{ticket.category}</h3>
                          <p>
                            <span className="font-weight-bold">
                              <Link
                                style={{ color: "midnightblue" }}
                                to={"/user/" + ticket.email}
                              >
                                {ticket.email}
                              </Link>
                            </span>{" "}
                            - {ticket.phone}
                          </p>

                          <div>
                            <span className="font-weight-bold">
                              {ticket.replies[0]?.is_agent === 1
                                ? "Reply:"
                                : "Message"}
                            </span>{" "}
                            <address>
                              {ticket.replies[0]?.is_image === 1 ? (
                                "Image attached"
                              ) : (
                                <span className="itm-title">
                                  {ticket.replies[0]?.message}
                                </span>
                              )}
                              <div className="text-right d-flex justify-content-between align-items-center">
                                <small>
                                  {moment(ticket.updated_at).fromNow()}
                                </small>
                              </div>
                            </address>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  <p style={{ padding: 100 }}>Loading...</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  chatPage = () => {
    return (
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button className="btn btn-sm" onClick={this.handleReplyReturn}>
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>{" "}
                <Link target="_blank" to={"/user/" + this.state.ticket.email}>
                  {this.state.ticket.email}
                </Link>
                {""}({this.state.ticket.phone})
              </h2>{" "}
              {!this.state.isClosed && this.state.ticket.assigned_agent && (
                <button
                  className="btn btn-danger btn-sm float-right"
                  disabled={this.state.isSubmitting}
                  onClick={this.closeTicket}
                >
                  Close Ticket
                </button>
              )}
              <div className="mb-3 mt-3">
                <h3 className="text-warning">{this.state.ticket.category}</h3>
                <div></div>
              </div>
            </div>
            <hr className="dash-hr" />

            <div className="row">
              <div className="col-12">
                <div className="container p-3">
                  {!this.state.isClosed && (
                    <>
                      <div className="form-group mt-3">
                        <label>Reply user</label>
                        <textarea
                          className="form-control"
                          placeholder="Reply here..."
                          name="message"
                          rows="8"
                          onChange={this.handleChange}
                          value={this.state.message}
                        ></textarea>
                        <select
                          className="p-2"
                          name="message_option"
                          onChange={this.handleChange}
                          value={this.state.message_option}
                        >
                          <option value="">Message Option</option>
                          <option value="allow_upload">
                            Allow Upload
                          </option>{" "}
                        </select>
                        <button
                          className="mt-2 btn  btn-primary float-right"
                          onClick={this.sendReply}
                          disabled={this.state.isSubmitting}
                        >
                          Send
                        </button>
                      </div>

                      <hr />
                    </>
                  )}
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="mt-2 mb-3">Conversations</h4>
                      {!this.state.isClosed && (
                        <h5
                          style={{ cursor: "pointer" }}
                          className=" text-warning p-3"
                          onClick={() =>
                            this.archiveTicket(this.state.ticket.id)
                          }
                        >
                          Archive
                        </h5>
                      )}
                    </div>
                    {this.state.ticket.replies.length > 0 ? (
                      this.state.ticket.replies.map((con, index) => {
                        return (
                          <div
                            className="btm-shadow "
                            key={index}
                            style={{
                              backgroundColor: con.is_agent ? "" : "#f8f9fe",
                            }}
                          >
                            <address className="p-3">
                              {con.is_image ? (
                                <img
                                  src={`${ConstantVar.API_BASE_URL2}/static/tickets/${this.state.ticket.id}/${con.file_name}.png`}
                                />
                              ) : (
                                con.message
                              )}
                              <div className="d-flex align-items-center justify-content-between mt-2">
                                {con.agent_email && (
                                  <div className="sidebar-icon-color ">
                                    <i className="fas fa-user-tie p-2" />
                                    <small>
                                      {con.agent_email
                                        ? con.agent_email
                                        : "trial"}
                                    </small>
                                  </div>
                                )}

                                <small className="text-right text-sm text-muted">
                                  {moment(con.updated_at).format(
                                    "MMM Do, h:mm:ss a"
                                  )}
                                </small>
                              </div>
                            </address>
                            {/* <hr /> */}
                          </div>
                        );
                      })
                    ) : (
                      <label>No conversations yet</label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  createTicketPage = () => {
    return (
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button
                  className="btn btn-sm"
                  onClick={() =>
                    this.setState({
                      page: "overview",
                    })
                  }
                >
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                Open Ticket
              </h2>{" "}
            </div>
            <hr className="dash-hr" />

            <div className="row">
              <div className="col-12">
                <div className="container p-3">
                  <form className=" mt-3" onSubmit={this.createTicket}>
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject here..."
                        name="subject_ct"
                        required
                        onChange={this.handleChange}
                        value={this.state.subject_ct}
                      />
                    </div>
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        className="form-control"
                        placeholder="Reply here..."
                        name="message_ct"
                        rows="8"
                        required
                        onChange={this.handleChange}
                        value={this.state.message_ct}
                      ></textarea>
                    </div>

                    <div className="form-group">
                      <label>User Email</label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        placeholder="User email address"
                        name="email_ct"
                        onChange={this.handleChange}
                        value={this.state.email_ct}
                      />
                    </div>
                    <div className="form-group">
                      <select
                        className="p-2"
                        name="message_option_ct"
                        onChange={this.handleChange}
                        value={this.state.message_option_ct}
                      >
                        <option value="">Message Option</option>
                        <option value="allow_upload">Allow Upload</option>{" "}
                      </select>
                      <button
                        className="mt-2 btn  btn-primary float-right"
                        disabled={this.state.isSubmitting}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div style={{ overflow: "hidden" }}>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-loans pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col-md-12 col-sm-12 ">
                {this.state.page === "overview"
                  ? this.ticketsPage()
                  : this.state.page === "chatPage"
                  ? this.chatPage()
                  : // : this.state.page === "archivePage"
                    // ? this.archivePage()
                    this.createTicketPage()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ticket;
