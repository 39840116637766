import React, { Component } from "react";
import RegisteredUsers from "./registered";
import VerifiedUsers from "./verified";
import TransactedUsers from "./transacted";
import AllTransactedUsers from "./allTransacted";
import UnverifiedUsers from "./unverified";
import NonTransactedUsers from "./non-transacted";
import VerifiedNonTransactedUsers from "./verified-and-not-transacting";
import Kochure from "./kochure-transaction";
import StoppedTransacting from "./stopped";


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "registered-users",
      // tab: "stopped_Transacting",
    };
  }

  render() {
    let clearance = localStorage.getItem("supportClearance");

    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">App Stats</h3>
              </div>

              <hr />

              <div>
                <ul className="nav nav-pills  p-3">
                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " + (this.state.tab === "stopped_Transacting" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "stopped_Transacting" })}
                    >
                      {" "}
                      Users Last Transaction Log                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "registered-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "registered-users" })}
                    >
                      {" "}
                      Registered Users
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "verified-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "verified-users" })}
                    >
                      {" "}
                      Verified Users
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "transacted-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "transacted-users" })}
                    >
                      {" "}
                      Transacted Users
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "all-transacted-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "all-transacted-users" })}
                    >
                      {" "}
                      All Transacted Users
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "verified-and-not-transacted" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "verified-and-not-transacted" })}
                    >
                      {" "}
                      Verified-NonTransacted-Users
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "non-transacted-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "non-transacted-users" })}
                    >
                      {" "}
                      Non Transacted Users
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "unverified-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "unverified-users" })}
                    >
                      {" "}
                      Unverified Users
                    </a>
                  </li>
                  {clearance !== "staff" &&
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "kochureTransaction" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "kochureTransaction" })}
                      >
                        {" "}
                        Kochure Transaction   
                      </a>
                    </li>
                  }
                </ul>

                <div className="p-3">
                  {
                  // this.state.tab === "app-usages" ? (
                  //   <AppUsage />
                  // ) :
                   this.state.tab === "registered-users" ? (
                    <RegisteredUsers />
                  ) : this.state.tab === "verified-users" ? (
                    <VerifiedUsers />
                  ) : this.state.tab === "transacted-users" ? (
                    <TransactedUsers />
                  ) : this.state.tab === "all-transacted-users" ? (
                    <AllTransactedUsers />
                  ) : this.state.tab === "unverified-users" ? (
                    <UnverifiedUsers />
                  ) : this.state.tab === "non-transacted-users" ? (
                    <NonTransactedUsers />
                  ) 
                  : this.state.tab === "verified-and-not-transacted" ? (
                    <VerifiedNonTransactedUsers />
                  ) : 
                   this.state.tab === "stopped_Transacting" ? (
                    <StoppedTransacting />
                  ) : 
                 this.state.tab === "kochureTransaction" ? (
                    <Kochure />
                    // "kochure"
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;