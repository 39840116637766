import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import moment from "moment";

class BankStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  getAccountStatementPdf = () => {
    //bind this to self
    if (this.state.email === null || this.state.email?.length < 5) {
      alert("Enter a valid email");
      return false;
    }
    if (this.state.start_date === this.state.end_date) {
      alert("Start and End date cannot be the same");
      return false;
    }

    
    this.setState({
      isLoading: true,
    });

    let self = this;
    
    axios
      .post(
        ConstantVar.API_BASE_URL + "/support/accountpdf",
        {
          token: localStorage.getItem("supportToken"),
          userEmail: this.state.email,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
        },
       {headers: { Accept: "application/pdf"} , responseType: "arraybuffer"},
        
        )
      .then((response) => {

        // convert bytes to JSON if under 100
       if(response.data.byteLength >= 100){
          self.setState({
            isLoading: false,
          });
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          return
       }
       const  resData = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data)) );
      
            self.setState({
                isLoading: false,
        });
        // alert("User not found!");
        alert(resData.message);
    // }
      }).catch((error) => {
        console.log("error: ",error)
      });
  }; 

  getNewAccountStatementPdf = () => {
    //bind this to self
    if (this.state.email === null || this.state.email?.length < 5) {
      alert("Enter a valid email");
      return false;
    }
    if (this.state.start_date === this.state.end_date) {
      alert("Start and End date cannot be the same");
      return false;
    }

    
    this.setState({
      isLoading: true,
    });

    let self = this;
    
    axios
      .post(
        "localhost:4000/get_pdf",
        {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
        }, { responseType: 'arraybuffer', headers: { Accept: 'application/pdf'}})
      .then((response) => {

          self.setState({
            isLoading: false,
          });

          window.open(response.data, '_blank');



          return
         }).catch((error) => {
        console.log("error: ",error)
      });
  }; 

  handleDelete = () => {
    //bind this to self
console.log("GOT CALLED")
    axios.delete(ConstantVar.API_BASE_URL + "/user",
        { headers: { Accept: 'application/json', Authorization:localStorage.getItem("supportToken")}})
      .then((response) => {
         console.log("RESPONSE:: ",response)
          return
         }).catch((error) => {
        console.log("error: ",error)
      });
  }; 

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Bank Statement</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="email"
                className="form-control"
                placeholder="User Email"
                value={this.state.email}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <div className="form-inline mt-3">
              <label>Start Date:</label>
              <input
                type="date"
                className="ml-2 mr-2 form-control"
                name="start_date"
                value={this.state.start_date}
                onChange={this.handleChange}
              />
              <label>End Date:</label>
              <input
                type="date"
                className="ml-2 mr-2 form-control"
                name="end_date"
                value={this.state.end_date}
                onChange={this.handleChange}
              />
              <button
                disabled={this.state.isLoading}
                onClick={this.getAccountStatementPdf}
                className="btn btn-success"
              >
                Get Statement
              </button>
           
            </div>
          </div>

          {this.state.isLoading ? (
            <div className="text-center">
              <label>Generating PDF Process, Kindly wait ..</label>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default BankStatement;
