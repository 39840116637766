import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";

class OtherTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      airtime:{}, 
      betting:{}, 
      flight:{}, 
      cable:{},waec:{},electricity:{},data:{},
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  getTransactionsReport = () => {
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/support/reports/", {
        params: {
          token: localStorage.getItem("supportToken"),
          start_date: self.state.start_date,
          end_date: self.state.end_date
        },
      })

      .then((res) => {
        let server_output = res.data;
        if (server_output.success == "true") {
          const {airtime, cable,waec,electricity,data, betting,flight} = server_output.data
          self.setState({
              airtime, cable,waec,electricity,data, betting,flight
           });
        }
        self.setState({
          isLoading: false,
        });
      });
  };

  componentDidMount() {
    this.getTransactionsReport();
  }

  render() {
    return (
      <div className="container">
        <div className="card shadow col-md-6 p-3 mx-auto">
          <div>
            <div className="form-inline">
              <label>From Date:</label>
              <input
                type="date"
                className="ml-2 mr-2 form-control"
                name="start_date"
                value={this.state.start_date}
                onChange={this.handleChange}
              />
              <label>To Date:</label>
              <input
                type="date"
                className="ml-2 mr-2 form-control"
                name="end_date"
                value={this.state.end_date}
                onChange={this.handleChange}
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={this.getTransactionsReport}
            >
              View
            </button>
          </div>

          <div className="mt-5 text-center">
            <h4>Transactions Stats</h4>
          </div>

          <table>
            <tbody>
              <tr>
                <th className="p-3">Report On</th>
                <th className="p-3">Transaction rate</th>
                <th className="p-3">Amount</th>
              </tr>
              <tr>
                <th className="p-3">Airtime</th>
                <th className="p-3">{this.state.airtime.total}</th>
                <th className="p-3">
                  <NumberFormat
                    value={this.state.airtime.sum}
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
                </th>
              </tr>
              <tr>
                <th className="p-3">Betting</th>
                <th className="p-3">{this.state.betting?.total}</th>
                <th className="p-3">
                  <NumberFormat
                    value={
                      this.state.betting?.sum == null
                        ? 0
                        : this.state.betting?.sum
                    }
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
                </th>
              </tr>
              <tr>
                <th className="p-3">Cable</th>
                <th className="p-3">{this.state.cable.total}</th>
                <th className="p-3">
                  <NumberFormat
                    value={this.state.cable.sum}
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
                </th>
              </tr>
              <tr>
                <th className="p-3">Mobile Data</th>
                <th className="p-3">{this.state.data.total}</th>
                <th className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.data.sum}
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
                </th>
              </tr>
              <tr>
                <th className="p-3">Electricity</th>
                <th className="p-3">{this.state.electricity.total}</th>
                <th className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.electricity.sum}
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
                </th>
              </tr>
              <tr>
                <th className="p-3">Waec</th>
                <th className="p-3">{this.state.waec.total}</th>
                <th className="p-3">
                  <NumberFormat
                    value={this.state.waec.sum}
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
                </th>
              </tr>
             
              <tr>
                <th className="p-3">Flight</th>
                <th className="p-3">{this.state.flight.total}</th>
                <th className="p-3">
                  <NumberFormat
                    value={this.state.flight.sum}
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
                </th>
              </tr>
            
              <tr style={{ fontSize: "20px", color: "#e84804 " }}>
                <th className="p-3">Total</th>
                <th className="p-3">
                  {this.state.waec.total +
                    this.state.electricity.total +
                    this.state.data.total +
                    this.state.airtime.total +
                    this.state.betting?.total +
                    this.state.flight.total +
                    this.state.cable.total}
                </th>
                <th className="p-3">
                  <NumberFormat
                    value={
                      Number(this.state.waec.sum) +
                      Number(this.state.electricity.sum) +
                      Number(this.state.data.sum) +
                      Number(this.state.betting?.sum) +
                      Number(this.state.flight?.sum) +

                      Number(this.state.airtime.sum) +
                      Number(this.state.cable.sum)
                    }
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                  />
                </th>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default OtherTransactions;
