import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

export default class SearchAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      account_number: "",
      account_name: "",
      bank: "",
      email: "",
      active: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getUserAccountDetails = this.getUserAccountDetails.bind(this);
    // this.sendSms = this.sendSms.bind(this);
  }

  //   getUser

  // updateLoan
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  //   componentDidMount(){
  //     this.getUserLoanDetails()
  //   }

  getUserAccountDetails = (e) => {
    e.preventDefault();
    //  let self = this;
    // alert("Are you sure you wish to update this data ? ")
    this.setState({
      isLoading: true,
    });

    console.log("STATE:: ", this.state)
    axios
      .post(ConstantVar.API_BASE_URL + `/support/account_search`, {
        account_number: this.state.account_number,
        token: localStorage.getItem("supportToken"),
      })
      .then((res) => {

        this.setState({
          isLoading: false,
        });
        if (res.data.success == "true") {
          // console.log("RESULT: ",res.data)
          return this.setState({
            account_name: res.data.data.account_name,
            email: res.data.data.email,
            account_number: res.data.data.account_number,
            bank: res.data.data.bank,
            active: true,
          });
        } else {
         
          alert(res.data.message);
          return;
        }
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">Get Reserved Account</h2>
              <div className="form-inline" style={{ float: "right" }}>
                <input
                  type="numeric"
                  className="ml-2 mr-2 form-control float-right"
                  name="account_number"
                  placeholder="Enter Account Number"
                  value={this.state.account_number}
                  onChange={this.handleChange}
                />
                <button 
                  className="btn btn-primary"
                  onClick={this.getUserAccountDetails}
                  disabled={this.state.isLoading}
                  >
                  Go
                </button>
              </div>
            </div>
            <hr className="dash-hr" />
            {(() => {
              if (this.state.isLoading)
                return (
                  <div style={{ padding: 50 }} className="text-center">
                    <div>
                      <p>Loading...</p>
                    </div>
                  </div>
                );
              else
                return (
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush">
                      <thead>
                        <tr>
                          <td></td>
                          <td>Email</td>
                          <td>Name</td>
                          <td>Bank</td>
                          <td>Account Number</td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.active && (
                          <tr
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                <div className="loan-img-bckg">
                                  <i className="fas fa-piggy-bank loan-img-color" />
                                </div>
                              </div>
                            </th>

                            <td className="" style={{ width: "5%" }}>
                              <p className="">{this.state.email}</p>
                            </td>

                            <td className="" style={{ width: "5%" }}>
                              <p className="">{this.state.account_name}</p>
                            </td>

                            <td className="" style={{ width: "5%" }}>
                              <p className="">{this.state.bank}</p>
                            </td>

                            <td className="" style={{ width: "5%" }}>
                              <p className="">{this.state.account_number}</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                );
            })()}
          </div>
        </div>
      </div>
    );
  }
}
