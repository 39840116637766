import React, { Component } from 'react'
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import moment from "moment";

export default class ID_Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      id_verification_list: null,

    };
  }
  
  componentDidMount() {
    this.getIdVerificationList();
  }

  getIdVerificationList(){
    this.setState({
      isLoading: true,
      id_verification_list: null,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/support/id_card_awaiting", {
        token: localStorage.getItem("supportToken"),
      })
      .then((res) => {
                let elements = res.data.data.map((account,key) => (
          <tr key={key}>
            <td>{ moment(account.id_card_uploaded_at).format("MMM Do, h:mm:ss a")}</td>
            <td>

              <a target="_blank" href={`/verification_requests?email=${account.email}`}>
                {account.email}
              </a>
            </td>
            <td>{account.fname}</td>
            <td>{account.lname}</td>
            <td>{account.phone}</td>
            <td>
              {(account.bvn_verified_at !== null ) && (account.dp_uploaded_at  !== null || account.dp_verified_at  !== null) ? (
                <button className="btn btn-success">Completed</button>
              ) :
              ((account.bvn_verified_at === null) && (account.dp_uploaded_at  !== null || account.dp_verified_at  !== null))?
              (
                <button className="btn btn-danger">BVN{" "}Required</button>
              )
              :
              ((account.bvn_verified_at !== null ) && account.dp_uploaded_at  === null)? (

                <button className="btn btn-danger">DP{" "}Required</button>
              ):
              (
                null
            )}
            </td>
          </tr>
        ));
        this.setState({
          isLoading: false,
          elements: elements,
          id_verification_list: 1,
        });
      })
  }

  render() {
    return (
      <div>
        
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">ID Card Verification</h2>
          </div>
          {this.state.isLoading ? (
            <div className="text-center">
              <label>Please wait....</label>
            </div>
          ) : (
            ""
          )}
           {this.state.id_verification_list !== null ? (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Created At</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">...</th>
                  </tr>
                </thead>
                <tbody>{this.state.elements}</tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
                
      </div>
    )
  }
}
