import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class loadFollowupSchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      akawo: [],
      isLoading: true,
      schedules: null,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      page: "overview",
      conversations: [],
      user: {},
      isSubmitting: false,
      showContacts: false,
      showAll: false,
      filter_contacts: [],
      e_email: this.props.location.pathname.split("followup_schedules/")[1] != "" ? this.props.location.pathname.split("followup_schedules/")[1] : "",
      contacts: [],
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount() {
    this.loadFollowupScheduled();
  }

  loadFollowupScheduled = () => {
    //bind this to self
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/followup_schedules", {
        token: localStorage.getItem("supportToken"),
        start_date: this.state.start_date,
        end_date: this.state.end_date,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            schedules: response.data.data,
            isLoading: false,
          });
        }
      });
  };

  loadUserContacts = () => {
    // this.setState({
    //  // isSubmitting: true,
    // });
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/load_defaulter_contacts", {
        token: localStorage.getItem("supportToken"),
        email: this.state.user.email,
      })
      .then(async (response) => {
        let contacts = await this.convertContactBlobToJSON(response.data.data);
        if (response.data.success === "true") {
          self.setState({
            isSubmitting: false,
            contacts: contacts,
          });
        }
      });
  };

  convertContactBlobToJSON(contactsLog) {
    // note: the contacts log is a blob, a MAP entries from dart, and need to be converted to a proper JSON Object
    // the conversion was handled on the React client side to take off load from the server
    // the function is simply  replace some text to get correct JSON format.
    let bufferOriginal = Buffer.from(contactsLog.data); // convert to buffer instance
    let bufferString = bufferOriginal.toString("utf8"); // convert buffer to string
    bufferString = bufferString.replace(/name: /g, '"name":"'); // replace name:  with containing quote
    bufferString = bufferString.replace(/, number: /g, '", "number":"'); // replace number with containing quote
    bufferString = bufferString.replace(/},/g, '"},'); // replace }, with containing quote
    bufferString = bufferString.replace("}]", '"}]'); // replace }] with containing quote
    bufferString = bufferString.substring(1); // remove " from the start of the string
    bufferString = bufferString.slice(0, -1); // remove " from the end of the string
    return JSON.parse(bufferString);
  }

  filterContact = (evt) => {
    this.setState({
      showAll: false,
      filter_contacts: [],
    });
    let contacts = this.state.contacts;
    let searchContact = evt.target.value;
    let filter_contacts = [];
    if (searchContact.length > 1) {
      contacts.map((contact) => {
        if (contact.name.toLowerCase().includes(searchContact.toLowerCase())) {
          filter_contacts.push(contact);
        }
      });

      this.setState({
        filter_contacts: filter_contacts,
      });
    }
  };

  openDefaulterPage = (email) => {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/followup_defaulter", {
        token: localStorage.getItem("supportToken"),
        email: email,
      })
      .then((response) => {
        if (response.data.success === "true") {
          console.log("follow-up:", response.data.data, "follow-up:::");
          let data = response.data.data;

          if (data.user) {
            self.setState({
              page: "defaulter",
              conversations: data.conversations,
              user: data.user,
            });
            window.$("#userPage").on("load", function () {
              window.$("#userPage").contents().find(".navbar").hide();
              window
                .$("#userPage")
                .contents()
                .find(".main-content-my")
                .removeClass();
            });
          } else {
            alert("user not found");
          }
        }
      });
  };

  addNotes = () => {
    this.setState({
      isSubmitting: true,
    });
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/followup_add_notes", {
        token: localStorage.getItem("supportToken"),
        email: this.state.user.email,
        notes: this.state.notes,
      })
      .then((response) => {
        if (response.data.success === "true") {
          let conversations = self.state.conversations;
          conversations.unshift({
            notes: this.state.notes,
            created_at: moment(),
          });
          self.setState({
            conversations: conversations,
            notes: "",
            isSubmitting: false,
          });
        }
      });
  };

  rescheduleCall = () => {
    if (moment(this.state.schedule_to) < moment()) {
      alert("Date must equal or greater than today.");
      return;
    }
    this.setState({
      isSubmitting: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/followup_reschedule_call", {
        token: localStorage.getItem("supportToken"),
        email: this.state.user.email,
        schedule_to: this.state.schedule_to,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            schedule_to: null,
            isSubmitting: false,
          });
          window.$("#reScheduleModal").modal("hide");
          alert(response.data.message);
          self.loadFollowupScheduled();
        }
      });
  };

  repayUserLoan = () => {
    if (this.state.amount < 50) {
      alert("Amount must be at least 50.");
      return;
    }
    this.setState({
      isSubmitting: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/repay_loan", {
        token: localStorage.getItem("supportToken"),
        email: this.state.user.email,
        amount: this.state.amount,
      })
      .then((response) => {
        self.setState({
          isSubmitting: false,
        });
        if (response.data.success === "true") {
          window.$("#repayUserLoanModal").modal("hide");
          alert(response.data.message);
        } else {
          alert(response.data.message);
        }
      });
  };

  markAsAttendedTo = () => {
    this.setState({
      isSubmitting: true,
    });
    let self = this;
    axios
      .post(
        ConstantVar.API_BASE_URL + "/support/followup_mark_as_attended_to",
        {
          token: localStorage.getItem("supportToken"),
          email: this.state.user.email,
        }
      )
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isSubmitting: false,
          });
          alert(response.data.message);
          self.loadFollowupScheduled();
        }
      });
  };

  schedules = () => {


    return (
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">Defaulter Call Scheduled</h2>

              <div className="form-inline mt-3">
                <label>Start Date:</label>
                <input
                  type="date"
                  className="ml-2 mr-2 form-control"
                  name="start_date"
                  value={this.state.start_date}
                  onChange={this.handleChange}
                />
                <label>End Date:</label>
                <input
                  type="date"
                  className="ml-2 mr-2 form-control"
                  name="end_date"
                  value={this.state.end_date}
                  onChange={this.handleChange}
                />
                <button
                  className="btn btn-primary"
                  onClick={this.loadFollowupScheduled}
                >
                  View
                </button>
              </div>

              <div className="form-inline" style={{ float: "right" }}>
                <input
                  type="email"
                  className="ml-2 mr-2 form-control float-right"
                  name="e_email"
                  placeholder="Enter email address"
                  value={this.state.e_email}
                  onChange={this.handleChange}
                />
                <button
                  className="btn btn-primary"
                  onClick={() => this.openDefaulterPage(this.state.e_email)}
                >
                  Go
                </button>
              </div>
            </div>
            <hr className="dash-hr" />
            {(() => {
              if (this.state.isLoading)
                return (
                  <div style={{ padding: 50 }} className="text-center">
                    <div>
                      <p>Loading...</p>
                    </div>
                  </div>
                );
              else
                return (
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush">
                      <thead>
                        <th></th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Scheduled To:</th>
                        <th>Attended To</th>
                      </thead>
                      <tbody>
                        {this.state.schedules.length > 0 ? (
                          this.state.schedules.map((sch, index) => {
                            return (
                              <tr
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.openDefaulterPage(sch.email)
                                }
                              >
                                <th scope="row dash-td" style={{ width: "1%" }}>
                                  <div className="media align-items-center">
                                    <div className="loan-img-bckg">
                                      <i className="fas fa-piggy-bank loan-img-color" />
                                    </div>
                                  </div>
                                </th>

                                <td className="" style={{ width: "5%" }}>
                                  <p className="">{sch.email}</p>
                                </td>

                                <td className="" style={{ width: "5%" }}>
                                  <p className="">
                                    {sch.fname} {sch.lname}
                                  </p>
                                </td>

                                <td className="" style={{ width: "5%" }}>
                                  <p className="">
                                    {" "}
                                    {moment(sch.scheduled_to).format("MMM Do")}
                                  </p>
                                </td>

                                <td className="" style={{ width: "5%" }}>
                                  <p className="">
                                    {sch.attended_to ? (
                                      <button className="btn btn-success btn-sm">
                                        <i class="fa fa-check mr-2"></i>
                                        ATTENDED
                                      </button>
                                    ) : (
                                      <button className="btn btn-warning btn-sm">
                                        Not Yet
                                      </button>
                                    )}
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>
                              <div className="text-center no-data full-width-data">
                                {/*<img className="no-trans-overview" src={transimg} />*/}
                                <p className="text-muted no-trans-text-o">
                                  No call schedule available!
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                );
            })()}
          </div>
        </div>
      </div>
    );
  };

  defaulterPage = () => {
    return (
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button
                  className="btn btn-sm"
                  onClick={() =>
                    this.setState({
                      page: "overview",
                      showContacts: false,
                      showAll: false,
                      filter_contacts: [],
                      contacts: [],
                    })
                  }
                >
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                {this.state.user.fname} {this.state.user.lname} (
                {this.state.user.phone})
              </h2>

              <div className=" mb-3 mt-3 w-100">
                <button
                  className="btn btn-primary btn-sm mb-2"
                  data-toggle="modal"
                  data-target="#reScheduleModal"
                >
                  Reschedule Call
                </button>

                <button
                  className="btn btn-primary btn-sm mb-2"
                  disabled={this.state.isSubmitting}
                  onClick={this.markAsAttendedTo}
                >
                  Mark as Attended To
                </button>

                <button
                  className="btn btn-primary btn-sm mb-2"
                  data-toggle="modal"
                  data-target="#repayUserLoanModal"
                >
                  Repay User Loan
                </button>

                {!this.state.showContacts ? (
                  <button
                    className="btn btn-success  float-right"
                    disabled={this.state.isSubmitting}
                    onClick={() => {
                      this.setState(
                        {
                          showContacts: true,
                        },
                        this.loadUserContacts()
                      );
                    }}
                  >
                    Show user contacts
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <hr className="dash-hr" />

            <div className="row">
              <div className="col-md-5 mb-4">
                <div className="container">
                  <div className="form-group mt-3">
                    <label>Add note</label>
                    <textarea
                      className="form-control"
                      placeholder="add notes"
                      name="notes"
                      onChange={this.handleChange}
                      value={this.state.notes}
                    ></textarea>
                    <button
                      className="mt-2 btn btn-sm btn-primary"
                      onClick={this.addNotes}
                      disabled={this.state.isSubmitting}
                    >
                      ADD
                    </button>
                  </div>

                  <hr />
                  <div>
                    <h4 className="mt-2 mb-3">Notes</h4>

                    {this.state.conversations.length > 0 ? (
                      this.state.conversations.map((con, index) => {
                        return (
                          <div>
                            <blockquote>
                              {con.notes}
                              <div className="d-flex py-2 flex-column text-sm text-muted">
                                 <small>
                                  
                                  {moment(con.created_at).format(
                                  "MMM Do YYYY, h:mm:ss a"
                                )}
                                  </small>
                                <small >By <a className="text-primary" href={`/user/${con.support_email}`} >{con.support_email}</a></small>
                              </div>
                            </blockquote>
                            <hr />
                          </div>
                        );
                      })
                    ) : (
                      <label>No conversations yet</label>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                {this.state.showContacts ? (
                  this.contactsPage()
                ) : (
                  <iframe
                    id="userPage"
                    width="100%"
                    height="600px"
                    src={
                      "https://support.gethometown.app" +
                      "/user/" +
                      this.state.user.email
                    }
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  contactsPage = () => {
    return (
      <div>
        <h2 className="mb-0">
          <a
            className="btn btn-sm"
            onClick={() =>
              this.setState({
                showContacts: false,
                filter_contacts: [],
              })
            }
          >
            <i className="fa fa-angle-left fa-3x"></i>
          </a>
          User Contacts
        </h2>
        <div className="row pl-4 pr-4 mb-3">
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Search by name"
              className="form-control  w-100"
              onChange={this.filterContact}
            />
          </div>

          <div className="col-md-4 text-right">
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                this.setState({
                  showAll: !this.state.showAll,
                });
              }}
            >
              {!this.state.showAll ? "Show All" : "Hide All"}
            </button>
          </div>
        </div>
        <div
          className="p-5 table-responsive bg-dark text-white"
          style={{
            height: "500px",
            overflow: "auto",
          }}
        >
          <table className="table">
            <thead>
              <th>Name</th>
              <th>Number</th>
            </thead>
            <tbody>
              {this.state.showAll
                ? this.state.contacts.map((contact, index) => {
                    return (
                      <tr>
                        <td>{contact.name}</td>
                        <td>{contact.number}</td>
                      </tr>
                    );
                  })
                : this.state.filter_contacts.map((contact, index) => {
                    return (
                      <tr>
                        <td>{contact.name}</td>
                        <td>{contact.number}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div style={{ overflowY: "scroll" }}>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            {this.state.page === "overview"
              ? this.schedules()
              : this.defaulterPage()}
          </div>
        </div>

        <div
          class="modal fade"
          id="reScheduleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="reScheduleModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="reScheduleModalLabel">
                  Reschedule Call
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label>ReSchedule To:</label>
                <div class="form-group">
                  <input
                    class="form-control form-control-sm"
                    type="date"
                    name="schedule_to"
                    value={this.state.schedule_to}
                    onChange={this.handleChange}
                  />
                </div>

                <div class="form-group">
                  <button
                    disabled={this.state.isSubmitting}
                    className="btn btn-primary"
                    onClick={this.rescheduleCall}
                  >
                    Reschedule Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="repayUserLoanModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="repayUserLoanModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="repayUserLoanModalLabel">
                  Repay User Loan
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label>Amount:</label>
                <div class="form-group">
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleChange}
                  />
                </div>

                <div class="form-group">
                  <button
                    disabled={this.state.isSubmitting}
                    className="btn btn-primary"
                    onClick={this.repayUserLoan}
                  >
                    Repay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default loadFollowupSchedules;
