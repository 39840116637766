import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class VerifyBVN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      account_name: "",
      bvn_name: "",
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleBankChange = (evt) => {
    this.setState({ bank: evt.target.value });
  };

  verifyBVN = (evt) => {
    evt.preventDefault();

    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/verify_bvn", {
        token: localStorage.getItem("supportToken"),
        bvn: this.state.bvn,
        phone: this.state.phone,
        pin: this.state.pin,
        dob: this.state.dob,
        otp: this.props.otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            bvn: "",
            phone: "",
            account_name: "",
            bvn_name: "",
            pin: "",
            dob: "",
          });
        } else {
          self.setState({
            isLoading: false,
          });
        }
        alert(response.data.message);
      });
  };

  getAccountName = (phone) => {
    this.setState({
      account_name: "",
    });
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/get_hometown_account_name", {
        token: localStorage.getItem("supportToken"),
        phone: phone,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            account_name: response.data.data,
          });
        } else {
          self.setState({
            account_name: "Not Found",
          });
        }
      });
  };

  getBVNName = (bvn) => {
    this.setState({
      bvn_name: "",
    });
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/get_bvn_name", {
        token: localStorage.getItem("supportToken"),
        bvn: bvn,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            bvn_name: response.data.data,
          });
        } else {
          self.setState({
            bvn_name: "Not Found",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Verify BVN</h2>
          </div>

          <form className="col-md-7" onSubmit={this.verifyBVN}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Phone Number</label>
                  <input
                    required
                    name="phone"
                    className="form-control"
                    placeholder="Enter User Phone"
                    value={this.state.phone}
                    onChange={(evt) => {
                      let phone = evt.target.value;
                      if (phone.length === 11) {
                        // get user full name
                        this.getAccountName(phone);
                      }
                      this.handleChange(evt);
                    }}
                    type="text"
                  />
                  <small className="text-info">{this.state.account_name}</small>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>BVN</label>
                  <input
                    required
                    name="bvn"
                    className="form-control"
                    placeholder="Enter BVN"
                    value={this.state.bvn}
                    onChange={(evt) => {
                      let bvn = evt.target.value;
                      if (bvn.length === 11) {
                        // get user full name
                        this.getBVNName(bvn);
                      }
                      this.handleChange(evt);
                    }}
                    type="text"
                  />
                  <small className="text-info">{this.state.bvn_name}</small>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {" "}
                <div className="form-group ">
                  <label>Date of Birth</label>
                  <input
                    required
                    name="dob"
                    className="form-control"
                    placeholder="Date of Birth"
                    value={this.state.dob}
                    onChange={this.handleChange}
                    type="date"
                  />
                </div>
              </div>
              <div className="col-md-6">
                {" "}
                <div className="form-group ">
                  <label>Your PIN</label>
                  <input
                    required
                    name="pin"
                    className="form-control"
                    placeholder="PIN"
                    value={this.state.pin}
                    onChange={this.handleChange}
                    type="password"
                  />
                </div>
              </div>
            </div>

            <button className="btn btn-success" disabled={this.state.isLoading}>
              Continue
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default VerifyBVN;
