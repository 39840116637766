import React, { Component } from 'react';
import Navbar from '../../components/navbar';
import Sidebar from '../../components/sidebar';
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

export default class index extends Component {
    constructor(props){
        super(props);
        this.state = {
            isPin:false,
            new_password:"",
            confirm_password:"",
            new_pin :'',
            confirm_pin :''
        };
    }
    

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    activatePassword = () => {
        this.setState({
            new_pin :'',
            confirm_pin :'',
            isPin:false
        })
    };

     activatePin = () => {
        this.setState({
            new_password:"",
            confirm_password:"",
            isPin:true
        })
    };


    handleReset = () => {
        // e.preventDefault()
        axios
            .post(ConstantVar.API_BASE_URL + "/support/resets/", {
                token: localStorage.getItem("supportToken"),
                new_password: this.state.new_password,
                old_password: this.state.old_password,
                confirm_password: this.state.confirm_password,
                old_pin: this.state.old_pin,
                new_pin: this.state.new_pin,
                confirm_pin: this.state.confirm_pin,
                type :this.state.isPin ? "PIN" : "PASSWORD"
            }).then(res => {
                if(res.data.success == "true"){
                    this.setState(
                        {                
                            old_pin :'',
                            new_pin :'',
                            confirm_pin :'',
                            new_password:"",
                            old_password:"",
                            confirm_password:""
                        }
                    )
                    
                }
                    alert(res.data.message)
                
            })
            .catch((error) => {
                alert(error.message)
            }
            ) 
    }

   
  render() {
    return (
        <div >
            <Sidebar />
            <div className="main-content main-content-my">
                <Navbar />
                <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
                    <div className="container-fluid">
                        <div className="header-body"></div>
                    </div>
                </div>
                <div className="container-fluid mt--7">
                    <div className="row">
                        <div className="col">
                            <div className="card shadow">
                                <div className="card-header border-0">
                                    <h2 className="mb-0 d-inline">Password / Pin Change</h2>
                                </div>
                                 <ul className="nav nav-pills  p-3">
                                    <li className="nav-item">
                                        <a
                                            className="nav-link "
                                            href="#"
                                            onClick={ this.activatePassword}
                                        >
                                            {" "}
                                            Password
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link "
                                            href="#"
                                            onClick={this.activatePin}
                                        >
                                            {" "}
                                            Pin
                                        </a>
                                    </li>
                                </ul>
                 
                                <hr />
                            </div>
                            <div  className='mx-auto mb-5 d-flex-column  justify-content-center align-aitems-center w-50'>
                                {
                                    !this.state.isPin 
                                        ? 
                                    <>
                                        
                                        <div className="row">
                                            <label>Old Password:</label>
                                            <input
                                                type="password"
                                                className="ml-2 mr-2 form-control"
                                                name="old_password"
                                                placeholder='Enter old password'
                                                value={this.state.old_password}
                                                onChange={this.handleChange}
                                                />
                                        </div>
                                        <div className="row">
                                            <label>New Password:</label>
                                            <input
                                                type="password"
                                                className="ml-2 mr-2 form-control"
                                                name="new_password"
                                                placeholder='Enter new password'
                                                value={this.state.new_password}
                                                onChange={this.handleChange}
                                                />
                                        </div>
                                        <div className="row">
                                            <label>Confirm Password:</label>
                                            <input
                                                type="password"
                                                className="ml-2 mr-2 form-control"
                                                name="confirm_password"
                                                placeholder='Re-enter new password'
                                                value={this.state.confirm_password}
                                                onChange={this.handleChange}
                                            />
                                        </div>

                                        
                                    </>
                                    
                                        :
                                        
                                    <>
                                        <div className="row">
                                            <label>Old Pin:</label>
                                            <input
                                                type="text"
                                                className="ml-2 mr-2 form-control"
                                                name="old_pin"
                                                placeholder='Enter old Pin'
                                                value={this.state.old_pin}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="row">
                                            <label>New Pin:</label>
                                            <input
                                                type="text"
                                                className="ml-2 mr-2 form-control"
                                                name="new_pin"
                                                placeholder='Enter new Pin'
                                                value={this.state.new_pin}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="row">
                                                
                                            <label>Confirm Pin:</label>
                                            <input
                                                type="text"
                                                className="ml-2 mr-2 form-control"
                                                name="confirm_pin"
                                                placeholder='Re-enter new Pin'
                                                value={this.state.confirm_pin}
                                                onChange={this.handleChange}
                                            />
                                        </div>

                                    </>
                                }
                                <div className="my-2">
                                    <button
                                        // disabled={this.isLoading}
                                        onClick={this.handleReset}
                                        className="btn btn-success"
                                        style={{backgroundColor:"#96C049"}}
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  

        </div>
     
    );
  }
}
