import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class CardCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      reference_id: "",
    };
  }

  handleChange = (evt) => {
    this.setState({ reference_id: evt.target.value });
  };

  handleCollection = () => {
    //validate
    let session_id = this.state.reference_id;
    let confirm = window.confirm("Are you sure this is a valid referenceID? ")
  if (!confirm) return

    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/get_raven/card", {
        token: localStorage.getItem("supportToken"),
        reference_id: session_id,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
          });
          alert(response.data.message);

        } else {
          self.setState({
            isLoading: false,
          });
          alert(response.data.message);
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Customer Card Deposit</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="reference_id"
                className="form-control"
                placeholder="Enter valid reference_id "
                value={this.state.reference_id}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button
              onClick={() => this.handleCollection()}
              className="btn btn-success"
              disabled={this.state.isLoading}
            >
                { this.state.isLoading ?"Please wait....":"Process"
                }           
             </button>

          </div>

          <hr />

          
        </div>
      </div>
    );
  }
}

export default CardCollections;
