import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import Bank from "./banks";

class VerifyAcctNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      account_name: "",
      account_number: "",
      bank: "",
      bank_account_name: "",
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleBankChange = (evt) => {
    this.setState({ bank: evt.target.value });
  };

  verifyAcctNo = (evt) => {
    evt.preventDefault();

    if (
      this.state.bank_account_name === "" ||
      this.state.bank_account_name === "Not Found"
    ) {
      alert("Please provide bank details");
      return;
    }
    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/verify_acct_no", {
        token: localStorage.getItem("supportToken"),
        bank: window.$("#nigeriaBanksList option:selected").text(),
        account_number: this.state.account_number,
        phone: this.state.phone,
        pin: this.state.pin,
        account_name: this.state.bank_account_name,
        otp: this.props.otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            account_number: "",
            phone: "",
            pin: "",
            bank_account_name: "",
            account_name: "",
          });
          window.$("#nigeriaBanksList").val("");
        } else {
          self.setState({
            isLoading: false,
          });
        }
        alert(response.data.message);
      });
  };

  getAccountName = (phone) => {
    this.setState({
      account_name: "",
    });
    let self = this;
    axios
      .post(
        ConstantVar.API_BASE_URL + "/support/get_hometown_account_details",
        {
          token: localStorage.getItem("supportToken"),
          phone: phone,
        }
      )
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            account_name: response.data.account_name,
          });
        } else {
          self.setState({
            account_name: "Not Found",
          });
        }
      });
  };

  getBankAccountName = (account_number) => {
    this.setState({
      bank_account_name: "",
    });
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/get_bank_account_name", {
        token: localStorage.getItem("supportToken"),
        bank: this.state.bank,
        account_number: account_number,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            bank_account_name: response.data.data,
          });
        } else {
          self.setState({
            bank_account_name: "Not Found",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Update / Verify Account No.</h2>
          </div>

          <form className="col-md-7" onSubmit={this.verifyAcctNo}>
            <div className="row">
              <div className="col-12">
                <div className="form-group ">
                  <label>Phone Number</label>
                  <input
                    required
                    name="phone"
                    className="form-control"
                    placeholder="Enter User Phone"
                    value={this.state.phone}
                    onChange={(evt) => {
                      let phone = evt.target.value;
                      if (phone.length === 11) {
                        // get user full name
                        this.getAccountName(phone);
                      }
                      this.handleChange(evt);
                    }}
                    type="text"
                  />
                  <small className="text-info">{this.state.account_name}</small>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label>Bank</label>
                <Bank handleChange={this.handleBankChange} />
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Account Number</label>

                  <input
                    required
                    name="account_number"
                    className="form-control"
                    placeholder="Enter Account Number"
                    value={this.state.account_number}
                    onChange={(evt) => {
                      let account_number = evt.target.value;
                      if (account_number.length === 10) {
                        // get user full name
                        this.getBankAccountName(account_number);
                      }
                      this.handleChange(evt);
                    }}
                    type="text"
                  />
                  <small className="text-info">
                    {this.state.bank_account_name}
                  </small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {" "}
                <div className="form-group ">
                  <label>Your PIN</label>
                  <input
                    required
                    name="pin"
                    className="form-control"
                    placeholder="PIN"
                    value={this.state.pin}
                    onChange={this.handleChange}
                    type="password"
                  />
                </div>
              </div>
            </div>

            <button className="btn btn-success" disabled={this.state.isLoading}>
              Continue
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default VerifyAcctNo;
