import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class BlockLoanOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      email_provider: "",
      state: "",
      lgovt: "",
      address: "",
      namelike: "",
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  blockLoanOffer = () => {
    let email = this.state.email,
      email_provider = this.state.email_provider,
      state = this.state.state,
      lgovt = this.state.lgovt,
      address = this.state.address,
      namelike = this.state.namelike;

    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/update_block_loan_offer", {
        token: localStorage.getItem("supportToken"),
        email: email,
        email_provider: email_provider,
        state: state,
        lgovt: lgovt,
        address: address,
        namelike: namelike,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
          });
          alert("Successful");
        } else {
          self.setState({
            isLoading: false,
          });
          alert(response.data.message);
        }
      });
  };

  componentDidMount() {
    let self = this;
    axios
      .get(
        ConstantVar.API_BASE_URL +
          "/support/block_loan_offers_data?token=" +
          localStorage.getItem("supportToken")
      )
      .then((response) => {
        if (response.data.success === "true") {
          let blockLoanOfferData = response.data.data;

          self.setState({
            isLoading: false,
            email: blockLoanOfferData.email,
            email_provider: blockLoanOfferData.email_provider,
            state: blockLoanOfferData.state,
            lgovt: blockLoanOfferData.lgovt,
            namelike: blockLoanOfferData.namelike,
          });
        } else {
          self.setState({
            isLoading: false,
          });
          alert("Reload again");
        }
      });
  }

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Block Loan Offers</h2>
          </div>

          <div className=" p-3 col-md-7">
            <div className="form-group mb-3">
              <label>Email Address</label>
              <textarea
                required
                name="email"
                className="form-control"
                placeholder=""
                value={this.state.email}
                onChange={this.handleChange}
                rows="3"
              />
            </div>

            <div className="form-group mb-3">
              <label>Email Provider</label>
              <textarea
                required
                name="email_provider"
                className="form-control"
                placeholder=""
                value={this.state.email_provider}
                onChange={this.handleChange}
                rows="3"
              />
            </div>

            <div className="form-group mb-3">
              <label>State</label>
              <textarea
                required
                name="state"
                className="form-control"
                placeholder=""
                value={this.state.state}
                onChange={this.handleChange}
                rows="3"
              />
            </div>

            <div className="form-group mb-3">
              <label>Local Government</label>
              <textarea
                required
                name="lgovt"
                className="form-control"
                placeholder=""
                value={this.state.lgovt}
                onChange={this.handleChange}
                rows="3"
              />
            </div>

            <div className="form-group mb-3">
              <label>Name Likes</label>
              <textarea
                required
                name="namelike"
                className="form-control"
                placeholder=""
                value={this.state.namelike}
                onChange={this.handleChange}
                rows="3"
              />
            </div>

            <button onClick={this.blockLoanOffer} className="btn btn-success">
              Update
            </button>
          </div>

          {this.state.isLoading ? (
            <div class="text-center">
              <label>Please wait....</label>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default BlockLoanOffer;
