import React, { Component } from "react";

import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class BVN_Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      bvn: "",
      user_bvn: [],
      user_info: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ bvn: evt.target.value });
  };

  lookupBVN = () => {
    //bind this to self
    if (this.state.bvn.length < 11) {
      alert("Enter valid bvn");
      return false;
    }
    this.setState({
      isLoading: true,
      // user_bvn: [],
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/search_bvn", {
        token: localStorage.getItem("supportToken"),
        bvn: this.state.bvn,
      })
      .then((response) => {
        if (response.data.success === "true") {
          console.log("response.data",response.data)
          self.setState({
            user_bvn: response.data.data,
            isLoading: false,
          });
        } else {
          self.setState({
            isLoading: false,
          });
          alert(response.data.message);
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">BVN Search</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="bvn"
                className="form-control"
                placeholder="Enter BVN"
                value={this.state.bvn}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button onClick={this.lookupBVN} className="btn btn-success">
              search
            </button>
          </div>

          {this.state.isLoading ? (
            <div className="text-center">
              <label>Loading....</label>
            </div>
          ) : (
            ""
          )}

            <table className="table p-4">
              <thead>
                <tr className="p-4">
                  <th scope="col">BVN</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                {this.state.user_bvn.length > 0 ?
                this.state.user_bvn.map((item,index) => 
                  <tr key={index}>
                    <td>{item.bvn}</td>
                    <td>{item.email}</td>
                  </tr>
                  )
                  :
                  <tr>
                    <td>No BVN details found</td>
                  </tr>
                }
              </tbody>
            </table>
          
        </div>
      </div>
    );
  }
}

export default BVN_Search;