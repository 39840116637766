import React, { Component } from "react";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class IdentificationUploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      isLoading: true,
      page: "overview",
      user: {},
      tab: "dp",
      activity:{},
      id_requests: [],
      disable:false,
      foundTab:"",
      email:"",
    };

    this.activityCheck= this.activityCheck.bind(this)
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount() {
    let searchText = this.props.location.search;
    if (searchText !== "") {
      let email = searchText.split("?email=")[1];
      this.setState(
        {
          email,
        },
        () => {
          this.openUpload();
        }
      );
    } else {
      // this.requests();
    }
  }

  activityCheck = (tab)=>{

    this.setState({
      tab
    },()=>{

      this.handleActivity(tab);
    });
  }

  openUpload = () => {
    let self = this;
    let token = localStorage.getItem("supportToken");
    let email = this.state.email.trim();
    this.setState({disable:true})
    axios
      .get(
        ConstantVar.API_BASE_URL +
          `/support/identification/user?token=${token}&email=${email}`
      )
      .then((response) => {
        if (response.data.success === "true"){
          self.setState({...this.state, user: response.data.data, page: "account",disable:false });
        }
          else alert(response.data.message);
      });
  };

  handleActivity = (tab) => {
    let self = this;
    let token = localStorage.getItem("supportToken");
    let email = this.state.email;
    axios
      .get(ConstantVar.API_BASE_URL + `/support/identification_activity?token=${token}&email=${email}&action=${tab}`)
      .then((response) => {
        if (response.data.success === "true"){
          self.setState({ activity: response.data.data,
            foundTab:response.data?.data?.slug?.split("-verification")[0],

           });
        }
        else alert(response.data.message);
      });
  }

  requests = () => {
    let self = this;
    let token = localStorage.getItem("supportToken");
    axios
      .get(ConstantVar.API_BASE_URL + `/support/identification?token=${token}`)
      .then((response) => {
        if (response.data.success === "true")
          self.setState({ user: response.data.data, page: "account" });
        else alert(response.data.message);
      });
  };

  confirm = (action) => {
    let self = this;
    if (action === "reject" && this.state.reason.length < 3) {
      alert("please provide the reason for rejection");
      return;
    }
    var status = window.confirm("Are you sure you want to perform this operation?");
    if (status) {
      axios
        .post(ConstantVar.API_BASE_URL + "/support/identification/verify", {
          token: localStorage.getItem("supportToken"),
          email: this.state.user.email,
          type: this.state.tab,
          action: action,
          reason: this.state.reason,
        }).then((response) => {
          alert(response.data.message);
          if (response.data.success === "true") {
            self.setState({
              page: "overview",
              tab: "id-card",
              reason: "",
            });
            self.openUpload();
          }
        });
    }
  };

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-loans pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="card shadow">
              <div className="card-header border-0">
                <h2 className="mb-0">
                  Identification Uploads
                  <div className="float-right form-inline">
                    <input
                      type="text"
                      style={{ width: "300px" }}
                      className="form-control"
                      // value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Email "
                      name="email"
                    />
                    <button  
                      className="btn btn-primary" 
                      onClick={this.openUpload}
                      disabled={!this.state.email||this.state.disable}
                    >
                      Open
                    </button>
                  </div>
                </h2>
              </div>

              <hr className="dash-hr" />

              {this.state.user?.email && this.state.page === "account" && (
                <div>
                  <div className=" p-3 ">
                    <button
                      className={this.state.tab === "id-card" ? "btn btn-info" : "btn"}
                      style={{
                        width: "350px",
                      }}
                      // onClick={() => {
                      //   this.setState({
                      //     tab: "id-card",
                      //   });
                      //   this.handleActivity();
                      // }}
                      onClick={()=>this.activityCheck("id-card")}

                    >
                      ID Card
                    </button>
                    <button
                      className={this.state.tab === "dp" ? "btn btn-info" : "btn"}
                      style={{
                        width: "350px",
                      }}
                      // onClick={() => {
                      //   this.setState({
                      //     tab: "dp",
                      //   });
                      //   this.handleActivity();

                      // }}
                      onClick={()=>this.activityCheck("dp")}

                    >
                      Profile Pics
                    </button>
                    <button
                      className={
                        this.state.tab === "utility-bill" ? "btn btn-info" : "btn"
                      }
                      style={{
                        width: "350px",
                      }}
                      onClick={()=>this.activityCheck("utility-bill")}
                    >
                      Utility Bill
                    </button>
                    <button
                      className="btn"
                      style={{
                        width: "350px",
                      }}
                    >
                      <a target="_blank" href={`/user/${this.state.user.email}`}>
                        Profile
                      </a>
                    </button>
                  </div>
                      {/* activiy indicator section */}
            
                  {/* {this.state.tab == "id-card" && (this.state.activity?.slug== "id-card-verification-reject" ||this.state.activity?.slug=="id-card-verification-approve")&&
                    <div disabled className={this.state.activity?.slug == "id-card-verification-reject"? "bg-danger rounded w-50 text-white m-3 p-3": " m-3 text-white rounded w-50 p-3 bg-success"} >
                      {this.state.activity?.slug == "id-card-verification-reject"?"Rejected by :": "Approved by :"} <h4>{this.state.activity?.support_by}</h4>
                    </div>
                  } */}

                  {/* start */}

                  {/* {["id-card", "utility-bill"].includes(this.state.tab) && */}
                  {this.state.tab == (this.state.foundTab) &&
  ["id-card-verification-reject", "id-card-verification-approve", "utility-bill-verification-approve"].includes(this.state.activity?.slug) && (
    <div
      disabled
      className={
        ["id-card-verification-reject", "utility-bill-verification-reject"].includes(this.state.activity?.slug)
          ? "bg-danger rounded w-50 text-white m-3 p-3"
          : "m-3 text-white rounded w-50 p-3 bg-success"
      }
    >
      {["id-card-verification-reject", "utility-bill-verification-reject"].includes(this.state.activity?.slug)
        ? "Rejected by :"
        : "Approved by :"}{" "}
      <h4>{this.state.activity?.support_by}</h4>
    </div>
  )
}

{/* end */}

                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="p-5 mt-4 text-center">
                          {(this.state.tab === "id-card" &&
                            this.state.user.id_card_uploaded_at &&
                            this.state.user.id_card_verified_at === null) ||
                          (this.state.tab === "dp" &&
                            this.state.user.dp_uploaded_at &&
                            this.state.user.dp_verified_at === null) ||
                          (this.state.tab === "utility-bill" &&
                            this.state.user.utility_bill_uploaded_at &&
                            this.state.user.utility_bill_verified_at === null) ? (
                            <div>
                              {this.state.tab === "id-card" && (
                                <div className="p-3">
                                  <div>
                                    ID Card Type: <b>{this.state.user.id_card_type}</b>
                                  </div>
                                  <div>
                                    ID Card Number:{" "}
                                    <b>{this.state.user.id_card_number}</b>
                                  </div>
                                </div>
                              )}

                              <button
                                className="btn btn-success mr-4"
                                onClick={() => this.confirm("approve")}
                              >
                                APPROVE
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => this.confirm("reject")}
                              >
                                REJECT
                              </button>
                              <div className="form-group  mt-3 text-center">
                                <textarea
                                  rows="3"
                                  className="form-control"
                                  name="reason"
                                  placeholder="Reason for rejection here"
                                  value={this.state.reason}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          ) : //

                          // SHOW Button to Request identification upload

                          (this.state.tab === "id-card" &&
                              this.state.user.id_card_uploaded_at === null &&
                              this.state.user.id_card_verified_at === null) ||
                            (this.state.tab === "utility-bill" &&
                              this.state.user.utility_bill_uploaded_at === null &&
                              this.state.user.utility_bill_verified_at === null) ? (
                            <button
                              className="btn btn-block btn-danger mr-4"
                              onClick={() => this.confirm("request")}
                            >
                              {this.state.tab === "id-card"
                                ? " REQUEST FOR ID CARD"
                                : this.state.tab === "utility-bill"
                                ? " REQUEST FOR UTILITY BILL"
                                : ""}
                            </button>
                          ) : //
                          //
                          //

                          // SHOW Button to reset identification upload

                          (this.state.tab === "id-card" &&
                              this.state.user.id_card_uploaded_at &&
                              this.state.user.id_card_verified_at) ||
                            (this.state.tab === "dp" &&
                              (this.state.user.dp_uploaded_at ||
                                this.state.user.dp_verified_at)) ||
                            (this.state.tab === "utility-bill" &&
                              this.state.user.utility_bill_uploaded_at &&
                              this.state.user.utility_bill_verified_at) ? (
                            <button
                              className="btn btn-block btn-warning mr-4"
                              onClick={() => this.confirm("reset")}
                            >
                              {this.state.tab === "id-card"
                                ? "RESET ID CARD UPLOAD & VERIFICATION"
                                : this.state.tab === "dp"
                                ? "RESET PROFILE PICS UPLOAD & VERIFICATION"
                                : this.state.tab === "utility-bill"
                                ? "RESET UTILITY BILL UPLOAD & VERIFICATION"
                                : ""}{" "}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-md-6 bg-dark text-white p-4">
                        {this.state.tab === "id-card" &&
                        this.state.user.id_card_uploaded_at ? (
                          <div>
                            <h5 className="text-white mb-3 ">Front Page</h5>
                            <img
                              style={{
                                maxWidth: "100%",
                              }}
                              src={`${ConstantVar.API_BASE_URL2}/static/id_cards/${this.state.user.phone}/frontpage.png`}
                            />
                            <h5 className="text-white mt-3">Back Page</h5>
                            <img
                              style={{
                                maxWidth: "100%",
                              }}
                              src={`${ConstantVar.API_BASE_URL2}/static/id_cards/${this.state.user.phone}/backpage.png`}
                            />
                          </div>
                        ) : this.state.tab === "dp" &&
                          (this.state.user.dp_uploaded_at ||
                            this.state.user.dp_verified_at) ? (
                          <div>
                            <h5 className="text-white mb-3 ">Profile pics</h5>
                            <img
                              style={{
                                maxWidth: "100%",
                              }}
                              src={`${ConstantVar.API_BASE_URL2}/static/profile_pics/${this.state.user.phone}/dp.png`}
                            />
                          </div>
                        ) : this.state.tab === "utility-bill" &&
                          this.state.user.utility_bill_uploaded_at ? (
                          <div>
                            <h5 className="text-white mb-3 ">Utility Bill</h5>
                            <img
                              style={{
                                maxWidth: "100%",
                              }}
                              src={`${ConstantVar.API_BASE_URL2}/static/utility_bills/${this.state.user.phone}/docs.png`}
                            />
                          </div>
                        ) : (
                          <b>No upload yet!</b>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* {this.state.page === "overview" && (
                <div className=" p-4">
                  <h3>Requests</h3>

                  <div className="mt-4">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Email Address</th>
                          <th scope="col">Phone</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Uploaded At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.id_requests.map((request) => (
                          <tr>
                            <td>
                              <a
                                href="#"
                                onClick={() => {
                                  this.setState({ user: request, page: "account" });
                                }}
                              >
                                {request.email}
                              </a>
                            </td>
                            <td>{request.phone}</td>
                            <td>{request.fname}</td>
                            <td>{request.lname}</td>
                            <td>{request.uploaded_at}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }


}

export default IdentificationUploads;
