import React, { Component } from "react";

import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class BVNSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      bvn: "",
      user_bvn: null,
      user_info: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ bvn: evt.target.value });
  };

  lookupBVN = () => {
    //bind this to self
    if (this.state.bvn.length < 11) {
      alert("Enter valid bvn");
      return false;
    }
    this.setState({
      isLoading: true,
      user_bvn: null,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/look_up_bvn", {
        token: localStorage.getItem("supportToken"),
        bvn: this.state.bvn,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            user_bvn: response.data.data.bvn,
            user_info: response.data.data.info,
            isLoading: false,
          });
        } else {
          self.setState({
            isLoading: false,
          });
          alert(response.data.message);
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">BVN LookUp</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="bvn"
                className="form-control"
                placeholder="Enter BVN"
                value={this.state.bvn}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button onClick={this.lookupBVN} className="btn btn-success">
              lookup
            </button>
          </div>

          {this.state.isLoading ? (
            <div class="text-center">
              <label>Loading....</label>
            </div>
          ) : (
            ""
          )}

          {this.state.user_bvn !== null ? (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">BVN</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Middle Name</th>
                  <th scope="col">DOB</th>
                  <th scope="col">Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.user_bvn.bvn}</td>
                  <td>{this.state.user_bvn.first_name ||this.state.user_bvn.firstname }</td>
                  <td>{this.state.user_bvn.last_name || this.state.user_bvn.lastname}</td>
                  <td>{this.state.user_bvn.middle_name ||this.state.user_bvn.middlename}</td>
                  <td>
                    {
                      this.state.user_bvn.date_of_birth
                      || this.state.user_bvn.birthdate
                      || this.state.user_bvn.dob}
                  </td>
                  <td>
                    {
                    this.state.user_bvn.phone_number
                    || this.state.user_bvn.phone
                      || this.state.user_bvn.mobile}
                  </td>
                </tr>

                {this.state.user_info !== null ? (
                  <tr>
                    <td colSpan="7" align="center">
                      Already used by
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {this.state.user_info !== null ? (
                  <tr>
                    <td> {this.state.user_info.bvn}</td>
                    <td>{this.state.user_info.fname}</td>
                    <td>{this.state.user_info.lname}</td>
                    <td>{this.state.user_info.email}</td>
                    <td>{this.state.user_info.dob}</td>
                    <td>{this.state.user_info.phone}</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default BVNSearch;