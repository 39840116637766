import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";

class Akawo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      totalOpenAkawo: null,
      totalLockupAkawo: null,
      totalOpenAkawoReturns: null,
      totalLockupAkawoReturns: null,
      start_date: null,
      end_date: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount() {
    let self = this;
    axios
      .get(
        ConstantVar.API_BASE_URL +
          "/support/akawo_report?token=" +
          localStorage.getItem("supportToken")
      )
      .then((response) => {
        let data = response.data.data;
        self.setState({
          totalOpenAkawo: data.open_akawo,
          totalLockupAkawo: data.lockup_akawo,
          totalOpenAkawoReturns: data.open_akawo_returns,
          totalLockupAkawoReturns: data.lockup_akawo_returns,
        });
      });
  }

  getLoanReportRange = (evt) => {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/loan_report_range", {
        token: localStorage.getItem("supportToken"),
        start_date: self.state.start_date,
        end_date: self.state.end_date,
      })
      .then((response) => {
        let data = response.data.data;
        self.setState({
          isLoading: false,
          noOfLoans_range: data.num,
          sumOfLoans_range: data.sum,
        });
      });
  };

  render() {
    return (
      <div className="container">
        <div className="card shadow col-md-6 p-3 mx-auto">
          <div className="row p-2">
            <h4 className="col-md-9">Akawo Investment</h4>
            {/*<a href="#" className="col-md-3 text-right ">
              Filter
          </a>  */}
          </div>

          <table className="p-2">
            <thead>
              <th>#</th>
              <th>Invested Amount</th>
              <th>Total Returns</th>
            </thead>
            <tbody>
              <tr>
                <th className="p-3">Open Akawo</th>
                <td className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.totalOpenAkawo}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
                <td className="p-3">
                  <NumberFormat
                    value={this.state.totalOpenAkawoReturns}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-3">Lockup Akawo</th>
                <td className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.totalLockupAkawo}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
                <td className="p-3">
                  <NumberFormat
                    value={this.state.totalLockupAkawoReturns}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-3">Overall</th>
                <td className="p-3">
                  {" "}
                  <NumberFormat
                    value={
                      parseInt(this.state.totalOpenAkawo) +
                      parseInt(this.state.totalLockupAkawo)
                    }
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
                <td className="p-3">
                  <NumberFormat
                    value={
                      parseInt(this.state.totalOpenAkawoReturns) +
                      parseInt(this.state.totalLockupAkawoReturns)
                    }
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Akawo;
