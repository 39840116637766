import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/support/compiled_data")
      // .get(ConstantVar.API_BASE_URL + "/support/newcompiled_data")
      .then((response) => {
        if (response.data.success === "true") {
          var totalAvailableBal = parseFloat(
            response.data.total_available_bal
          ).toFixed(0);
          self.setState({
            users: response.data.total_users,
            month_users: response.data.month_users,
            total_trxs: response.data.total_trxs,
            month_trxs: response.data.month_trxs,
            total_loan_amount: response.data.total_loan_amount,
            total_loan_return: response.data.total_loan_return,
            total_loan_balance: response.data.total_loan_balance,
            total_akawo_current: response.data.total_akawo_current,
            total_akawo_invested: response.data.total_akawo_invested,
            total_available_bal: totalAvailableBal,
            total_open_tickets: response.data.total_open_tickets,
            total_unreplied_tickets: response.data.total_unreplied_tickets,
            total_loan_amount_paid: response.data.total_loan_amount_paid
          });
        }
      });
  }

  render() {

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row">
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total number of users
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                              <i className="far fa-user" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.users}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              New users this month
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="far fa-money-bill-alt" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.month_users}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title  text-muted margin-overview-card-text">
                              Number of transactions
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="fas fa-tasks" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_trxs}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title  text-muted margin-overview-card-text">
                              Transactions this month
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="fas fa-circle" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.month_trxs}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: 20 }}>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title  text-muted margin-overview-card-text">
                              Total amount given out in Loans
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_loan_amount}
                              prefix={"NGN "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title  text-muted margin-overview-card-text">
                              Total expected amount from Loans
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                              <i className="fas fa-coins" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_loan_return}
                              prefix={"NGN "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title  text-muted margin-overview-card-text">
                              Total profit expected from Loans
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                              <i className="fas fa-money-bill-alt" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={
                                
                                this.state.total_loan_return -
                                this.state.total_loan_amount 
                              }
                              prefix={"NGN "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />



                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Balance expected from Loans
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={
                                this.state.total_loan_balance
                                  ? this.state.total_loan_balance.toFixed(2)
                                  : 0
                              }
                              prefix={"NGN "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: 20 }}>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total invested amount in Akawo
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_akawo_invested ?
                                this.state.total_akawo_invested .toFixed(2):0
                              }
                              prefix={"NGN "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total amount in Wallet
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_available_bal}
                              prefix={"NGN "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total number of Open Tickets{" "}
                              <span className="bg-danger badge text-white  p-2" style={{fontSize:15}}>
                                {this.state.total_unreplied_tickets}
                              </span>
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="far fa-money-bill-alt" />{" "}
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={
                                this.state.total_open_tickets
                                  ? this.state.total_open_tickets
                                  : 0
                              }
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total Loan Amount Paid{" "}
                              <span className="bg-danger badge text-white  p-2" style={{fontSize:15}}>
                              </span>
                            </h4>
                          </div>

                          <div className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="far fa-money-bill-alt" />{" "}
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={
                                this.state.total_loan_amount_paid
                                  ? this.state.total_loan_amount_paid.toFixed(2)
                                  : 0
                              }
                              displayType={"text"}
                              prefix={"NGN"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
