import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class UpdateBVNNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      bvn: "",
      new_number: "",
      bvn_name: "",
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  updateBVNNumber = (e) => {
    e.preventDefault();
    if (this.state.new_number.length !== 11) {
      alert("Please provide a valid phone number.");
      return;
    }
    this.setState({
      isLoading: true,
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/update_bvn_number", {
        token: localStorage.getItem("supportToken"),
        bvn: this.state.bvn,
        new_number: this.state.new_number,
        pin: this.state.pin,
        otp: this.props.otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            bvn: "",
            new_number: "",
            pin: "",
            bvn_name: "",
          });
        } else {
          self.setState({
            isLoading: false,
          });
        }
        alert(response.data.message);
      });
  };

  getBVNName = (bvn) => {
    this.setState({
      bvn_name: "",
    });
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/get_bvn_name", {
        token: localStorage.getItem("supportToken"),
        bvn: bvn,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            bvn_name: response.data.data,
          });
        } else {
          self.setState({
            bvn_name: "Not Found",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Update BVN Number</h2>
          </div>

          <form className=" p-3 col-md-7" onSubmit={this.updateBVNNumber}>
            <div className="form-group ">
              <label>BVN</label>
              <input
                required
                name="bvn"
                className="form-control"
                placeholder="Your BVN Number"
                value={this.state.bvn}
                onChange={(evt) => {
                  let bvn = evt.target.value;
                  if (bvn.length === 11) {
                    // get user full name
                    this.getBVNName(bvn);
                  }
                  this.handleChange(evt);
                }}
                type="text"
              />
              <small className="text-info">{this.state.bvn_name}</small>
            </div>
            <div className="form-group ">
              <label>New Phone Number</label>
              <input
                required
                name="new_number"
                className="form-control"
                placeholder="New Number"
                value={this.state.new_number}
                onChange={this.handleChange}
                type="number"
              />
            </div>

            <div className="form-group ">
              <label>Your PIN</label>
              <input
                required
                name="pin"
                className="form-control"
                placeholder="PIN"
                value={this.state.pin}
                onChange={this.handleChange}
                type="password"
              />
            </div>

            <button className="btn btn-success" disabled={this.state.isLoading}>
              Continue
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default UpdateBVNNumber;
