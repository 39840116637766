import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import Loan from "./loan";
import Akawo from "./akawo";
import Transfer from "./transfer";
import Appstats from "./Appstats";
import P2P from "./p2p";
import Deposit from "./deposit";
import OtherTransactions from "./other_transactions.js";
import AccountFund from "./account_fund.js";
import BalanceCounter from "./balance_counter.js";
import User from "./user.js";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "p2p",
    };
  }

  render() {
    let clearance = localStorage.getItem("supportClearance");
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Report</h2>
                  </div>

                  <hr />

                  <ul className="nav nav-pills  p-3">
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "loan" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "loan" })}
                      >
                        {" "}
                        Loan
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "akawo" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "akawo" })}
                      >
                        Akawo
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "transfer" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "transfer" })}
                      >
                        Transfer
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "deposit" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "deposit" })}
                      >
                        Deposit
                      </a>
                    </li>
                    { (clearance === "admin" || clearance === "account"|| clearance === "staff" || clearance === "content-creator") &&
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "app" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "app" })}
                        >
                          {" "}
                          App Stats
                        </a>
                      </li>
                    }
                    { (clearance === "admin"  || clearance === "content-creator") &&
                      <li className="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "user" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "user" })}
                        >
                          {" "}
                          User Stats
                        </a>
                      </li>
                    }
                    

                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "others" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "others" })}
                      >
                        Others Transactions
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "account_funds" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "account_funds" })}
                      >
                        Account Funds
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "balance_counter" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "balance_counter" })}
                      >
                        Balance Counter
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "p2p" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "p2p" })}
                      >
                        P2P Report
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="p-3">
              {this.state.tab === "loan" ? (
                <Loan />
              ) : this.state.tab === "akawo" ? (
                <Akawo />
              ) : this.state.tab === "transfer" ? (
                <Transfer />
              ) : this.state.tab === "deposit" ? (
                <Deposit />
              ) : this.state.tab === "account_funds" ? (
                <AccountFund />
              ) : this.state.tab === "app" ? (
                <Appstats />
              ) 
              : this.state.tab === "others" ? (
                <OtherTransactions />
              ) 
              : this.state.tab === "user" ? (
                <User />
              ) 
              : this.state.tab === "balance_counter" ? (
                <BalanceCounter />
              ) 
              : this.state.tab === "p2p" ? (
                <P2P/>
              ) 
              : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
