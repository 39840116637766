import React, { Component } from 'react';
import './assets/index.css';
import Navbar from '../../components/navbar.js';
import Sidebar from '../../components/sidebar.js';
import axios from 'axios'
import NumberFormat from 'react-number-format'

class Userprofile extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: ''
        };
        this.changeEmail = this.changeEmail.bind(this);
        this.changeAmount = this.changeAmount.bind(this);
        this.changePin = this.changePin.bind(this);
        this.creditDeposit = this.creditDeposit.bind(this);
    }

    changeEmail(e){

        this.setState({email:e.target.value})
    }
    changeAmount(e){

        let txt = e.target.value;
        let numb = txt.match(/\d/g);
        if (numb !== null)
            numb = numb.join("");

        this.setState({amount:numb})
    }
    changePin(e){

        this.setState({pin:e.target.value})
    }
    creditDeposit(e){

        e.preventDefault();

        axios.post('https://api.roqqu.com/support/support/credit_user', {
            token: localStorage.getItem('supportToken'),
            user: this.state.email,
            amount: this.state.amount,
            pin: this.state.pin
        }).then((response) => {

            if (response.data.success === 'true'){

                window.location.href = '/transactions'
            }
            else{

                alert(response.data.message);
            }
        })
    }

    componentDidMount(){

        //get ref link if it exists
        let urlParams = new URLSearchParams(window.location.search);
        let emailRef = urlParams.get('email') || '';

        this.setState({
            email: emailRef
        })
    }
    render() {

        return (
            <div /*style={{overflowY: "scroll"}}*/>
                <Sidebar />
                <div className="main-content main-content-my">
                    <Navbar />
                    <div className="header bg-gradient-warning pb-8 pt-5 pt-md-8">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row">
                                    <nav>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid mt--7 promargin-m">
                        <div className="row">
                            <div className="col-xl-12 order-xl-12 mb-5 mb-xl-0">
                                <div className="card card-profile shadow">
                                    <form className="p-5" onSubmit={this.creditDeposit}>
                                        <h2 className="mb-4">Support Credits</h2>
                                        <input required onChange={this.changeEmail} value={this.state.email} placeholder="User email address" className="form-control mb-4" type="email"/>
                                        <NumberFormat
                                            required
                                            thousandSeparator=","
                                            onChange={this.changeAmount} value={this.state.amount}
                                            placeholder="Amount in Naira" className="form-control mb-4" type="input"/>
                                        <input required autoComplete="new-password" onChange={this.changePin} value={this.state.pin} placeholder="Support pin" className="form-control mb-4" type="password"/>
                                        <button type="submit" className="btn btn-default">Complete transaction</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Userprofile;