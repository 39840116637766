import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class TransferUpgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      transfer_upgrade: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ email: evt.target.value });
  };

  componentDidMount() {
    this.getAccountsWithTransferUpgrade();
  }

  getAccountsWithTransferUpgrade() {
    this.setState({
      isLoading: true,
      transfer_upgrade: null,
      elements: null,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/support/transfer_limit_upgrade", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        let elements = response.data.data.map((account, index) => (
          <tr key={index}>
            <td>
              <a target="_blank" href={`/verification_requests?email=${account.email}`}>
                {account.email}
              </a>
            </td>
            <td>{account.fname}</td>
            <td>{account.lname}</td>
            <td>{account.phone}</td>
            <td>
              {account.transfer_upgrade === 1 && account.id_card_uploaded_at !== null ? (
                <button className="btn btn-success">Uploaded</button>
              ) : (
                <button className="btn btn-danger">Required</button>
              )}
            </td>
          </tr>
        ));
        this.setState({
          isLoading: false,
          elements: elements,
          transfer_upgrade: 1,
        });
      });
  }

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Transfer Limit Upgrade</h2>
          </div>

          {this.state.isLoading ? (
            <div class="text-center">
              <label>Please wait....</label>
            </div>
          ) : (
            ""
          )}

          {this.state.transfer_upgrade !== null ? (
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Email Address</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">...</th>
                  </tr>
                </thead>
                <tbody>{this.state.elements}</tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default TransferUpgrade;
