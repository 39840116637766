import React, { Component } from 'react';
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import Switch from "react-switch";

export default class DeleteAccount extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        isLoading: false,
        elements:[],
        reqAmt:"",
        duration:"",
        repayAmt:"",
        rate:"",
        dueAt:"",
        token:"",
        loanDetails :false,
        onlyExtendDate:false,
        existingRequestedAmount: "",
        };
    this.getDeleteRequestList = this.getDeleteRequestList.bind(this);
    }


    componentDidMount() {
        this.getDeleteRequestList();
    }
    

      getDeleteRequestList = () => {
          console.log("ConstantVarAPI_BASE_URL:: ",
          ConstantVar.API_BASE_URL)
        this.setState({
            isLoading:true,
        })
        axios
        .post(
        ConstantVar.API_BASE_URL +
        "/support/delete_user_requests",
        {
            token: localStorage.getItem("supportToken"),
        }
        )
        .then((res) => {
        if(res.data.success === "true"){


            this.setState({
                elements: res.data.data.data,
                isLoading:false
            })
            
            return
        }
        })
        .catch((error) => {
            console.log("ERROR:: ", error)
            alert(error.res.data.message);
        });
     }

    handleDeleteRequest = (email) => {
      
        const userEmail = email.toLowerCase().trim()
        if (userEmail){
            if (!window.confirm("Are you sure you wish to delete this account?(Press ESC key to cancel)"))return
        }

        axios
        .post(
            ConstantVar.API_BASE_URL +
            `/support/delete_user`,{
                email :userEmail,
                token: localStorage.getItem("supportToken"),
            }
        )
        .then((res) => {
        if(res.data.success === "true"){
            alert(res.data.message)
        return
        }
        // alert(res.data.message);
        })
        .catch((error) => {
        alert(error.res.data.message);
        });
    }

    render() {
       return (
        <div>
            <div className="card shadow">
            {this.state.elements.length > 0 ? (
                <div className="col-md-12 mt-3 ">
                    <p className='text-center'>
                        No Request yet
                    </p>
                </div>
            ) : (
                <div className="col-md-12 mt-3">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Email Address</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Phone</th>
                            <th>Requested On</th>
                            <th scope="col">...</th>
                        </tr>
                        </thead>
                        {/* <tbody>{this.state.elementss}</tbody> */}
                        <tbody>
                       {this.state.elements.map((account,index) => (
                <tr key={index}>
                  <td>
                    <a href={`/user/${account.email}`} target="_blank" >
                    {account.email}
                    </a>
                  </td>
                  <td>{account.fname}</td>
                  <td>{account.lname}</td>
                  <td>{account.phone}</td>
                  <td>{account.updated_at}</td>
                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => this.handleDeleteRequest(account.email)}
                      disabled={this.state.isLoading}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
              }   
                        </tbody>
                    </table>
                </div>
            )}
            </div>
        </div>
        );
    }
}