import React, { Component } from "react";
import NumberFormat from "react-number-format";
import * as ConstantVar from "../../constants_var.js";

import moment from "moment";
import personimg from "./assets/img/person.jpg";
import { Link } from "react-router-dom";

export default class Details extends Component {
  constructor(props) {
    super(props);
  }

  changeInput1(e) {
    this.setState({
      fname: e.target.value,
    });
  }

  changeInput2(e) {
    this.setState({
      lname: e.target.value,
    });
  }

  changeInput3(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  changeInput4(e) {
    this.setState({
      dob: e.target.value,
    });
  }

  changeInput5(e) {
    this.setState({
      bvn: e.target.value,
    });
  }

  render() {
    console.log("USER_PROP :: ",this.props.user )

    return (
      <div
        className="tab-pane fade show active"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        {
          this.props.user.email != null ?
          <div className="row">
            <div className="col-8">
              <div className="card bg-secondary shadow">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <h3 className="mb-0">
                        Wallet Balance:{" "}
                        <NumberFormat
                          decimalScale={2}
                          className="text-success"
                          value={this.props.user.available_bal}
                          prefix={"NGN "}
                          displayType={"text"}
                          thousandSeparator={","}
                        />
                      </h3>
                    </div>
                    <div className="col-4">
                      <h3 className="mb-0">
                        Loan Balance:{" "}
                        <NumberFormat
                          decimalScale={2}
                          className="text-success"
                          value={this.props.user.loan_bal}
                          prefix={"NGN "}
                          displayType={"text"}
                          thousandSeparator={","}
                        />
                      </h3>
                    </div>
                    <div className="col-4">
                      <h3 className="mb-0">
                        Akawo Balance:{" "}
                        <NumberFormat
                          decimalScale={2}
                          className="text-danger"
                          value={this.props.user.akawo.current_amount}
                          prefix={"NGN "}
                          displayType={"text"}
                          thousandSeparator={","}
                        />
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form >
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              name="fname"
                              onChange={this.props.handleChange}
                              className="form-control bg-readonly form-control-alternative"
                              // value={this.props.user.fname || null}
                              defaultValue={this.props.user.fname || ""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              name="lname"
                              onChange={this.props.handleChange}
                              className="form-control bg-readonly form-control-alternative"
                              defaultValue={
                                (this.props.user.lname &&
                                  this.props.user.lname.toUpperCase()) ||
                                ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            disabled={this.props.isSubmitting}
                            onClick={this.props.updateUserDetails}
                            className="btn-success float-right text-white btn"
                            style={{ width: "24%" }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              id="input-phone"
                              name="phone"
                              readOnly={this.props.is_staff}
                              onChange={this.props.handleChange}
                              className="form-control bg-readonly form-control-alternative"
                              defaultValue={this.props.user.phone || ""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              id="input-last-name"
                              className="form-control bg-readonly form-control-alternative"
                              readOnly
                              defaultValue={this.props.user.email || ""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-alt-email"
                            >
                              Alt_Email
                            </label>
                            <input
                              type="email"
                              id="input-last-name"
                              className="form-control bg-readonly form-control-alternative"
                              readOnly
                              defaultValue={this.props.user.alt_email || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-dob"
                            >
                              Date of birth
                            </label>
                            <input
                              type="date"
                              id="input-dob"
                              name="dob"
                              readOnly={this.props.is_staff}
                              onChange={this.props.handleChange}
                              className="form-control bg-readonly form-control-alternative"
                              value={
                                moment(this.props.user.dob).format(
                                  "YYYY-MM-DD"
                                ) || ""
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-bvn"
                            >
                              BVN
                            </label>
                            <input
                              type="text"
                              id="input-bvn"
                              readOnly={this.props.is_staff}
                              name="bvn"
                              onChange={this.props.handleChange}
                              className="form-control bg-readonly form-control-alternative"
                              defaultValue={this.props.user.bvn || ""}
                            />
                          </div>
                        </div>
                        {!this.props.is_staff ? (
                          <div className="col-lg-12">
                            <button
                              type="button"
                              disabled={this.props.isSubmitting}
                              onClick={this.props.updateBVNDetails}
                              className="btn-success float-right text-white btn"
                              style={{ width: "24%" }}
                            >
                              Update
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <input
                              className="form-control bg-readonly form-control-alternative"
                              readOnly
                              placeholder="Home Address"
                              defaultValue={this.props.user.address || ""}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              id="input-city"
                              className="form-control bg-readonly form-control-alternative"
                              readOnly
                              defaultValue={
                                this.props.user.state_of_residence || ""
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              LGA
                            </label>
                            <input
                              type="text"
                              id="input-country"
                              className="form-control bg-readonly form-control-alternative"
                              readOnly
                              defaultValue={this.props.user.local_govt_area || ""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Gender
                            </label>
                            <input
                              type="text"
                              id="input-postal-code"
                              className="form-control bg-readonly form-control-alternative"
                              readOnly
                              defaultValue={this.props.user.gender}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="col-xl-12 order-xl-2 mb-5 mb-xl-0">
                <div className="card card-profile shadow">
                  <div className="row justify-content-center">
                    <div className="col-lg-3 order-lg-2">
                      <div className="card-profile-image">
                        <a href="#">
                          <img
                            alt=""
                            className="rounded-circle"
                            src={
                              // this.props.user.dp_uploaded_at && this.props.user.dp_verified_at ?
                              ConstantVar.API_BASE_URL2 +
                              "/static/profile_pics/" +
                              this.props.user.phone +
                              "/dp.png" 
                              // : "not yet uploaded"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = personimg;
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0 pt-md-4 mt-5">
                    <div className="text-center">
                      <h3 className="margin-proname" style={{ paddingTop: 30 }}>
                        {this.props.user.fname} {this.props.user.lname}
                      </h3>

                      <div className="font-weight-300">
                        {this.props.user.email}
                      </div>

                      <div className="mt-2 font-weight-bold">
                        Credit Score: {this.props.user.credit_score}
                      </div>
                      <div className="mt-2 font-weight-bold">
                        {this.props.user.bvn}
                      </div>
                      <div className="mt-2 font-weight-normal">
                        <span>
                          {this.props.user.username != null &&
                            this.props.user.username}{" "}
                        </span>
                      </div>

                      <div className="m-2">
                        Joined:
                        <span className="font-weight-bold">
                          {moment(this.props.user.created_at).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      </div>
                      <div className="mt-2">{this.props.user.phone}</div>
                      <hr className="my-4" />
                    </div>
                    <div className="text-center">
                      <Link to="/credit" className="btn btn-warning text-white">
                        Fund user
                      </Link>
                    </div>
                    <div className="row p-4 mt-5">
                      <div className="col-9">
                        {" "}
                        <b>Email Verified</b>
                      </div>
                      <div className="col-3 text-right">
                        <span
                          className={
                            this.props.user.email_verified_at
                              ? "fa fa-check text-success"
                              : "fa fa-times text-danger"
                          }
                        ></span>
                      </div>
                      <div className="col-9">
                        {" "}
                        <b>NIN Verified</b>
                      </div>
                      <div className="col-3 text-right">
                        <span
                          className={
                            this.props.user.nin_verified_at
                              ? "fa fa-check text-success"
                              : "fa fa-times text-danger"
                          }
                        ></span>
                      </div>
                      <div className="col-9">
                        {" "}
                        <b>BVN Verified</b>
                      </div>
                      <div className="col-3 text-right">
                        <span
                          className={
                            this.props.user.bvn_verified_at
                              ? "fa fa-check text-success"
                              : "fa fa-times text-danger"
                          }
                        ></span>
                      </div>

                      <div className="col-8">
                        {" "}
                        <b>Profile Picture Verified</b>
                      </div>
                      <div className="col-4 text-right">
                        {this.props.user.dp_verified_at ? (
                          <span className="fa fa-check text-success"></span>
                        ) : this.props.user.dp_uploaded_at ? (
                          <span className="text-light">uploaded</span>
                        ) : (
                          <span className="fa fa-times text-danger"></span>
                        )}
                      </div>

                      <div className="col-8">
                        {" "}
                        <b>ID Card Verified</b>
                      </div>
                      <div className="col-4 text-right">
                        {this.props.user.id_card_verified_at ? (
                          <span className="fa fa-check text-success"></span>
                        ) : this.props.user.id_card_uploaded_at ? (
                          <span className="text-light">uploaded</span>
                        ) : (
                          <span className="fa fa-times text-danger"></span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
         
        <div className="text-center no-data full-width-data">
          <p className="text-muted no-trans-text-o">
              Loading...
          </p>
        </div>
     }
      </div>
    );
  }
}
