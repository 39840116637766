import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noOfTransfer: 0,
      bankTransfer: 0,
      bankTransferCharges: 0,
      profitFromBankTransferCharges: 0,
      noOfRoqquTransfer: 0,
      roqquCharges: 0,
      roqquTransfer: 0,
      ravenProfitFromBankTransferCharges: 0,
      ravenBankTransfer: 0,
      ravenBankTransferCharges: 0,
      numberOfRavenTransfers: 0,
      providusProfitFromBankTransferCharges: 0,
      providusBankTransfer: 0,
      providusBankTransferCharges: 0,
      numberOfProvidusTransfers: 0,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount() {
    this.getTransferReportRange();
  }

  getTransferReportRange = () => {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/transfer_report_range", {
        token: localStorage.getItem("supportToken"),
        start_date: self.state.start_date,
        end_date: self.state.end_date,
      })
      .then((response) => {
        let data = response.data.data;
        self.setState({
          isLoading: false,
          noOfTransfer: data.number_of_transfers,
          bankTransferCharges: data.bank_transfer_charges,
          bankTransfer: data.bank_transfer,
          profitFromBankTransferCharges: data.profit_from_bank_transfer_charges,
          noOfRoqquTransfer: data.number_of_roqqu_transfers,
          roqquCharges: data.roqqu_charges,
          roqquTransfer: data.roqqu_transfer,

          ravenProfitFromBankTransferCharges: data.raven_profit_from_bank_transfer_charges,
          ravenBankTransfer: data.raven_bank_transfer,
          ravenBankTransferCharges: data.raven_bank_transfer_charges,
          numberOfRavenTransfers: data.number_of_raven_transfers,

          providusProfitFromBankTransferCharges: data.providus_profit_from_bank_transfer_charges,
          providusBankTransfer: data.providus_bank_transfer,
          providusBankTransferCharges: data.providus_bank_transfer_charges,
          numberOfProvidusTransfers: data.number_of_providus_transfers,
        });
      });
  };

  render() {
    return (
      <div className="container">
        <div className="card shadow col-md-9 p-1 mx-auto">
          <div className="form-inline">
            <label>From:</label>
            <input
              type="date"
              className="ml-2 mr-2 form-control"
              name="start_date"
              value={this.state.start_date}
              onChange={this.handleChange}
            />
            <label>To:</label>
            <input
              type="date"
              className="ml-2 mr-2 form-control"
              name="end_date"
              value={this.state.end_date}
              onChange={this.handleChange}
            />
            <button
              className="btn btn-primary"
              onClick={this.getTransferReportRange}
            >
              View
            </button>
          </div>

          <div className="mt-5 text-center">
            <h4>Bank Transfer</h4>
          </div>

          <table className="ml-3">
            <tbody  >

              <tr className="text-gray">
                <th>Provider</th>
                <th>Number of Transfers</th>
                <th>Amount {" "} (₦)</th>
                <th className="text-gray">Charges{" "} (₦)</th>
                <th className="text-gray">Profit from Charges{" "} (₦)</th>
                {/* <th className="text-gray">Total</th> */}
              </tr>
              <tr className="">
                <th className="px-3 py-1">Raven</th>
                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.numberOfRavenTransfers}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </th>
                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.ravenBankTransfer}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  />
                </th>
                <th className="px-3 py-1">{" "}
                  <NumberFormat
                    value={this.state.ravenBankTransferCharges}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  />
                </th>

                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.ravenProfitFromBankTransferCharges}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  /></th>
              </tr>

              <tr className="">
                <th className="px-3 py-1">Providus</th>
                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.numberOfProvidusTransfers}
                    thousandSeparator={true}
                    displayType={"text"}
                  /></th>
                <th className="px-3 py-1">  {" "}
                  <NumberFormat
                    value={this.state.providusBankTransfer}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  /></th>
                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.providusBankTransferCharges}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  />
                </th>
                <th className="px-3 py-1">{" "}
                  <NumberFormat
                    value={this.state.providusProfitFromBankTransferCharges}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  />
                </th>
              </tr>

              <tr className="">
                <th className="px-3 py-1">Others</th>
                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.noOfTransfer}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </th>
                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.bankTransfer}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  /></th>
                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.bankTransferCharges}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  /></th>
                <th className="px-3 py-1"> {" "}
                  <NumberFormat
                    value={this.state.profitFromBankTransferCharges}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  /></th>
              </tr>
              <tr className="">
                <th className="px-3 py-1">Total</th>
                <th className="px-3 py-1">
                <NumberFormat
                    value=
                    {this.state.noOfTransfer +this.state.numberOfRavenTransfers+ this.state.numberOfProvidusTransfers}
                    thousandSeparator={true}
                    displayType={"text"}

                  />
                  </th>
                <th className="px-3 py-1">
                <NumberFormat
                    value={this.state.bankTransfer +this.state.ravenBankTransfer +this.state.providusBankTransfer}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  />
                </th>

                <th className="px-3 py-1">
                  
                  <NumberFormat
                      value={this.state.bankTransferCharges + this.state.ravenBankTransferCharges +this.state.providusBankTransferCharges}
                      thousandSeparator={true}
                      displayType={"text"}
                      // prefix="₦ "
                  />
                  </th>
                <th className="px-3 py-1">

                <NumberFormat
                    value={this.state.profitFromBankTransferCharges +this.state.ravenProfitFromBankTransferCharges+this.state.providusProfitFromBankTransferCharges}
                    thousandSeparator={true}
                    displayType={"text"}
                    // prefix="₦ "

                  />
                  
                  </th>
              </tr>
  
            </tbody>
          </table>

          <hr />
          <div className="mt-2 text-center">
            <h4>Wallet Transfer</h4>
          </div>

          <table>
            <tbody>
              <tr>
                <th className="p-1">No. Transfer</th>
                <td className="p-1">
                  {" "}
                  <NumberFormat
                    value={this.state.noOfRoqquTransfer}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-1">Transfer</th>
                <td className="p-1">
                  {" "}
                  <NumberFormat
                    value={this.state.roqquTransfer}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-1">Charges / Profit</th>
                <td className="p-1">
                  {" "}
                  <NumberFormat
                    value={this.state.roqquCharges}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-1">Total</th>
                <td className="p-1">
                  {" "}
                  <NumberFormat
                    value={this.state.roqquCharges + this.state.roqquTransfer}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  
}

export default Transfer;
