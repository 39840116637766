import React, { Component } from 'react';
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";


export default class PrivateSms extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        isLoading: false,
        phone:"",
        body:""
        };
        
        
    this.handleChange = this.handleChange.bind(this);
    this.sendSms = this.sendSms.bind(this);
    }

    sendSms = (e) => {
        e.preventDefault()
        this.isLoading = true
        if(this.state.phone.length <= 10 || this.state.phone.length > 11){
          alert("Please enter a correct 11-digit phone-number")
          
          return
        }
        alert("Please confirm this send action?")
        axios.post(ConstantVar.API_BASE_URL + `/support/message/sms`, {
            token: localStorage.getItem("supportToken"),
            phone:this.state.phone,
            body:this.state.body
          })
          .then((res) => {
        if (res.data.success === "true") {
          this.setState({
            phone: "",
            body: "",
            isLoading: false,
          });
          alert(res.data.message)
        } else {
          this.setState({
            isLoading: false,
          });
        }
    })
    
    }

    
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
    <div>
        <form
            // role="form"
            style={{ padding: 50 }}
            onSubmit={this.sendSms}
            autoComplete="off"
        >
            <h2>SMS</h2>
            <div className="form-group mb-3">
                <div className="input-group input-group-alternative">
                <input
                    required
                    autoComplete="off"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    name="phone"
                    className="form-control"
                    placeholder="Enter phone number"
                    // minLength={11}
                    // maxLength={11}
                    type="tel"
                />
                </div>
            </div>
            <div className="form-group mb-3">
                <div className="input-group input-group-alternative">
                <textarea
                    rows={10}
                    maxLength={150}
                    required
                    autoComplete="off"
                    name="body"
                    className="form-control"
                    placeholder="Enter your message about 150 characters."
                    value={this.state.body}
                    onChange={this.handleChange}
                />
                </div>
            </div>
            <div className="text-center">
                <button
                    disabled={this.state.isLoading}
                    className="btn btn-primary my-2 w-100"
                >
                Send 
                </button>
            </div>
        </form>
    </div>
    )
  }
}
