import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class BlockP2PTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      blocked_transfer_accounts: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ email: evt.target.value });
  };

  componentDidMount() {
    this.getAccountsWithBlockedP2PTransfer();
  }

  getAccountsWithBlockedP2PTransfer() {
    this.setState({
      // isLoading: true,
      blocked_transfer_accounts: null,
      elements: null,
    });
    axios
      .get(
        ConstantVar.API_BASE_URL + `/support/p2p_transfers/disabled_accounts?token=${localStorage.getItem("supportToken")}`
      )
      .then((response) => {
        if (response.data.data != "undefined"){

          this.setState({
            isLoading: false,
          })
          let elements = response.data.data.map((account) => (
            <tr>
              <td>{account.email}</td>
              <td>{account.fname}</td>
              <td>{account.lname}</td>
              <td>{account.phone}</td>
              <td>{account.block_p2p_transfer_at}</td>
              <td>
                <button
                  className="btn btn-info"
                  onClick={() => this.unBlockP2PTransfer(account.email)}
                  disabled={this.state.isLoading}
                >
                  Unblock
                </button>
              </td>
            </tr>
          ));
          this.setState({
            // isLoading: false,
            elements: elements,
            blocked_transfer_accounts: 1,
          });
          }
       } )
    }


  unBlockP2PTransfer = (email) => {
    // if the request is to block the transfer
    // if (block) {
      //validate
      if (!email.length) {
        alert("Enter user email address");
        return false;
      }

      if (!email.includes("@") || !email.includes(".")) {
       alert("Enter a valid email address");
        return false;
      }
    // }

    this.setState({
      isLoading: true,
      email: "",
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/p2p_transfers/enable", {
        token: localStorage.getItem("supportToken"),
        email: email,
      })
      .then((response) => {

        this.setState({
          isLoading: true,
        })

        if (response.data.success === "true") {
          
          alert(response.data.message.toUpperCase());
          self.getAccountsWithBlockedP2PTransfer();
       
        } else {
         
          alert(response.data.message);
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Block P2P Transfer</h2>
          </div>

          <hr />

          {this.state.isLoading ? (
            <div class="text-center">
              <label>Please wait....</label>
            </div>
          ) : (
            ""
          )}

          {this.state.blocked_transfer_accounts !== null ? (
            <div>
              <h4 className="p-4">Blocked</h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Email Address</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Phone</th>
                    <th>Blocked On</th>
                    <th scope="col">...</th>
                  </tr>
                </thead>
                <tbody>{this.state.elements}</tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default BlockP2PTransfer;
