import React, { Component } from 'react'

import NumberFormat from "react-number-format";
import moment from "moment/moment";

export default class Akawo extends Component {
    constructor(props) {
    super(props);
        this.state = {
            history :[],
        }
  
    }
    componentDidMount() {
        let history = this.props.user.akawo.history,
        historyList = [];
        // let balance =  0,
        let balance =   parseInt(this.props.user.akawo.current_amount),
        oldAmount = 0;

        // for (let i = 0; i < 5; i++) {
        for (let i = 0; i < history.length; i++) {

            let item = history[i];
            
            console.log("item.direction:: ",item.direction)
            console.log("item.amount:: ",item.amount)
            console.log("balance:: ",balance)
            if  (i == (history.length -1)){
               
                item.balance =  parseInt(item.amount);
                
            }else if(i == 0){
                console.log("balance:: ",balance)
                
                // item.balance = balance 
                // console.log("item.balance:: ",item.balance)

                if (item.direction == 'credit') {
                    item.balance = balance
                    balance = item.balance - parseInt(item.amount) 
                }
                else{
                    item.balance = balance
                    balance =  item.balance +  parseInt(item.amount);
                }
                // balance = item.balance - parseInt(item.amount)         
            }else {
                if (item.direction == 'credit') {
                    item.balance = balance
                    balance = item.balance - parseInt(item.amount) 
                }
                else{
                    item.balance = balance
                    balance =  item.balance +  parseInt(item.amount);
                }
            }
            historyList.push(item)
        }

        this.setState({
          history:historyList,
        })
    }

    render() {
        return (
        <div
            className="tab-pane fade show active"
            id="nav-akawo"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
        >
            <div className="card bg-secondary shadow">
            <div className="card-header bg-white border-0">
                <div className="row align-items-center">
                <div className="col-8">
                    <h3 className="mb-0">Akawo History</h3>
                </div>
                </div>
            </div>
            <div className="">
                <div className="" style={{ marginTop: "3%", marginBottom: "3%" }}>
                <div className="table-responsive">
                    <table className="table align-items-center table-flush">
                        <tbody>
                        {this.state.history.length > 0 ? (
                            this.state.history.map((trx,index) => {
                            return (
                                <tr key={index}>
                                    <td scope="row dash-td" style={{ width: "1%" }}>
                                        <div className="media align-items-center">
                                        {trx.type === "Deposit" ? (
                                            <div className="wallet-bckg">
                                            <i className="fas fa-wallet wallet-color" />
                                            </div>
                                        ) : trx.type === "Withdrawal" ? (
                                            <div className="phone-bckg">
                                            <i className="fas fa-university phone-color" />
                                            </div>
                                        ) : trx.type === "Growth" ? (
                                            <div className="tv-bckg">
                                            <i className="fas fa-line-chart tv-color" />
                                            </div>
                                        ) : (
                                            <p>Hometown</p>
                                        )}
                                        </div>
                                    </td>
                                    <td className="dash-td" style={{ width: "9%" }}>
                                        <h4 className="text-muted trans-date">
                                            {moment(trx.created_at).format("Y MMM Do, h:mm:ss a")}
                                        </h4>
                                    </td>
                                    <td className="dash-td">
                                        <span className="badge-dot mr-4">
                                        <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                            {trx.type}
                                        </p>
                                        <p className="table-dash-details2 mb-0">
                                            {trx.description}{" "}
                                        </p>
                                        </span>
                                    </td>
                                    <td className="dash-td">
                                        {trx.direction === "credit" &&
                                        <span className="float-right trans-credit-color font-weight-normal">
                                            <NumberFormat
                                            value={trx.amount}
                                            className="card-bal-your"
                                            displayType={"text"}
                                            prefix={"+ NGN "}
                                            decimalScale={0}
                                            thousandSeparator={true}
                                            />
                                        </span>
                                        }
                                    </td>
                                    <td className="dash-td">
                                        {trx.direction  !=="credit" &&
                                        <span className="float-right trans-debit-color font-weight-normal">
                                            <NumberFormat
                                            value={trx.amount}
                                            className="card-bal-your"
                                            displayType={"text"}
                                            prefix={"- NGN "}
                                            decimalScale={0}
                                            thousandSeparator={true}
                                            />
                                        </span>
                                        }
                                    </td>

                                    
                                    <td className="dash-td">
                                        <span className="float-right table-dash-details1 font-weight-normal">
                                            <NumberFormat
                                                value={trx.balance}
                                                className="card-bal-your"
                                                displayType={"text"}
                                                prefix={"NGN "}
                                                decimalScale={0}
                                                thousandSeparator={true}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            );
                            })
                        ) : (
                            <tr>
                                <td>
                                    <div className="text-center no-data full-width-data">
                                    {/*<img className="no-trans-overview" src={transimg} />*/}
                                    <p className="text-muted no-trans-text-o">
                                        No transaction done yet!
                                    </p>
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
        );
    }
}
