import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import img from "./assets/img/person.jpg";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
    this.logout = this.logout.bind(this);
  }

  logout() {
    localStorage.clear();
    window.location.href = "/";
  }
  changePassword() {
  
    window.location.href = "/resets";
  }
  changePin() {
    window.location.href = "/resets";
  }

  openTrxSearchModal = () => {
    if (this.props.name == "trx") this.props.func();
  };

  handleChange = (evt) => {
    this.setState({ search: evt.target.value });
  };

  performUserSearch = (evt) => {
    evt.preventDefault();
    if (this.props.name == "user") {
      this.props.func(evt.target.search.value);
    }
    return false;
  };

  render() {
    return (
      <div>
        <nav
          className="navbar navbar-top navbar-expand-md navbar-dark"
          id="navbar-main"
        >
          <div className="container-fluid">
            <ul className="navbar-nav align-items-center d-none d-md-flex ml-lg-auto">
              <li className="nav-item dropdown">
                <form
                  onSubmit={this.performUserSearch}
                  className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
                >
                  <div className="form-group mb-0">
                    <div className="input-group input-group-alternative">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-search" />
                        </span>
                      </div>
                      <input
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        value={this.state.search}
                        type="text"
                        onChange={this.handleChange}
                        onClick={this.openTrxSearchModal}
                      />
                    </div>
                  </div>
                </form>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link pr-0"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="media align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="Image placeholder" src={img} />
                    </span>
                    <div className="media-body ml-2 d-none d-lg-block">
                      {/*<span className="mb-0 text-sm  font-weight-bold">Jessica Jones</span>*/}
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                  <a onClick={this.logout} className="dropdown-item btn">
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </a>

                  <a onClick={this.changePin} className="dropdown-item btn">
                    <i className="ni ni-user-run" />
                    <span>Change Pin</span>
                  </a>
                  
                  <a onClick={this.changePassword} className="dropdown-item btn">
                    <i className="ni ni-user-run" />
                    <span>Change Password</span>
                  </a>
                 </div>
              </li>

              
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
