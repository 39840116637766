import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class Akawo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: [],
      isLoading: true,
      categories:["other", "loans"]
    };
    this.createStaff = this.createStaff.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //bind this to self
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/staff", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            staff: response.data.data,
            isLoading: false,
          });
        }
      });
  }

  
  createStaff(e) {
    e.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/support/create_staff", {
        email: e.target.email.value,
        fname: e.target.fname.value,
        lname: e.target.lname.value,
        password: e.target.password.value,
        pin: e.target.pin.value,
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          window.location.href = "/staff";
        } else {
          alert(response.data.message);
        }
      });
  }

  deleteStaff(email) {
    this.setState({
      isSubmitting: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/support/delete_staff", {
        email: email,
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          window.location.href = "/staff";
        } else {
          alert(response.data.message);
        }
      });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-akawo pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">All Hometown Staff</h2>
                    <button
                      data-toggle="modal"
                      data-target="#staffModal"
                      className="btn btn-success ml-auto"
                      style={{ float: "right" }}
                    >
                      Add new staff
                    </button>
                    <div
                      className="modal fade"
                      id="staffModal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="card">
                            <div className="card-body">
                              <h3>Create Staff Account</h3>
                              <hr />
                              <form
                                onSubmit={this.createStaff}
                                autoComplete="off"
                              >
                                <div className="form-group mb-3">
                                  <div className="input-group input-group-alternative">
                                    <input
                                      required
                                      autoComplete="off"
                                      name="email"
                                      className="form-control"
                                      placeholder="Staff Email"
                                      type="email"
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <div className="input-group input-group-alternative">
                                    <input
                                      required
                                      autoComplete="off"
                                      name="fname"
                                      className="form-control"
                                      placeholder="Staff First Name"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <div className="input-group input-group-alternative">
                                    <input
                                      required
                                      autoComplete="off"
                                      name="lname"
                                      className="form-control"
                                      placeholder="Staff Last Name"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="input-group input-group-alternative">
                                    <input
                                      required
                                      autoComplete="new-password"
                                      name="password"
                                      className="form-control"
                                      placeholder="Staff Password"
                                      type="password"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="input-group input-group-alternative">
                                    <input
                                      required
                                      name="pin"
                                      className="form-control"
                                      placeholder="Staff Support Pin"
                                      type="password"
                                    />
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    disabled={this.state.isSubmitting}
                                    className="btn btn-primary my-2 w-100"
                                  >
                                    Create staff
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="dash-hr" />
                  {!this.state.isLoading ? (
                    this.state.staff.map((staff, index) => {
                      return (
                        <div
                          key={index}
                          className="card card-style"
                          style={{ margin: 20, padding: "20" }}
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <h3 className="text-warning">
                                {staff.fname} {staff.lname}
                              </h3>
                              <p>
                                <span className="font-weight-bold">
                                  <Link
                                    style={{ color: "midnightblue" }}
                                    to={"/user/" + staff.email}
                                  >
                                    {staff.email}
                                  </Link>
                                </span>
                              </p>
                              <small>
                                added ({moment(staff.created_at).fromNow()})
                              </small>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.deleteStaff(staff.email);
                                  }}
                                  style={{
                                    textAlign: "right",
                                    float: "right",
                                    display:
                                      staff.clearance !== "admin"
                                        ? "block"
                                        : "none",
                                  }}
                                  className="btn btn-danger"
                                >
                                  Delete Staff
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                      <div className="text-center">
                        <p style={{ padding: 100 }}>Loading...</p>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Akawo;
