import React, { Component } from 'react'
import * as ConstantVar from "../../constants_var.js";

import axios from "axios";
import moment from "moment";

export default class PendingTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [] 
    };
  }

  componentDidMount() {
    this.getUserPendingTrxn();
  }

  getUserPendingTrxn = () => {
    this.setState({isLoading :true})

    let email = this.props.email,
    token = localStorage.getItem("supportToken"),
    per_page= this.state.perPage,
    current_page = this.state.currentPage
    axios
      .get(ConstantVar.API_BASE_URL + `/support/user/pendings?email=${email}&token=${token}&per_page=${per_page}&current_page=${current_page}`
      )
      .then((res) => {
        // console.log("response", res.data)
        if (res.data.success === "true"){
          let items = res.data.data

          // merge to an array from the arrays sent from backend
          let transactions = items.bettings.concat(
            items.electricity_recharges,
            items.data_recharges,
            items.waec_purchases,
            items.recharge,
            items.airtime
          )
          // sort by date and set to state
          this.setState({
            isLoading:false,
            transactions: transactions
              .slice()
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
          });
        }
      });
  
  }

  
  render() {
    
    return (
      <div
        className="tab-pane fade show active"
        id="nav-transactions"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0">Pending Transactions</h3>
              </div>
            </div>
          </div>
          <div className="">
          {this.state.isLoading ? (
            <div style={{ padding: 50 }} className="text-center">
              <div>
                <p>Loading...</p>
              </div>
            </div>
          ) :
            <div className="">
              <hr className="dash-hr" />
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead>
                    <tr itemScope="col">
                      <th>...</th>
                      <th>Date</th>
                      <th>Request_Id</th>
                      <th>Phone_number</th>
                      <th>Amount</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                      {this.state.transactions.length > 0 ? (
                        this.state.transactions.map((trx, index) => {
                          return (
                            <tr key={index} itemScope="row">
                              {/* <td style={{ width: "1%" }}> */}
                              <td >
                                <div className="media align-items-center">
                                  {trx.slug === "wallet_deposit" ? (
                                    <div className="wallet-bckg">
                                      <i className="fas fa-wallet wallet-color" />
                                    </div>
                                  ) : trx.slug === "airtime_recharge" ? (
                                    <div className="phone-bckg">
                                      <i className="fas fa-phone phone-color" />
                                    </div>
                                  ) : trx.slug === "pay_cable" ? (
                                    <div className="tv-bckg">
                                      <i className="fas fa-tv tv-color" />
                                    </div>
                                  ) : trx.slug === "tv_subscription" ? (
                                    <div className="phone-bckg">
                                      <i className="fas fa-phone phone-color" />
                                    </div>
                                  ) : trx.slug === "pay_electric" ? (
                                    <div className="phone-bckg">
                                      <i className="fas fa-bolt phone-color" />
                                    </div>
                                  ) : trx.slug === "fund_bet" ? (
                                    <div className="phone-bckg">
                                      <i className="fas fa-money-bill-alt phone-color" />
                                    </div>
                                  ) : trx.slug === "transfer" ? (
                                    <div className="tv-bckg">
                                      <i className="fas fa-paper-plane tv-color" />
                                    </div>
                                  ) : trx.slug === "repay" ? (
                                    <div className="phone-bckg">
                                      <i className="fas fa-coins phone-color" />
                                    </div>
                                  ) : trx.slug === "akawo_credit" ? (
                                    <div className="phone-bckg">
                                      <i className="fas fa-wallet phone-color" />
                                    </div>
                                  ) : trx.slug === "invest" ? (
                                    <div className="phone-bckg">
                                      <i className="fas fa-wallet phone-color" />
                                    </div>
                                  ) : trx.slug === "loan" ? (
                                    <div className="phone-bckg">
                                      <i className="fas fa-money-bill-alt phone-color" />
                                    </div>
                                  ) : (
                                    <p>Hometown</p>
                                  )}
                                </div>
                              </td>
                              {/* <td className="dash-td" style={{ width: "6%" }}> */}
                              <td >
                                <h4 className="text-muted trans-date">
                                    {moment(trx.created_at).format(
                                      "Y MMM Do, h:mm:ss a"
                                    )}
                                  <br />
                                  
                                </h4>
                              </td>
                              <td
                                // className="dash-td"
                                // align="left"
                                // style={{ width: "9%" }}
                              >
                                <p className="table-dash-details2 mb-0">
                                  {trx.request_id}
                                </p>
                              </td>
                              <td
                                // className="dash-td"
                                // align="left"
                                // style={{ width: "12%" }}
                              >
                                <p className="table-dash-details2 mb-0">
                                  {trx.phone_number}
                                </p>
                              </td>
                              {/* <td className="dash-td" style={{ width: "9%" }}> */}
                              <td >
                                <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                  {trx.amount}
                                </p>
                              </td>
                              {/* <td className="dash-td" style={{ width: "9%" }}> */}
                              <td >
                                <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                  {trx.email}
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>
                            <div className="text-center no-data full-width-data">
                              {/*<img className="no-trans-overview" src={transimg} />*/}
                              <p className="text-muted no-trans-text-o">
                                No Pending transaction yet!
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                   
                  </tbody>
                </table>
              </div>
            </div>
  }
          </div>
        </div>
      </div>
    );
  }
}
