import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import moment from "moment";
import NumberFormat from "react-number-format";

class ReInitiateP2pBankWithdrawals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      reinitiate_process: null,
    };

    this.handleReInitiate = this.handleReInitiate.bind(this);
  }

  handleChange = (evt) => {
    this.setState({ email: evt.target.value });
  };

  componentDidMount() {
    this.getNonReInitiatedFailedP2pBankWithdrawals();
  }

  handleReInitiate(reference) {
    this.setState({
      isLoading: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/support/reinitiate_p2p_bank_withdrawal", {
        token: localStorage.getItem("supportToken"),
        reference,
      })
      .then(() => {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.componentDidMount();
          }
        );
      });
  }

  getNonReInitiatedFailedP2pBankWithdrawals() {
    this.setState({
      isLoading: true,
      elements: null,
      reinitiate_process: null,
    });

    axios
      .post(
        ConstantVar.API_BASE_URL +
          "/support/get_non_reinitiated_failed_p2p_bank_withdrawals",
        {
          token: localStorage.getItem("supportToken"),
        }
      )
      .then((response) => {
        let elements = response.data.data.map((account, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{moment(account.created_at).format("Y MMM Do, h:mm:ss a")} </td>
            <td>
              <a target="_blank" href={`/user/${account.email}`}>
                {account.email}
              </a>
            </td>
            <td>
              <NumberFormat
                value={account.amount}
                className="card-bal-your h5"
                displayType={"text"}
                prefix={"NGN "}
                decimalScale={0}
                thousandSeparator={true}
              />
            </td>
            <td>
              {account.account_name} <br />
              {account.account_number}
            </td>
            <td>{account.reference}</td>
            <td>
              <button
                disabled={this.isLoading}
                onClick={() => this.handleReInitiate(account.reference)}
                className="btn btn-danger"
              >
                Re-Initiate
              </button>
            </td>
          </tr>
        ));
        this.setState({
          isLoading: false,
          elements: elements,
          reinitiate_process: 1,
        });
      });
  }

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Re-Initiate Withdrawals</h2>
          </div>

          {this.state.isLoading ? (
            <div className="text-center">
              <label>Please wait....</label>
            </div>
          ) : (
            ""
          )}

          {this.state.reinitiate_process !== null ? (
            <div>
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th scope="col">Date</th>
                    <th scope="col">Email</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Account</th>
                    <th scope="col">Reference</th>
                    <th scope="col">...</th>
                  </tr>
                </thead>
                <tbody>{this.state.elements}</tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default ReInitiateP2pBankWithdrawals;
