import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class ManageTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: [],
      selectedStaff: {},
      isLoading: false,
      categories: ["Others",
       "Loans request failed",
       "Unable to apply for a loan",
       "Haven't received the loan I applied for",
       "Delayed Withdrawals",
       "Account number does not match",
       "NIN does not match",
       "Wallet to wallet transfer disabled",
       "Technical issues", 
       "Flight booking"],
      selectedCat: [],
      errorMessage: "",
      showDelete: false,
      showAssignModal:false,
    };
    this.assignTicket = this.assignTicket.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleShowRemove = this.handleShowRemove.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.showAssignModal = this.showAssignModal.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //bind this to self
    this.loadStaff();
  }

  loadStaff() {
    //bind this to self
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/staff", {
        token: localStorage.getItem("supportToken"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            staff: response.data.data,
            isLoading: false,
          });
        }
      });
  }

  async assignTicket(e, email) {
    e.preventDefault();

    // this.setState({
    //   isSubmitting: true,
    // });
    try {
      const response = await axios.post(
        ConstantVar.API_BASE_URL + "/support/staff/add_ticket_cats",
        {
          selected_categories: this.state.selectedCat,
          email,
          token: localStorage.getItem("supportToken"),
        }
      );
      alert(response.data);
    } catch (e) {
      console.log("error:: ", e.response.data);
      this.setState({
        errorMessage: e.response.data,
      });
    }
    // });
  }

  selectStaff(email) {
    this.setState({
      staffEmail: email,
    });
  }

  showAssignModal(selectedStaff) {
    this.setState({
      selectedStaff,
        showAssignModal: true,
    },() => {
     
    });
  }

  handleChange = (e) => {
    var options = e.target.options;
    var values = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }
    this.setState(
      {
        selectedCat: values,
      },
      () => {
        console.log("State1:: ", this.state.selectedCat);
      }
    );
  };

  closeModal() {
    this.setState({ ...this.state, selectedCat: [], errorMessage: "" }, () => {
      window.location.href = "/manage_ticket";
    });
  }

  handleShowRemove(e) {
    const category = e.target.value;
    // e.target.value
    this.setState({ showDelete: true, category });
  }

  async handleRemove(email) {
    try {
      const response = await axios.post(
        ConstantVar.API_BASE_URL + "/support/staff/remove_ticket_cats",
        {
          categoryItem: this.state.category,
          email,
          token: localStorage.getItem("supportToken"),
        }
      );

      this.loadStaff();

      alert(response.data);
    } catch (e) {
      this.setState({
        errorMessage: e.response.data,
      });
    }
  }

  render() {

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header  bg-gradient-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>

          {this.state.showAssignModal ?

<div
  // className="modal fade"
  // id="staffModal"
  // tabIndex="-1"
  // role="dialog"
  // aria-labelledby="exampleModalLabel"
  // aria-hidden="true"
>
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="card">
        <div className="card-body">
          <h3 className="m-auto">
            Assign Ticket Category to {this.state.selectedStaff?.email}
          </h3>
          <hr />
          {this.state.errorMessage != "" && (
            <div className="flex justify-content-between item-center bg-danger text-white p-3">
              <p>{this.state.errorMessage}</p>
              <span
                className=" ml-2  bg-danger"
                onClick={this.closeModal}
              >
                X
              </span>
            </div>
          )}
          <form
            onSubmit={(e) =>
              this.assignTicket(e, this.state.selectedStaff?.email)
            }
            autoComplete="off"
          >
            <div className="form-group mb-3">
              <select
                multiple
                className="input-group p-3 input-group-alternative"
                onChange={this.handleChange}
              >
                {this.state?.categories
                  .filter(
                    (category) =>
                      !this.state.selectedStaff?.ticket_categories?.includes(
                        category
                      )
                  )
                  .map((item, indx) => (
                    <option value={item} key={indx}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>

            <div className="text-center">
              <button
                type="submit"
                disabled={this.state.isSubmitting}
                className="btn btn-primary my-2 w-100"
              >
                Assign Ticket Category
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

:
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">All Hometown Staff</h2>
                  </div>
                  <hr className="dash-hr" />
                  {!this.state.isLoading ? (
                    this.state?.staff.map((staff, index) => {
                      return (
                        <div
                          key={index}
                          className="card card-style"
                          style={{ margin: 20, padding: "20" }}
                        >


                          <div className="row">
                            <div className="col-sm-12">
                              <h3 className="text-warning">
                                {staff.fname} {staff.lname}
                              </h3>
                              <p>
                                <span className="font-weight-bold">
                                  <Link
                                    style={{ color: "midnightblue" }}
                                    to={"/user/" + staff.email}
                                  >
                                    {staff.email}
                                  </Link>
                                </span>
                              </p>
                              <small>
                                added ({moment(staff.created_at).fromNow()})
                              </small>
                              <div>
                                {staff?.ticket_categories?.length > 0 && (
                                  <div>
                                    <select onChange={this.handleShowRemove}>
                                      <option value="">
                                        select a role to remove
                                      </option>
                                      {JSON.parse(staff.ticket_categories).map(
                                        (item, idx) => (
                                          // select an option then show the remove button
                                          <option value={item} key={idx}>
                                            {item}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    {this.state.showDelete && (
                                      <span
                                        onClick={() =>
                                          this.handleRemove(staff.email)
                                        }
                                        className="bg-danger btn ml-2 p-2 text-white cursor"
                                      >
                                        {" "}
                                        Remove
                                      </span>
                                    )}
                                  </div>
                                )}

                                <button
                                  data-toggle="modal"
                                  data-target="#staffModal"
                                  className="btn btn-success ml-auto"
                                  style={{
                                    textAlign: "right",
                                    float: "right",
                                    display: "block",
                                  }}
                                  onClick = {() =>this.showAssignModal(staff)}
                                  // className="btn btn-danger"
                                >
                                  Assign Ticket
                                </button>
                              </div>
                            </div>
                          </div>


                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      <p style={{ padding: 100 }}>Loading...</p>
                    </div>
                  )}


                </div>
              </div>
            </div>
          </div>
  }
        </div>
      </div>
    );
  }
}

export default ManageTicket;
