import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/index.js";
import Loan from "./pages/Loan/index.js";
import Akawo from "./pages/Akawo/index.js";
import Staff from "./pages/Staff/index.js";
import Messaging from "./pages/Messaging/index.js";
import Transactions from "./pages/Transactions/index.js";
import UserProfile from "./pages/Userprofile/index.js";
import Credit from "./pages/Userprofile/credit.js";
import Tickets from "./pages/Tickets/index.js";
import Users from "./pages/Users/index.js";
import Login from "./pages/Login/index.js";
import Withdrawals from "./pages/Withdrawals/index.js";
import FollowUpSchedules from "./pages/FollowUp/index.js";
import NotFound from "./pages/NotFound.js";
import Account from "./pages/Account/index.js";
import Settings from "./pages/Settings/index.js";
import Report from "./pages/Report/index.js";
import Cpanel from "./pages/Cpanel/index.js";
import Reset from "./pages/Resets/index.js";
import IdentificationUpload from "./pages/IdentificationUpload/index.js";
import Raven from "./pages/RavenDashBoard/index.js";
import ManageTicket from "./pages/ManageTicket/index.js";
// import MavenPortal from "./pages/Raven/index.js";

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendered: false,
    };
  }
  componentDidMount() {
    let appToken = localStorage.getItem("supportToken");

    //check if page sensitive
    if (appToken === null && window.location.pathname !== "/") {
      window.location.href = "/";
      alert("Login to continue");
    } else this.setState({ rendered: true });
  }

  render() {
    let clearance = localStorage.getItem("supportClearance");
    if (this.state.rendered)
      return (
        <Switch>
          {this.props.children}
          <Route exact path="/" component={Login} />
          {(() => {
            if (clearance === "admin" || clearance === "account")
              return <Route exact path="/overview" component={Dashboard} />;
          })()}
          {(() => {
            if (clearance === "admin")
              return <Route exact path="/staff" component={Staff} />;
          })()}
          {(() => {
            if (clearance === "admin" || clearance === "content-creator")
              return <Route exact path="/messaging" component={Messaging} />;
          })()}

          <Route exact path="/loans" component={Loan} />
          <Route exact path="/resets" component={Reset} />
          <Route exact path="/manage_ticket" component={ManageTicket} />
          <Route exact path="/akawo" component={Akawo} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="/tickets" component={Tickets} />
          <Route exact path="/withdrawals" component={Withdrawals} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/user/:email" component={UserProfile} />
          <Route  path="/raven/:transactionID?" component={Raven} />
          <Route exact path="/credit" component={Credit} />
          <Route exact path="/account" component={Account} />
          <Route  path="/followup_schedules" component={FollowUpSchedules} />
          {/* <Route exact path="/ravens" component={MavenPortal} /> */}

          <Route exact path="/verification_requests" component={IdentificationUpload} />
          {/* <Route exact path="/raven" component={Raven} /> */}
          {(() => {
            if (clearance === "admin" )
              return <Route exact path="/settings" component={Settings} />;
          })()}
          {(() => {
            if (clearance === "admin" || clearance === "account" || clearance === "content-creator"|| clearance === "staff")
              return <Route exact path="/report" component={Report} />;
          })()}

          {(() => {
            if (clearance === "admin" || clearance === "loan-officer")
              return <Route exact path="/cpanel" component={Cpanel} />;
          })()}

          <Route component={NotFound} />
        </Switch>
      );
    else return null;
  }
}
export default Routes;
