import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import axios from "axios";
import moment from "moment/moment";
import * as ConstantVar from "../../constants_var.js";
import Deposits from "./deposits";
import Transactions from "./transactions";
import Details from "./details";
import BankAccount from "./bank_account";
import Guarantors from "./guarantors";
import Loans from "./loans";
import Akawo from "./akawo";
import PendingTransactions from "./pending_transactions";


class Userprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        akawo: {
          history: [],
        },
        fname: "",
        lname: "",
        phone: "",
        dob: "",
        bvn: "",
        deposits: [],
        bank_accounts: [],
        reserved_accounts: [],
        loans: [],
        guarantors: [],
        cards:[]
      },
      disableButton: false,

      tab: "home",
      //pagination state
      perPage: 10,
      isSubmitting:false,
      userEmail: this.props.match.params.email != "" ? this.props.match.params.email:  "",
      offset: 0,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
      is_staff:
        localStorage.getItem("supportClearance") === "admin" ? false : true,
    };

    this.updateUserDetails = this.updateUserDetails.bind(this);
    this.updateBVNDetails = this.updateBVNDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
  }

  getUserDetails = () => {
    let email = this.props.match.params.email,
      self = this;


    axios
      .post(ConstantVar.API_BASE_URL + "/support/user", {
        token: localStorage.getItem("supportToken"),
        user: email,
        current_page: this.state.currentPage,
        per_page: this.state.perPage,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            userEmail: this.props.match.params.email,
            user: response.data.data,
            fname: response.data.data.fname,
            lname: response.data.data.lname,
            phone: response.data.data.phone,
            dob: response.data.data.dob,
            bvn: response.data.data.bvn,
          });
        }
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUserDetails();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  updateBVNDetails(e) {
    e.preventDefault();
    this.setState({isSubmitting:true})
    //update user details
    let email = this.props.match.params.email,
      self = this;
      var answer = window.confirm("Are you sure you need to update these details.?")
      if (!answer){
        this.setState({...this.state,isSubmitting:false})
        return
      }
    // check the values provided
    if (this.state.phone.length !== 11 || this.state.bvn.length !== 11) {
      alert("The length of phone or bvn number is incorrect");
      return false;
    }
   
    axios
      .post(ConstantVar.API_BASE_URL + "/support/update_user", {
        token: localStorage.getItem("supportToken"),
        user: email,
        is_bvn_update: true,
        phone: this.state.phone,
        bvn: this.state.bvn,
        dob: this.state.dob,
      })
      .then((response) => {
        this.setState({isSubmitting:false})

        if (response.data.success === "true") {
          alert("User BVN info updated successfully");
        } else {
          alert(response.data.message);
        }
      });
  }

  updateUserDetails(e) {
    e.preventDefault();
    this.setState({isSubmitting:true})
    //update user details
    let email = this.props.match.params.email,
      self = this;
      var answer = window.confirm("Are you sure you need to update these details.?")
      if (!answer){
        this.setState({...this.state,isSubmitting:false})
        return
      }
    axios
      .post(ConstantVar.API_BASE_URL + "/support/update_user", {
        token: localStorage.getItem("supportToken"),
        user: email,
        fname: this.state.fname,
        lname: this.state.lname,
      })
      .then((response) => {
        this.setState({isSubmitting:false})

        if (response.data.success === "true"){

          alert("User data updated successfully");
          return
        }
          alert(response.data.message);
          
        });
  }


  RemoveCard = (cards) =>{
    this.setState({cards:cards,disableButton:true})
  }
  
handleCardDelete = (data) => {
  let confirm = window.confirm("Are you sure you wish to delete this card ? ")
  if (!confirm) return
  axios.post(
    ConstantVar.API_BASE_URL +"/support/user/card", {
      token: localStorage.getItem("supportToken"),
      email:data.email,
      authorization :data.authorization
    }
  ).then(res => {
    let response = res.data
    if (response.status == "success"){
      this.setState({disableButton:true})
      this.getUserDetails();
      window.alert(response.message)
        // () => {
        

    }else{
      window.alert(response.message)
    }
  })
}


  render() {

    return (
      <div /*style={{overflowY: "scroll"}}*/>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row">
                  <nav>
                    <div className="nav " id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link profile-click-m active"
                        id="nav-home-tab"
                        data-toggle="tab"
                        // href="#nav-home"
                        href="#"
                        onClick={() => this.setState({ tab: "home" })}
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Details</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-transaction-tab"
                        data-toggle="tab"
                        // href="#nav-transactions"
                        href="#"
                        onClick={() => {
                          // e.preventDefault();
                          this.setState({ tab: "transactions" });
                        }}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Transactions</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-pending-tab"
                        data-toggle="tab"
                        href="#"
                        onClick={() =>
                          this.setState({ tab: "transaction_pending" })
                        }
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Pendings Trxn</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-deposit-tab"
                        data-toggle="tab"
                        href="#"
                        onClick={() => this.setState({ tab: "deposits" })}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Deposits</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        // href="#nav-loans"
                        href="#"
                        onClick={() => this.setState({ tab: "loans" })}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Loans</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="#"
                        onClick={() => {
                          this.setState({ tab: "bank_accounts" });
                        }}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Bank Accounts</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        // href="#nav-guarantors"
                        href="#"
                        onClick={() => this.setState({ tab: "guarantors" })}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Guarantors</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="#"
                        onClick={() => this.setState({ tab: "akawo" })}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Akawo History</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid mt--7 promargin-m">
            <div className="row">
              <div className="col-xl-12 order-xl-1">
                {/* transaction_pending */}
                <div className="tab-content" id="nav-tabContent">
                  {this.state.user && this.state.tab === "transactions" ? (
                    <Transactions email={this.state.userEmail} />
                  ) : this.state.tab === "deposits" ? (
                    <Deposits email={this.state.userEmail} />
                  ) : this.state.tab === "transaction_pending" ? (
                    <PendingTransactions email={this.state.userEmail} />
                  ) : this.state.tab === "loans" ? (
                    <Loans user={this.state.user} />
                  ) : this.state.tab === "akawo" ? (
                    <Akawo user={this.state.user} />
                  ) : this.state.tab === "guarantors" ? (
                    <Guarantors user={this.state.user} />
                  ) : this.state.tab === "bank_accounts" ? (
                    // this.BankAccountsWidget()
                    <BankAccount 
                      user={this.state.user}
                      removeCard ={this.RemoveCard}
                      getUserDetails={this.componentDidMount}
                      handleCardDelete={this.handleCardDelete}
                      disableButton={this.state.disableButton}
                    />
                  ) : this.state.tab === "home" ? (
                    <Details
                      user={this.state.user}
                      email={this.state.userEmail}
                      // key={this.state.user}
                      updateBVNDetails={this.updateBVNDetails}
                      updateUserDetails={this.updateUserDetails}
                      handleChange={this.handleChange}
                      is_staff={this.state.is_staff}
                      isSubmitting={this.state.isSubmitting}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Userprofile;
