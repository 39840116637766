import React, { Component } from "react";
import Deposits from "./deposit";
import Transfers from "./transfer";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // tab: "p2p_deposit",
      tab: "p2p_transfers",
    };
  }

  render() {

    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">P2P Report</h3>
              </div>

              <hr />

              <div>
                <ul className="nav nav-pills  p-3">
                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " + (this.state.tab === "p2p_transfers" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "p2p_transfers" })}
                    >
                      {" "}
                      Transfers                   </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "p2p_deposit" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "p2p_deposit" })}
                    >
                      {" "}
                      Deposits
                    </a>
                  </li>

               
               
                </ul>

                <div className="p-3">
                  {
                  // this.state.tab === "app-usages" ? (
                  //   <AppUsage />
                  // ) :
                   this.state.tab === "p2p_deposit" ? (
                    <Deposits />
                  ) : this.state.tab === "p2p_transfers" ? (
                    <Transfers />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
