import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import moment from "moment";

class BlockAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      blocked_accounts:null
    };
  }

  handleChange = (evt) => {
    this.setState({ email: evt.target.value });
  };

  componentDidMount(){
    this.getBlockedAccount()
  }
  blockAccount = (block, email) => {
    // if the request is to block the transfer
    if (block) {
      //validate
      if (!email.length) {
        alert("Enter user email address");
        return false;
      }

      if (!email.includes("@") || !email.includes(".")) {
        alert("Enter a valid email address");
        return false;
      }
    }

    this.setState({
      isLoading: true,
      email: "",
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/blocks/account", {
        token: localStorage.getItem("supportToken"),
        email,
      })
      .then((response) => {
        self.setState({
          isLoading: false,
        });
        if (response.data.success === "true") {
          alert(response.data.message);
          
        } else {
         
          alert(response.data.message);
        }
      });
  };
  
  getBlockedAccount = () => {
    this.setState({
      isLoading: true,
      blocked_accounts: null,
      elements: null,
    });
    let self = this;

    axios
      .post(ConstantVar.API_BASE_URL + "/support/get_blocked_accounts", {
        token: localStorage.getItem("supportToken"),
      })
     .then((response) => {
        let elements = response.data.data.map((account,index) => (
          <tr key={index}>
            <td>{account.email}</td>
            <td>{account.fname}</td>
            <td>{account.lname}</td>
            <td>{account.phone}</td>
            <td>
            {moment(account._blocked_at).format("yyyy MMM Do, h:mm:ss a")}
            </td>
            <td>
              
            </td>
          </tr>
        ));
        this.setState({
          isLoading: false,
          elements: elements,
          blocked_accounts: 1,
        });
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Block Account</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="email"
                className="form-control"
                placeholder="Enter User Email Address"
                value={this.state.email}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button
              onClick={() => this.blockAccount(true, this.state.email)}
              className="btn btn-success"
            >
              Block Account
            </button>
          </div>

          <hr />

          {this.state.isLoading && (
            <div className="text-center">
              <label>Please wait....</label>
            </div>
          ) }

{this.state.blocked_accounts !== null ? (
            <div>
              <h4 className="p-4">Blocked</h4>

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Email Address</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Phone</th>
                    <th>Blocked On</th>
                    <th scope="col">...</th>
                  </tr>
                </thead>
                <tbody>{this.state.elements}</tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default BlockAccount;
